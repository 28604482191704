import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import HelmetMeta from "./HelmetMeta";

export default function Head() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    if (window.clarity) {
      window.clarity("set", "page", location.pathname);
    }
  }, [location]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
    return () => clearTimeout(timeout);
  }, [location.pathname]);

  useEffect(() => {
    const referral = searchParams.get("hsref");
    if (referral && localStorage) {
      localStorage.setItem("hsref", referral);
      setSearchParams({});
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    const giftCard = searchParams.get("giftCard");
    if (giftCard && localStorage) {
      localStorage.setItem("giftCard", giftCard);
      setSearchParams({});
    }
  }, [searchParams, setSearchParams]);

  return (
    <>
      <HelmetMeta />
    </>
  );
}
