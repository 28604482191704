import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Pricing from "../Pricing";

const metrics = [
  {
    value: "30min",
    label: "Delivery Time",
  },
  {
    value: "98%",
    label: "Satisfaction Rate",
  },
  {
    value: "100+",
    label: "Variations",
  },
];

export default function OnboardingPricing() {
  const [coupon, setCoupon] = useState("");
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  const handleApplyCoupon = async () => {
    const couponTrim = coupon.trim() ?? "";
    if (disabled || couponTrim.length === 0) return;
    setDisabled(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payment/apply-coupon`,
        { coupon: couponTrim },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response?.data?.success === true) {
        toast.success("Enterprise coupon applied successfully");
      } else {
        toast.error("Invalid enterprise coupon. Please contact your account manager or sales team.");
      }
    } catch (error) {
      const message = error?.response?.data?.message;
      toast.error(message || "This coupon appears to be invalid or not authorized for enterprise use.");
    } finally {
      setDisabled(false);
    }
  };

  return (
    <div className="max-w-screen-xl mx-auto p-4 mt-16">
      <div className="w-full flex justify-center items-center flex-col mb-12">
        <div className="w-full max-w-xl text-center mb-3 px-4">
          <h1 className="font-semibold text-3xl md:text-5xl">
            Professional Headshots at a Fraction of Studio Costs
          </h1>
        </div>
        <div className="w-full max-w-6xl text-center">
          <p className="font-normal text-base mb-8">
            Transform your regular photos with AI-powered studio-quality
            professional headshots
          </p>

          {/* Metrics */}
          <div className="flex justify-center gap-12 mt-10 text-xs text-gray-600">
            {metrics.map((metric, index) => (
              <div key={index} className="flex flex-col items-center gap-1">
                <span className="font-semibold text-blue-700 text-lg">
                  {metric.value}
                </span>
                <span>{metric.label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-6 w-full">
        <Pricing showCoupon={true} affiliateEnabled={true} />
      </div>

      <div className="flex w-full justify-center items-center my-10">
        <div className="min-w-96 max-[400px]:w-full border rounded-3xl p-6 lg:p-8 
                        hover:shadow-lg transition-all duration-300 border-gray-200
                        bg-gradient-to-br from-orange-50 via-white to-amber-50">
          {/* Header */}
          <div className="flex items-center justify-between mb-4">
            <div>
              <h3 className="font-semibold text-2xl mb-1">
                <span className="text-orange-500">Lifetime</span>{" "}
                <span className="text-amber-400">Deal</span>
              </h3>
              <p className="text-gray-600 text-sm">Never Pay Again</p>
            </div>
            <span className="bg-orange-100 text-orange-700 text-xs px-3 py-1 rounded-full
                           animate-pulse">Limited Time</span>
          </div>

          {/* Features */}
          <div className="space-y-2 mb-4">
            <div className="flex items-center gap-2 text-gray-700">
              <svg className="w-5 h-5 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span>Unlimited Professional Headshots</span>
            </div>
            <div className="flex items-center gap-2 text-gray-700">
              <svg className="w-5 h-5 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span>All Future Updates Included</span>
            </div>
            <div className="flex items-center gap-2 text-gray-700">
              <svg className="w-5 h-5 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span>One-time Payment Only</span>
            </div>
          </div>

          {/* CTA Button */}
          <button
            disabled={disabled}
            onClick={() => navigate("/lifetime-deal")}
            className={`w-full py-3.5 px-6 rounded-3xl font-medium 
                       transition-all duration-300 transform
                       bg-orange-500 text-white hover:bg-orange-600
                       hover:shadow-lg hover:-translate-y-0.5
                       ${disabled && "opacity-75 cursor-not-allowed"}`}
          >
            <div className="flex items-center justify-center gap-2">
              <span>Get Lifetime Access</span>
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
            </div>
          </button>
        </div>
      </div>
      <div className="flex w-full justify-center items-center my-10">
        <div className="min-w-96 max-[400px]:w-full border rounded-3xl p-6 lg:p-8 hover:shadow-lg transition-all duration-300 border-gray-200">
          <div className="flex items-center gap-2 mb-3">
            <div className="font-semibold text-lg">Enterprise Coupon</div>
            <span className="bg-blue-100 text-blue-700 text-xs px-2 py-1 rounded-full">B2B Only</span>
          </div>
          
          <div className="text-sm text-gray-600 mb-4">
            <p className="mb-2">This section is exclusively for enterprise customers with a valid company coupon code.</p>
            <div className="flex items-start gap-2 text-xs bg-gray-50 p-3 rounded-lg">
              <svg className="w-4 h-4 text-blue-600 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <p>If you're an individual user, please proceed with our standard pricing plans above. For enterprise solutions, contact our sales team.</p>
            </div>
          </div>

          <div className="space-y-3">
            <input
              readOnly={disabled}
              placeholder="Enter enterprise coupon code"
              onChange={(e) => setCoupon(e.target.value)}
              type="text"
              className="border-2 border-gray-300 rounded-lg p-2.5 w-full focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all"
            />
            
            <button
              disabled={disabled}
              onClick={() => handleApplyCoupon()}
              className={`w-full py-2.5 px-6 rounded-lg font-medium transition-all duration-300
                        bg-gray-100 text-gray-900 hover:bg-gray-200 hover:shadow-sm
                        ${disabled && "opacity-75 cursor-not-allowed"}`}
            >
              Apply Enterprise Coupon
            </button>
          </div>

          <div className="mt-4 flex items-center gap-2 text-xs text-gray-500">
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
            </svg>
            <span>Only authorized enterprise codes will work</span>
          </div>
        </div>
      </div>
    </div>
  );
}
