import React from "react";
import { Helmet } from "react-helmet-async";

const HelmetMeta = ({
  name = "HeadShotly - Studio-Quality Professional Headshots in Minutes",
  description = "Transform your photos into stunning professional headshots in minutes. Perfect for LinkedIn, business profiles, and personal branding. Studio-quality results guaranteed.",
  keywords = "professional headshots, AI headshots, business portraits, LinkedIn photos, corporate headshots, professional photography, headshot generator, AI photography, personal branding, professional profile pictures, business headshots, executive portraits, studio quality headshots",
}) => {
  return (
    <Helmet prioritizeSeoTags>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#ff5a1f" />

      <title>{name}</title>
      <meta name="title" content={name} />
      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://headshotly.ai/" />
      <meta property="og:title" content={name} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="https://headshotly.ai/og-image.jpg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content={name} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content="https://headshotly.ai/" />
      <meta name="twitter:title" content={name} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="https://headshotly.ai/og-image.jpg" />
      <meta name="twitter:image:alt" content={name} />

      <meta name="linkedin:card" content="summary_large_image" />
      <meta name="linkedin:title" content={name} />
      <meta name="linkedin:description" content={description} />
      <meta
        name="linkedin:image"
        content="https://headshotly.ai/og-image.jpg"
      />

      <meta name="keywords" content={keywords} />
      <meta name="author" content="HeadShotly" />
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
};

export default HelmetMeta;
