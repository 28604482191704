import { useSelector } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";
import prices from "../data/pricing";
import useStripeCheckout from "../hooks/useStripeCheckout";
import { userSelector } from "../app/slices/user";

const FormatFeature = ({ text }) => {
  if (text === "Money-back Guarantee") {
    return (
      <div className="flex items-center justify-center gap-2 mb-3">
        <span className="text-blue-700 text-lg">•</span>
        <span className="text-sm font-semibold text-blue-700">{text}</span>
      </div>
    );
  }

  const [title, value] = text.split(":");
  if (!value) return text;

  return (
    <div className="flex items-center w-full gap-y-3 px-4">
      <div className="text-blue-700 text-lg mr-1">•</div>
      <div className="text-sm text-left">
        <span className="text-gray-900 font-medium">{title}</span>
        <span className="text-gray-600 ml-1">:{value}</span>
      </div>
    </div>
  );
};

export default function Pricing({
  showCoupon = true,
  affiliateEnabled = true,
  buyForSelf = true,
}) {
  const { isAuthenticated, isFetched } = useSelector(userSelector);
  const { handleCheckout, disabled } = useStripeCheckout(affiliateEnabled);
  const [numVouchers, setNumVouchers] = useState(1);

  const isAuth = isAuthenticated && isFetched;

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value);
    if (typeof value === "number") {
      if (value > 0) setNumVouchers(value);
    }
  };

  return (
    <>
      {prices.map((item) => (
        <div key={item.key} className="flex-1">
          <div
            className={`border rounded-3xl p-6 lg:p-8 hover:shadow-lg transition-all duration-300 
        ${
          item.key === "standard"
            ? "border-blue-600 shadow-md"
            : "border-gray-200"
        }`}
          >
            <div className="text-center">
              <div className="font-semibold text-xl md:text-2xl mb-2">
                {item.name}
              </div>
              <div className="flex flex-col items-center">
                {showCoupon && (
                  <div className="font-bold text-3xl md:text-4xl text-gray-400 line-through mb-1">
                    ${parseInt(item.price)}.00
                  </div>
                )}
                <div className="font-bold text-3xl md:text-4xl text-blue-700 mb-1">
                  ${parseInt(item.price * (showCoupon ? 0.5 : 1))}.00
                  <span className="text-base font-normal text-gray-600 ml-1">
                    one-time
                  </span>
                </div>
                {showCoupon && (
                  <div className="bg-green-100 text-green-700 text-sm px-3 py-1 rounded-full font-medium">
                    Save 50% with code: CYBERMONDAY
                  </div>
                )}
              </div>

              <div className="h-px bg-gray-200 my-6" />

              <div className="space-y-1">
                {item.features.map((feature) => (
                  <FormatFeature key={feature.key} text={feature.content} />
                ))}
              </div>

              <div className="mt-8">
                {!buyForSelf && (
                  <>
                    <div className="text-sm text-gray-500 mb-2">
                      Number of vouchers
                    </div>
                    <input
                      onChange={handleInputChange}
                      value={numVouchers}
                      type="number"
                      className="py-1 px-2 bg-gray-100 mb-2 outline-0 rounded"
                      min={1}
                    />
                  </>
                )}
                {isAuth ? (
                  <button
                    disabled={disabled}
                    onClick={() =>
                      handleCheckout({
                        priceId: item.priceId,
                        quantity: numVouchers,
                        buyFor: buyForSelf ? "self" : "team",
                      })
                    }
                    className={`w-full py-3 px-6 rounded-xl font-medium transition-colors duration-300
                ${
                  item.key === "standard"
                    ? "bg-blue-700 text-white hover:bg-blue-700"
                    : "bg-gray-100 text-gray-900 hover:bg-gray-200"
                } ${disabled && "opacity-75 cursor-not-allowed"}`}
                  >
                    Get Professional Photos
                  </button>
                ) : (
                  <Link to="/auth">
                    <button
                      disabled={disabled}
                      className={`w-full py-3 px-6 rounded-xl font-medium transition-colors duration-300 bg-gray-100 text-gray-900 hover:bg-gray-200 ${
                        disabled && "opacity-75 cursor-not-allowed"
                      }`}
                    >
                      Get Professional Photos
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
