import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useState } from "react";
import Slider from "react-slick";
import ArrowLeft from "../../assets/landing/icons/arrow-pointer-left.png";
import ArrowRight from "../../assets/landing/icons/arrow-pointer-right.png";
import { importAll } from "../../utils/importHelper";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import VerifiedIcon from "@mui/icons-material/Verified";
import Testimonials from './Testimonials';

const slideSet = [
  {
    id: "Code",
    name: "Code",
    original: [
      {
        name: "Code 1",
        image: require("../../assets/set-images/original/ori-1-1.jpeg"),
        profileUrl: "https://www.linkedin.com/in/zofiakoscik/",
        profilePlatform: "linkedin",
      },
      {
        name: "Code 2",
        image: require("../../assets/set-images/original/ori-2-1.jpeg"),
        profileUrl: "https://www.linkedin.com/in/zofiakoscik/",
        profilePlatform: "linkedin",
      },
      {
        name: "Code 3",
        image: require("../../assets/set-images/original/ori-3-1.jpeg"),
        profileUrl: "https://www.linkedin.com/in/zofiakoscik/",
        profilePlatform: "linkedin",
      },
    ],
    generated: [
      {
        key: "1",
        name: "Code 1",
        image: require("../../assets/set-images/generated/gen-1-1.png"),
      },
      {
        key: "2",
        name: "Code 2",
        image: require("../../assets/set-images/generated/gen-2-1.png"),
      },
      {
        key: "3",
        name: "Code 3",
        image: require("../../assets/set-images/generated/gen-3-1.png"),
      },
      {
        key: "4",
        name: "Code 4",
        image: require("../../assets/set-images/generated/gen-4-1.png"),
      },
    ],
  },
  {
    id: "Code 2",
    name: "Code 2",
    original: [
      {
        name: "Code 1",
        image: require("../../assets/set-images/original/ori-1-2.jpg"),
        profileUrl: "https://www.instagram.com/andrii_zahorodnii",
        profilePlatform: "instagram",
      },
      {
        name: "Code 2",
        image: require("../../assets/set-images/original/ori-2-2.jpg"),
        profileUrl: "https://www.instagram.com/andrii_zahorodnii",
        profilePlatform: "instagram",
      },
      {
        name: "Code 3",
        image: require("../../assets/set-images/original/ori-3-2.jpg"),
        profileUrl: "https://www.instagram.com/andrii_zahorodnii",
        profilePlatform: "instagram",
      },
    ],
    generated: [
      {
        key: "1",
        name: "Code 1",
        image: require("../../assets/set-images/generated/gen-1-2.jpg"),
      },
      {
        key: "2",
        name: "Code 2",
        image: require("../../assets/set-images/generated/gen-2-2.jpg"),
      },
      {
        key: "3",
        name: "Code 3",
        image: require("../../assets/set-images/generated/gen-3-2.jpg"),
      },
      {
        key: "4",
        name: "Code 4",
        image: require("../../assets/set-images/generated/gen-4-2.jpg"),
      },
    ],
  },
  {
    id: "Code 3",
    name: "Code 3",
    original: [
      {
        name: "Code 1",
        image: require("../../assets/set-images/original/ori-1-3.jpg"),
        profileUrl: "https://www.linkedin.com/in/yakush-anna/",
        profilePlatform: "linkedin",
      },
      {
        name: "Code 2",
        image: require("../../assets/set-images/original/ori-2-3.jpg"),
        profileUrl: "https://www.linkedin.com/in/yakush-anna/",
        profilePlatform: "linkedin",
      },
      {
        name: "Code 3",
        image: require("../../assets/set-images/original/ori-3-3.jpg"),
        profileUrl: "https://www.linkedin.com/in/yakush-anna/",
        profilePlatform: "linkedin",
      },
    ],
    generated: [
      {
        key: "1",
        name: "Code 1",
        image: require("../../assets/set-images/generated/gen-1-3.jpg"),
      },
      {
        key: "2",
        name: "Code 2",
        image: require("../../assets/set-images/generated/gen-2-3.jpg"),
      },
      {
        key: "3",
        name: "Code 3",
        image: require("../../assets/set-images/generated/gen-3-3.jpg"),
      },
      {
        key: "4",
        name: "Code 4",
        image: require("../../assets/set-images/generated/gen-4-3.jpg"),
      },
    ],
  },
];

let _slides = [];

for (let i = 0; i < slideSet.length; i++) {
  const slide = slideSet[i];
  const _original = slide.original.map((item) => ({ id: slide.id, ...item }));
  _slides = [..._slides, ..._original];
}

const slides = _slides.map((item, index) => ({
  key: index.toString(),
  ...item,
}));

const slideStyle = {
  display: "block",
  textAlign: "center",
  background: "#EAEBEE",
  color: "white",
  borderRadius: "50%",
  zIndex: 1,
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        ...slideStyle,
        right: "-50px",
      }}
      onClick={onClick}
    >
      <ArrowForwardIosIcon />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        ...slideStyle,
        left: "-50px",
      }}
      onClick={onClick}
    >
      <ArrowBackIosNewIcon />
    </div>
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const settings2 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const sampleImages = importAll(
  require.context(
    "../../assets/landing/photomeia-gallery",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

export default function Gallery({ galleryRef }) {
  const [autoPlay, setAutoPlay] = useState(true);
  const [generated, setGenerated] = useState(slideSet[0].generated);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleAfterChange = (index) => {
    const _slide = slides.find((_, _index) => _index === index);
    const _generated = slideSet.find((slide) => slide.id === _slide.id);
    setGenerated(_generated.generated);
  };

  const moreExamples = Object.keys(sampleImages)
    .slice(0, 16) // Limit to  images
    .map((imagePath, index) => ({
      id: `example-${index + 1}`,
      name: `Example ${index + 1}`,
      image: sampleImages[imagePath],
    }));

  return (
    <div className="max-w-screen-xl mx-auto px-4 mt-16" ref={galleryRef}>
      <div className="w-full flex justify-center items-center flex-col mb-8">
        <div className="w-full max-w-xl text-center mb-3 px-4">
          <h1 className="font-semibold text-3xl sm:text-4xl md:text-5xl lg:text-6xl">
            Before & <span className="text-orange-500">After</span>{" "}
            <span className="text-amber-400">Transformations</span>
          </h1>
          <p className="font-normal text-sm sm:text-base md:text-lg text-gray-600 mt-4">
            From casual snapshots to executive-quality headshots in minutes
          </p>
        </div>
      </div>

      <div onClick={() => setAutoPlay(false)}>
        <div className="flex items-center justify-center">
          <div className="h-32 w-36 flex max-[900px]:hidden">
            <img src={ArrowLeft} alt="" className="object-cover h-full" />
          </div>
          <div className="mb-5 max-w-[32rem] max-[450px]:w-36">
            <Slider
              {...settings}
              afterChange={handleAfterChange}
              autoplay={autoPlay}
              speed={1500}
              autoplaySpeed={3000}
              {...(isMobile && { arrows: false })}
            >
              {slides.map((item) => (
                <div key={item.key} className="px-2">
                  <div className="relative">
                    <div className="h-[150px] rounded-xl overflow-hidden">
                      <img
                        src={item.image}
                        alt={item.name}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    {item.profileUrl && (
                      <a
                        href={item.profileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="absolute -bottom-2 -right-2 bg-white rounded-full p-1.5 
                                   shadow-lg hover:scale-110 transition-transform duration-200
                                   flex items-center gap-1"
                      >
                        {item.profilePlatform === "linkedin" ? (
                          <LinkedInIcon className="text-[#0077b5] w-5 h-5" />
                        ) : (
                          <InstagramIcon className="text-[#E4405F] w-5 h-5" />
                        )}
                        <VerifiedIcon className="text-blue-500 w-4 h-4 absolute -top-1 -right-1" />
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="h-32 w-36 flex justify-end max-[900px]:hidden">
            <img src={ArrowRight} alt="" className="object-cover h-full" />
          </div>
        </div>
        <div>
          <Slider {...settings2} {...(isMobile && { arrows: false })}>
            {generated.map((item) => (
              <div key={item.key} className="px-2">
                <div className="h-[300px] rounded-3xl overflow-hidden">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="w-full h-full object-cover object-tops"
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className="mt-24">
        <Testimonials />
      </div>

      <div className="mt-16">
        <div className="text-center mb-16">
          <h2 className="font-semibold text-4xl mb-4">
            Trusted <span className="text-orange-500">Across</span>{" "}
            <span className="text-amber-400">Industries</span>
          </h2>
          <p className="font-normal text-base">
            See why professionals in finance, tech, consulting, and more choose
            our AI headshots
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {moreExamples.map((example) => (
            <div
              key={example.id}
              className="aspect-square rounded-xl overflow-hidden"
            >
              <img
                src={example.image}
                alt={example.name}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
