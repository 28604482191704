import { useNavigate } from "react-router-dom";
import LinkedIn from "../../assets/landing/brands/linkedin-logo.png";
import Indeed from "../../assets/landing/brands/indeed-logo.png";
import Glassdoor from "../../assets/landing/brands/glassdoor-logo.png";
import AfterImg from "../../assets/landing/gallery/person/after.png";
import BeforeImg from "../../assets/landing/gallery/person/before.png";
import HeroArrow from "../../assets/landing/icons/heroarrow.png";

const brands = [
  {
    title: "LinkedIn",
    img: LinkedIn,
  },
  {
    title: "Indeed",
    img: Indeed,
  },
  {
    title: "Glassdoor",
    img: Glassdoor,
  },
];

export default function Hero({ galleryRef }) {
  const navigate = useNavigate();

  return (
    <div>
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 mt-16">
        <div className="w-full max-w-lg max-[400px]:mb-8">
          <h1 className="font-semibold text-5xl mb-4">
            <span className="text-orange-500">Professional Headshots</span> in{" "}
            <span className="text-amber-400">10 Minutes</span>
          </h1>
          <p className="font-normal text-base text-gray-800 mb-4 leading-5">
            Skip the photographer. Skip the wait. Keep Studio quality, instant
            delivery.
          </p>
          <div>
            <p className="font-normal text-base text-gray-800 mb-3">
              Trusted by professionals on
            </p>
            <div className="flex items-center gap-x-3 mb-2.5">
              {brands.map((brand) => (
                <div
                  key={brand.title}
                  title={brand.title}
                  className="max-w-20 grayscale hover:grayscale-0"
                >
                  <img
                    src={brand.img}
                    alt={brand.title}
                    className="h-auto w-auto object-cover"
                  />
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={() => navigate("/auth")}
            className="bg-orange-500 font-normal text-base text-white rounded-3xl px-6 py-3 mt-5"
          >
            Get Professional Photos
          </button>
        </div>
        <div className="w-full max-w-md h-auto">
          <div
            onClick={() =>
              galleryRef?.current?.scrollIntoView({ behavior: "smooth" })
            }
          >
            <div className="flex z-0">
              <div className="h-60 w-60 rounded-2xl overflow-hidden">
                <img
                  src={BeforeImg}
                  alt=""
                  className="object-cover h-full w-full"
                />
              </div>
              <div className="flex flex-col mt-4 ml-4 cursor-pointer">
                <p className="font-semibold text-sm mb-2">
                  See more in <span className="underline">gallery</span>
                </p>
                <div className="w-7">
                  <img src={HeroArrow} alt="" />
                </div>
              </div>
            </div>
            <div className="-mt-32 -ml-7 flex justify-end z-10">
              <div className="w-60 h-60 rounded-2xl overflow-hidden">
                <img src={AfterImg} alt="" className="object-cover w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
