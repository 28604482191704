import React from 'react';
import GHeader from './layout/GHeader';
import GFooter from './layout/GFooter';
import { FaShieldAlt, FaUserCheck, FaAward, FaGlobe } from 'react-icons/fa';
import Try from './landing/Try';

// Import team profile images
import michaelProfile from '../assets/team-profile/michael.jpeg';
import nafisProfile from '../assets/team-profile/nafis.jpeg';
import zofiaProfile from '../assets/team-profile/zofia.jpeg';
import sabujProfile from '../assets/team-profile/sabuj.jpeg';

const About = () => {
  const pricingRef = React.useRef(null);
  const bannerRef = React.useRef(null);

  return (
    <>
      <GHeader pricingRef={pricingRef} bannerRef={bannerRef} />
      
      {/* Hero Section with Trust Signals */}
      <div className="bg-gradient-to-b from-orange-50 to-white">
        <div className="container mx-auto px-4 py-20">
          <div className="max-w-4xl mx-auto text-center mb-12">
            <h1 className="text-4xl md:text-5xl font-serif mb-6">
              Professional Headshots Made Simple with AI
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              Transform your selfies into professional headshots using our advanced AI technology. 
              Fast, affordable, and available 24/7.
            </p>
            
            {/* Trust Badges */}
            <div className="flex flex-wrap justify-center gap-6 mt-8">
              <div className="flex items-center bg-white px-4 py-2 rounded-full shadow-sm">
                <img 
                  src="https://www.gstatic.com/images/branding/product/2x/googleg_48dp.png"
                  alt="Google Verified" 
                  className="h-5 w-5 mr-2" 
                />
                <span className="text-sm font-medium">Google Verified Business</span>
              </div>
              <div className="flex items-center bg-white px-4 py-2 rounded-full shadow-sm">
                <svg 
                  className="h-5 w-5 mr-2 text-green-600" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" 
                  />
                </svg>
                <span className="text-sm font-medium">SSL Secured</span>
              </div>
            </div>
          </div>

          {/* Stats Section */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 max-w-4xl mx-auto mt-16">
            {STATS.map((stat, index) => (
              <div 
                key={index} 
                className="text-center transform hover:scale-105 transition-all duration-300 p-6 rounded-xl hover:shadow-lg"
                data-aos="fade-up"
                data-aos-delay={index * 100}
              >
                <div className="text-5xl font-bold text-orange-500 mb-3 animate-number">
                  {stat.value}
                </div>
                <div className="text-sm font-medium text-gray-600 uppercase tracking-wider">
                  {stat.label}
                </div>
                <div className="text-orange-400 text-3xl mt-2">
                  {stat.icon}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Technology Section */}
      <div className="container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto mb-24">
          <h2 className="text-3xl font-serif mb-8 text-center">Enterprise-Grade AI Technology</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="aspect-video rounded-2xl overflow-hidden">
              <img 
                src="https://images.unsplash.com/photo-1621600411688-4be93cd68504?q=80&w=1000"
                alt="Professional Photography Studio"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="space-y-6">
              {TECH_FEATURES.map((feature, index) => (
                <div key={index} className="flex items-start">
                  <div className="text-orange-500 mt-1 mr-4">
                    {feature.icon}
                  </div>
                  <div>
                    <h3 className="font-semibold mb-2">{feature.title}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Values Section */}
        <div className="mb-24">
          <h2 className="text-3xl font-serif mb-12 text-center">Our Core Values</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {VALUES.map((value, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow">
                <div className="text-orange-500 text-2xl mb-4">{value.icon}</div>
                <h3 className="text-xl font-semibold mb-3">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Meet Our Leadership */}
        <div className="container mx-auto px-4 py-16">
          <div className="max-w-4xl mx-auto mb-24">
            <h2 className="text-3xl font-serif mb-4 text-center">Meet Our Leadership</h2>
            <p className="text-center text-gray-600 mb-12">
              Building the future of professional headshots with AI
            </p>
            <div className="grid md:grid-cols-3 gap-8">
              {TEAM_MEMBERS.map((member, index) => (
                <div key={index} className="flex flex-col items-center p-6 hover:bg-gray-50 rounded-lg transition-colors">
                  <img 
                    src={member.image} 
                    alt={member.name}
                    className="w-40 h-40 rounded-lg mb-4 object-cover"
                  />
                  <h3 className="text-lg font-medium mb-1">{member.name}</h3>
                  <p className="text-gray-600 text-sm mb-3">{member.role}</p>
                  <p className="text-gray-600 text-sm mb-4 text-center">{member.bio}</p>
                  <div className="flex gap-3">
                    {member.linkedin && (
                      <a 
                        href={member.linkedin} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-[#0A66C2] hover:opacity-80 transition-colors"
                        aria-label="LinkedIn Profile"
                      >
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                        </svg>
                      </a>
                    )}
                    {member.github && (
                      <a 
                        href={member.github} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-[#171515] hover:opacity-80 transition-colors"
                        aria-label="GitHub Profile"
                      >
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
                        </svg>
                      </a>
                    )}
                    {member.twitter && (
                      <a 
                        href={member.twitter} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-[#1DA1F2] hover:opacity-80 transition-colors"
                        aria-label="Twitter Profile"
                      >
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/>
                        </svg>
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Try />
      <GFooter pricingRef={pricingRef} faqRef={React.useRef(null)} />
    </>
  );
};

const STATS = [
  { value: "24/7", label: "Service Availability" },
  { value: "< 5min", label: "Processing Time" },
  { value: "100%", label: "Secure" },
  { value: "AI", label: "Powered" }
];

const TECH_FEATURES = [
  {
    icon: <FaShieldAlt size={24} />,
    title: "Secure Processing",
    description: "Your photos are processed securely and automatically deleted after processing."
  },
  {
    icon: <FaUserCheck size={24} />,
    title: "AI Technology",
    description: "Advanced image processing that transforms your photos into professional headshots."
  },
  {
    icon: <FaAward size={24} />,
    title: "Instant Results",
    description: "Get your professional headshots in minutes, not days or weeks."
  },
  {
    icon: <FaGlobe size={24} />,
    title: "24/7 Availability",
    description: "Access our service anytime, anywhere, from any device."
  }
];

const VALUES = [
  {
    icon: <FaShieldAlt />,
    title: "Trust & Security",
    description: "Your privacy and data security are our top priorities, backed by enterprise-grade protection."
  },
  {
    icon: <FaUserCheck />,
    title: "User Success",
    description: "We measure our success through the professional achievements of our users."
  },
  {
    icon: <FaAward />,
    title: "Innovation",
    description: "Continuously pushing the boundaries of AI technology in professional imaging."
  },
  {
    icon: <FaGlobe />,
    title: "Global Impact",
    description: "Making professional imaging accessible to everyone, everywhere."
  }
];

const TEAM_MEMBERS = [
  {
    name: "Sabuj Ali",
    role: "Co-founder & CTO",
    image: sabujProfile,
    bio: "Leading technical innovation and development",
    linkedin: "https://www.linkedin.com/in/prosabuj/",
    github: "",
    twitter: ""
  },
  {
    name: "Michael Skladanek",
    role: "Co-founder & UI/UX Engineer",
    image: michaelProfile,
    bio: "Crafting intuitive and beautiful user experiences",
    linkedin: "https://www.linkedin.com/in/michaelskladanek/",
    github: "",
    twitter: ""
  },
  {
    name: "Nafis Faysal",
    role: "Founder & CEO",
    image: nafisProfile,
    bio: "Building AI-powered solutions for professional imaging",
    linkedin: "https://www.linkedin.com/in/nafisfaysal/",
    github: "https://github.com/nafisfaysal",
    twitter: "https://twitter.com/dotnafis"
  },
  {
    name: "Zofia Koscik",
    role: "Economic Analyst & Model",
    image: zofiaProfile,
    bio: "Economic analysis and professional modeling",
    linkedin: "https://www.linkedin.com/in/zofiakoscik/",
    github: "",
    twitter: ""
  }
];

export default About;
