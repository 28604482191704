const testimonials = [
  {
    id: 1,
    screenshot: require("../../assets/testimonials/testimonial-1.jpg"),
  },
  {
    id: 2,
    screenshot: require("../../assets/testimonials/testimonial-2.jpg"),
  },
  {
    id: 3,
    screenshot: require("../../assets/testimonials/testimonial-3.jpg"),
  },
  {
    id: 4,
    screenshot: require("../../assets/testimonials/testimonial-4.jpg"),
  },
  {
    id: 5,
    screenshot: require("../../assets/testimonials/testimonial-5.jpg"),
  },
  {
    id: 6,
    screenshot: require("../../assets/testimonials/testimonial-6.jpg"),
  },
  {
    id: 7,
    screenshot: require("../../assets/testimonials/testimonial-7.jpg"),
  },
  {
    id: 8,
    screenshot: require("../../assets/testimonials/testimonial-8.jpeg"),
  },
  {
    id: 9,
    screenshot: require("../../assets/testimonials/testimonial-9.jpg"),
  },
  {
    id: 10,
    screenshot: require("../../assets/testimonials/testimonial-10.jpg"),
  },
  {
    id: 11,
    screenshot: require("../../assets/testimonials/testimonial-11.jpeg"),
  },
];

export default function Testimonials() {
  return (
    <div className="max-w-screen-xl mx-auto px-4 md:p-8 mt-8 md:mt-16">
      <div className="text-center mb-8 md:mb-12">
        <h2 className="font-semibold text-3xl md:text-4xl lg:text-5xl mb-3 md:mb-4">
          Our Users Loved It <span className="text-orange-500">So Much</span>{" "}
          <span className="text-amber-400">They Wrote Paragraph-Size Testimonials</span>
        </h2>
        <p className="text-gray-600 text-sm md:text-base max-w-2xl mx-auto">
          Read the stories of why industry-leading professionals choose HeadSholtyAI
        </p>
      </div>

      <div className="columns-1 md:columns-2 lg:columns-3 gap-4">
        {testimonials.map((testimonial) => (
          <div 
            key={testimonial.id} 
            className="mb-4 break-inside-avoid group"
          >
            <img
              src={testimonial.screenshot}
              alt="Testimonial"
              className="w-full border-2 border-orange-200 hover:border-orange-400 transition-all duration-300 rounded-xl shadow-sm hover:shadow-md group-hover:scale-[1.01]"
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
