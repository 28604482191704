import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { userSelector } from "../../app/slices/user";
import ltdPrices from "../../data/ltd-pricing";
import prices from "../../data/pricing";
import useStripeCheckout from "../../hooks/useStripeCheckout";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "90%",
    md: "85%",
    lg: "80%",
  },
  maxWidth: 1400,
  maxHeight: {
    xs: "95vh",
    sm: "90vh",
  },
  overflow: "auto",
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  p: {
    xs: 3,
    sm: 4,
    md: 5,
  },
};

const metrics = [
  {
    value: "30min",
    label: "Delivery Time",
  },
  {
    value: "98%",
    label: "Satisfaction Rate",
  },
  {
    value: "100+",
    label: "Variations",
  },
];

const FormatFeature = ({ text, isLTD = false }) => {
  if (text === "Money-back Guarantee") {
    return (
      <div className="flex items-center justify-center gap-2 mb-3">
        <span
          className={`${isLTD ? "text-orange-500" : "text-blue-700"} text-lg`}
        >
          •
        </span>
        <span
          className={`text-sm font-semibold ${
            isLTD ? "text-orange-500" : "text-blue-700"
          }`}
        >
          {text}
        </span>
      </div>
    );
  }

  const [title, value] = text.split(":");
  if (!value) {
    return (
      <div
        className={`flex items-start w-full gap-2 px-2 py-1.5 ${
          !title && "invisible"
        }`}
      >
        <div
          className={`${
            isLTD ? "text-orange-500" : "text-blue-700"
          } text-lg mt-0.5`}
        >
          •
        </div>
        <div className="text-sm text-left flex-1">
          <span className="text-gray-900">{text}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center w-full gap-y-3 px-4">
      <div
        className={`${
          isLTD ? "text-orange-500" : "text-blue-700"
        } text-lg mr-1`}
      >
        •
      </div>
      <div className="text-sm text-left">
        <span className="text-gray-900 font-medium">{title}</span>
        <span className="text-gray-600 ml-1">:{value}</span>
      </div>
    </div>
  );
};

export default function PricingModal({
  isOpen,
  handleClose,
  showCoupon = true,
  affiliateEnabled = true,
}) {
  const [activeTab, setActiveTab] = useState("regular");
  const [selfMode, setSelfMode] = useState(true);
  const [coupon, setCoupon] = useState("");
  const [couponDisabled, setCouponDisabled] = useState(false);
  const [numVouchers, setNumVouchers] = useState(1);

  const { isAuthenticated, isFetched } = useSelector(userSelector);
  const { handleCheckout, disabled } = useStripeCheckout(affiliateEnabled);
  const isAuth = isAuthenticated && isFetched;

  const handleApplyCoupon = async () => {
    const couponTrim = coupon.trim() ?? "";
    if (couponDisabled || couponTrim.length === 0) return;
    setCouponDisabled(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payment/apply-coupon`,
        { coupon: couponTrim },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response?.data?.success === true) {
        toast.success("Coupon applied successfully");
      } else {
        toast.error("Invalid coupon code");
      }
    } catch (error) {
      const message = error?.response?.data?.message;
      toast.error(message || "Invalid coupon code");
    } finally {
      setCouponDisabled(false);
    }
  };

  const renderHeader = () => (
    <div className="w-full flex justify-center items-center flex-col mb-8">
      <Typography
        variant="h4"
        component="h2"
        align="center"
        gutterBottom
        sx={{ fontWeight: 600 }}
      >
        Professional Headshots at a Fraction of Studio Costs
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        align="center"
        gutterBottom
      >
        Transform your regular photos with AI-powered studio-quality
        professional headshots
      </Typography>

      <div className="flex rounded-lg bg-gray-100 p-1 mt-6 mb-4">
        {["regular", "ltd"].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`px-6 py-2 rounded-md text-sm font-medium transition-all duration-200 
              ${
                activeTab === tab
                  ? "bg-white text-gray-900 shadow-sm"
                  : "text-gray-600 hover:text-gray-900"
              }`}
          >
            {tab === "regular" ? "Regular Pricing" : "Lifetime Deal"}
          </button>
        ))}
      </div>

      <div className="flex justify-center gap-12 mt-6 text-xs text-gray-600">
        {metrics.map((metric, index) => (
          <div key={index} className="flex flex-col items-center gap-1">
            <span
              className={`font-semibold text-lg ${
                activeTab === "ltd" ? "text-orange-500" : "text-blue-700"
              }`}
            >
              {metric.value}
            </span>
            <span>{metric.label}</span>
          </div>
        ))}
      </div>
    </div>
  );

  const renderPurchaseTypeSelector = () => (
    <div className="w-full flex flex-col justify-center items-center mb-6">
      <div className="inline-flex items-center justify-center bg-gray-100 rounded-xl p-1.5 gap-2 min-w-[280px]">
        {[
          {
            mode: true,
            text: "For Myself",
            icon: "M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z",
          },
          {
            mode: false,
            text: "As a Gift",
            icon: "M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7",
          },
        ].map(({ mode, text, icon }) => (
          <button
            key={text}
            onClick={() => setSelfMode(mode)}
            className={`flex-1 px-6 py-2.5 rounded-lg text-sm font-medium transition-all duration-200 
              ${
                selfMode === mode
                  ? `${
                      activeTab === "ltd"
                        ? "bg-orange-500 text-white shadow-orange-200 shadow-sm"
                        : "bg-blue-700 text-white shadow-blue-200 shadow-sm"
                    }`
                  : "text-gray-600 hover:bg-gray-50"
              }`}
          >
            <div className="flex items-center justify-center gap-2">
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={icon}
                />
              </svg>
              <span>{text}</span>
            </div>
          </button>
        ))}
      </div>

      {!selfMode && (
        <div className="mt-4 flex items-center gap-3">
          <span className="text-sm text-gray-600">Number of vouchers:</span>
          <div className="inline-flex items-center rounded-lg border border-gray-200 bg-white shadow-sm">
            {["-", "+"].map((op, i) => (
              <Fragment key={op}>
                <button
                  onClick={() =>
                    setNumVouchers(
                      op === "-"
                        ? Math.max(1, numVouchers - 1)
                        : numVouchers + 1
                    )
                  }
                  className={`px-3 py-1.5 ${
                    i === 0 ? "border-r" : "border-l"
                  } border-gray-200 hover:bg-gray-50 transition-colors text-lg font-medium
                    ${
                      activeTab === "ltd" ? "text-orange-500" : "text-blue-700"
                    }`}
                >
                  {op}
                </button>
                {i === 0 && (
                  <input
                    type="number"
                    value={numVouchers}
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      if (value > 0) setNumVouchers(value);
                    }}
                    className="w-16 py-1.5 px-2 text-center focus:outline-none text-gray-700"
                    min="1"
                  />
                )}
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  const renderPricingCards = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 w-full">
      {(activeTab === "regular" ? prices : ltdPrices).map((item) => (
        <div key={item.key} className="w-full">
          <div
            className={`border rounded-3xl p-6 lg:p-8 hover:shadow-lg transition-all duration-300 h-full
            ${
              item.key === "standard"
                ? `border-${
                    activeTab === "ltd" ? "orange" : "blue"
                  }-600 shadow-md`
                : "border-gray-200"
            }`}
          >
            <div className="text-center">
              <div className="font-semibold text-xl md:text-2xl mb-2">
                {item.name}
              </div>
              <div className="flex flex-col items-center">
                {activeTab === "regular" && showCoupon && (
                  <div className="font-bold text-3xl md:text-4xl text-gray-400 line-through mb-1">
                    ${parseInt(item.price)}.00
                  </div>
                )}
                <div
                  className={`font-bold text-3xl md:text-4xl ${
                    activeTab === "ltd" ? "text-orange-500" : "text-blue-700"
                  } mb-1`}
                >
                  $
                  {parseInt(
                    activeTab === "regular"
                      ? item.price * (showCoupon ? 0.5 : 1)
                      : item.price
                  )}
                  .00
                  <span className="text-base font-normal text-gray-600 ml-1">
                    {activeTab === "ltd" ? "lifetime" : "one-time"}
                  </span>
                </div>
                {activeTab === "regular" && showCoupon && (
                  <div className="bg-green-100 text-green-700 text-sm px-3 py-1 rounded-full font-medium">
                    Save 50% with code: CYBERMONDAY
                  </div>
                )}
                {activeTab === "ltd" && (
                  <div className="bg-orange-100 text-orange-700 text-sm px-3 py-1 rounded-full font-medium">
                    Limited Time Offer
                  </div>
                )}
              </div>

              <div className="h-px bg-gray-200 my-6" />

              <div className="space-y-1">
                {item.features.map((feature) => (
                  <FormatFeature
                    key={feature.key}
                    text={feature.content}
                    isLTD={activeTab === "ltd"}
                  />
                ))}
              </div>

              <div className="mt-8">
                {isAuth ? (
                  <button
                    disabled={disabled}
                    onClick={() =>
                      handleCheckout({
                        priceId: item.priceId,
                        quantity: numVouchers,
                        buyFor: selfMode ? "self" : "team",
                        openInNewWindow: true,
                      })
                    }
                    className={`w-full py-3 px-6 rounded-xl font-medium transition-colors duration-300
                      ${
                        activeTab === "ltd"
                          ? "bg-orange-500 text-white hover:bg-orange-600"
                          : item.key === "standard"
                          ? "bg-blue-700 text-white hover:bg-blue-800"
                          : "bg-gray-100 text-gray-900 hover:bg-gray-200"
                      } ${disabled && "opacity-75 cursor-not-allowed"}`}
                  >
                    Get Started
                  </button>
                ) : (
                  <Link to="/auth" className="block">
                    <button
                      className={`w-full py-3 px-6 rounded-xl font-medium transition-colors duration-300
                      ${
                        activeTab === "ltd"
                          ? "bg-orange-500 text-white hover:bg-orange-600"
                          : "bg-gray-100 text-gray-900 hover:bg-gray-200"
                      }`}
                    >
                      Get Started
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderCouponSection = () => (
    <div className="flex w-full justify-center items-center mt-10">
      <div className="min-w-96 max-[400px]:w-full border rounded-3xl p-6 lg:p-8 hover:shadow-lg transition-all duration-300 border-gray-200">
        <div className="flex items-center gap-2 mb-3">
          <div className="font-semibold text-lg">Apply Coupon</div>
          <span className="bg-green-100 text-green-700 text-xs px-2 py-1 rounded-full">
            Save More
          </span>
        </div>

        <div className="text-sm text-gray-600 mb-4">
          <p className="mb-2">
            Have a promotional code? Enter it here to get additional savings on
            your purchase.
          </p>
          <div className="flex items-start gap-2 text-xs bg-gray-50 p-3 rounded-lg">
            <svg
              className="w-4 h-4 text-blue-600 mt-0.5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <p>
              Promotional codes can be combined with existing discounts for
              maximum savings.
            </p>
          </div>
        </div>

        <div className="space-y-3">
          <input
            readOnly={couponDisabled}
            placeholder="Enter your coupon code"
            onChange={(e) => setCoupon(e.target.value)}
            type="text"
            className="border-2 border-gray-300 rounded-lg p-2.5 w-full focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all"
          />

          <button
            disabled={couponDisabled}
            onClick={handleApplyCoupon}
            className={`w-full py-2.5 px-6 rounded-lg font-medium transition-all duration-300
                      bg-gray-100 text-gray-900 hover:bg-gray-200 hover:shadow-sm
                      ${couponDisabled && "opacity-75 cursor-not-allowed"}`}
          >
            Apply Coupon
          </button>
        </div>

        <div className="mt-4 flex items-center gap-2 text-xs text-gray-500">
          <svg
            className="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
            />
          </svg>
          <span>One coupon code per order</span>
        </div>
      </div>
    </div>
  );

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={style}>
        {renderHeader()}
        {renderPurchaseTypeSelector()}
        {renderPricingCards()}
        {renderCouponSection()}
      </Box>
    </Modal>
  );
}
