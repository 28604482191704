import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { TourProvider } from "@reactour/tour";
import { HelmetProvider } from "react-helmet-async";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./app/store";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://91a7eb580cddfdc7d9bb25f7c7f7c35a@o4508431317991424.ingest.de.sentry.io/4508431322579024",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost",
      "headshotly.ai",
      /^https:\/\/api\.headshotly\.ai/,
      /^https:\/\/headshotly\.ai/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <TourProvider>
          <App />
        </TourProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
