import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { asyncGetUser } from "../app/slices/user";
import { miscActions } from "../app/slices/mics";
import { initializeSocket, getSocket } from "../utils/socket";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Receiver() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authToken = localStorage.getItem("token");

  useEffect(() => {
    const socket = initializeSocket(authToken);

    const handleRefetchProfile = (data) => {
      dispatch(asyncGetUser({ authToken }));
      dispatch(miscActions.setResetModal());
      const toastMessage = data?.message?.toast;
      const redirectUrl = data?.message?.redirectUrl;
      if (toastMessage) {
        toast(toastMessage);
      }
      if (redirectUrl) {
        const timeout = setTimeout(() => {
          navigate(redirectUrl);
        }, 1000);
        return () => clearTimeout(timeout);
      }
    };

    if (socket) {
      socket.on("connect", () => {
        console.log("connected 🚀");
      });

      socket.on("receive-message", (data) => {
        switch (data?.messageType) {
          case "refetch-profile":
            handleRefetchProfile(data);
            break;
          default:
            break;
        }
      });

      socket.on("disconnect", () => {
        console.log("disconnect 🚀");
      });
    }

    return () => {
      const socket = getSocket();
      if (socket) {
        socket.off("connect");
        socket.off("receive-message");
        socket.off("disconnect");
      }
    };
  }, [authToken, dispatch, navigate]);

  return <div />;
}
