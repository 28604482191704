import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { userSelector } from "../../app/slices/user";
import { asyncClaimGiftCard } from "../../app/slices/vouchers";

const modalStyles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: "400px" },
    maxHeight: { xs: "90vh", sm: "80vh" },
    overflow: "auto",
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    p: { xs: 2, sm: 3 },
  },
  content: {
    textAlign: "center",
    mb: 3,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  buttonContainer: {
    display: "flex",
    gap: 1,
    justifyContent: "flex-end",
    mt: 1,
  },
};

export default function GiftModal({ isOpen, handleClose }) {
  const dispatch = useDispatch();
  const { isAuthenticated, isFetched } = useSelector(userSelector);

  const [giftCardState, setGiftCardState] = useState({
    code: "",
    isFromLocalStorage: false,
    isDisabled: false,
  });

  useEffect(() => {
    const savedCode = localStorage?.getItem("giftCard");
    if (savedCode) {
      setGiftCardState((prev) => ({
        ...prev,
        code: savedCode,
        isFromLocalStorage: true,
      }));
    }
  }, []);

  const handleGiftCardChange = (e) => {
    setGiftCardState((prev) => ({
      ...prev,
      code: e.target.value,
      isFromLocalStorage: false,
    }));
  };

  const handleApplyCoupon = async () => {
    const { code } = giftCardState;
    if (!isAuthenticated || !isFetched || !code || code.length !== 8) return;

    setGiftCardState((prev) => ({ ...prev, isDisabled: true }));

    try {
      const token = localStorage.getItem("token");
      const response = await dispatch(
        asyncClaimGiftCard({ userToken: token, code })
      ).unwrap();

      if (response?.success) {
        toast.success("Gift card applied successfully");
        handleClose();
      } else {
        toast.error("Invalid gift card code");
      }
      localStorage?.removeItem("giftCard");
    } catch (error) {
      const message = error?.response?.data?.message;
      toast.error(message || "Invalid gift card code");
    } finally {
      setGiftCardState((prev) => ({ ...prev, isDisabled: false }));
    }
  };

  const handleCloseModal = () => {
    if (giftCardState.isFromLocalStorage) {
      localStorage.removeItem("giftCard");
    }
    handleClose();
  };

  const MessageComponent = () =>
    giftCardState.isFromLocalStorage ? (
      <Typography variant="body2" color="text.secondary" gutterBottom>
        A gift card has been detected in your app. Would you like to redeem it?
      </Typography>
    ) : (
      <Typography variant="body2" color="text.secondary" gutterBottom>
        Enter the gift card code you received to claim.
      </Typography>
    );

  return (
    <Modal open={isOpen && isAuthenticated && isFetched} onClose={handleClose}>
      <Box sx={modalStyles.container}>
        <Box sx={modalStyles.content}>
          <Typography variant="h5" component="h2" gutterBottom>
            Claim Your Gift Card
          </Typography>
          <MessageComponent />
        </Box>

        <Box sx={modalStyles.form}>
          <TextField
            fullWidth
            size="small"
            label="Gift Card Code"
            variant="outlined"
            value={giftCardState.code}
            onChange={handleGiftCardChange}
            disabled={giftCardState.isDisabled}
            placeholder="GIFTCARD"
          />

          <Box sx={modalStyles.buttonContainer}>
            <Button
              size="small"
              variant="outlined"
              onClick={handleCloseModal}
              disabled={giftCardState.isDisabled}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={handleApplyCoupon}
              disabled={
                giftCardState.isDisabled ||
                giftCardState.code.trim().length !== 8
              }
              style={{ color: "white" }}
            >
              Claim Gift Card
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
