// Import placeholder images for each step
import Original1 from "../../assets/set-images/original/ori-1-1.jpeg";
import Original2 from "../../assets/set-images/original/ori-3-1.jpeg";
import Original3 from "../../assets/set-images/original/ori-2-1.jpeg";

import Woman1 from "../../assets/onboarding/styleSelection/woman/fullbody_white_background_1.png";
import Woman2 from "../../assets/onboarding/styleSelection/woman/environment_park_1_1.png";
import Woman3 from "../../assets/onboarding/styleSelection/woman/headshot_urban_1_1.png";

import Generated1 from "../../assets/set-images/generated/gen-4-1.png";
import Generated2 from "../../assets/set-images/generated/gen-1-1.png";
import Generated3 from "../../assets/set-images/generated/gen-2-1.png";

export default function Steps() {
  const steps = [
    {
      number: "1",
      title: "Upload your selfies",
      description:
        "Share 10+ recent photos with good lighting and different angles",
      demo: (
        <div className="mt-4">
          <div className="flex gap-3 justify-center">
            {[Original1, Original2, Original3].map((img, idx) => (
              <div
                key={idx}
                className="w-24 h-24 relative rounded-lg overflow-hidden bg-gray-100 group cursor-pointer hover:bg-gray-200 transition-all"
              >
                <img src={img} alt="" className="w-full h-full object-cover" />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-20 transition-all">
                  <span className="text-white text-sm opacity-0 group-hover:opacity-100">
                    Upload
                  </span>
                </div>
              </div>
            ))}
          </div>
          <p className="text-xs text-gray-500 mt-2 text-center">
            Upload High quality 15 photos for best results
          </p>
        </div>
      ),
    },
    {
      number: "2",
      title: "Pick your style",
      description:
        "Choose from professional looks that match your industry and personality",
      demo: (
        <div className="mt-4">
          <div className="grid grid-cols-3 gap-4">
            {[
              { img: Woman1, label: "Business Power" },
              { img: Woman2, label: "Professional Success" },
              { img: Woman3, label: "Everyday Authentic" },
            ].map((style, idx) => (
              <div key={idx} className="flex flex-col items-center">
                <div className="relative w-20 h-20 rounded-lg overflow-hidden bg-gray-100">
                  <img
                    src={style.img}
                    alt=""
                    className="w-full h-full object-contain"
                  />
                </div>
                <p className="text-xs text-gray-700 font-medium mt-2 text-center">
                  {style.label}
                </p>
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      number: "3",
      title: "AI Magic Happens",
      description: "We create a custom AI model just for you",
      demo: (
        <div className="mt-4">
          <div className="flex gap-3 justify-center">
            {[Original1, Original2, Original3].map((img, idx) => (
              <div
                key={idx}
                className="w-24 h-24 relative rounded-lg bg-gray-100 overflow-hidden"
              >
                {/* Original Image as Background */}
                <img
                  src={img}
                  alt=""
                  className="absolute inset-0 w-full h-full object-contain"
                />

                {/* Semi-transparent overlay */}
                <div className="absolute inset-0 bg-white/30 backdrop-blur-[1px]" />

                {/* Animation Overlays - using the same animations as before */}
                <div className="absolute inset-0 flex items-center justify-center">
                  {idx === 0 && (
                    <>
                      <div className="absolute inset-0">
                        <div className="h-full w-1 bg-gradient-to-b from-transparent via-orange-500 to-transparent animate-scanning-line" />
                      </div>
                      <div className="relative">
                        <div className="w-12 h-12">
                          <div className="absolute inset-0 rounded-full border-2 border-orange-200" />
                          <div className="absolute inset-0 rounded-full border-2 border-orange-500 border-t-transparent animate-spin" />
                        </div>
                        <div className="absolute -inset-1">
                          <div className="w-1 h-1 bg-orange-500 rounded-full absolute top-0 left-1/2 transform -translate-x-1/2 animate-pulse" />
                          <div className="w-1 h-1 bg-orange-500 rounded-full absolute bottom-0 left-1/2 transform -translate-x-1/2 animate-pulse delay-100" />
                          <div className="w-1 h-1 bg-orange-500 rounded-full absolute left-0 top-1/2 transform -translate-y-1/2 animate-pulse delay-200" />
                          <div className="w-1 h-1 bg-orange-500 rounded-full absolute right-0 top-1/2 transform -translate-y-1/2 animate-pulse delay-300" />
                        </div>
                      </div>
                    </>
                  )}
                  {idx === 1 && (
                    <div className="relative w-16 h-16">
                      <div className="absolute inset-0 border-2 border-dashed border-orange-300 rounded-lg animate-[border-dance_3s_linear_infinite]" />
                      <div className="absolute inset-2 border-2 border-orange-400 rounded-lg animate-pulse" />
                      <div className="absolute inset-4 border-2 border-orange-500 rounded-lg animate-pulse delay-100" />
                    </div>
                  )}
                  {idx === 2 && (
                    <div className="relative">
                      <div className="w-12 h-12 rounded-lg border-2 border-orange-500 animate-[quality-enhance_2s_ease-in-out_infinite]" />
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div className="w-8 h-8 bg-orange-500/20 rounded animate-ping" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <p className="text-xs text-gray-500 mt-2 text-center">
            Estimated time: 7-10 minutes
          </p>
        </div>
      ),
    },
    {
      number: "4",
      title: "Get Your Headshots",
      description:
        "Download 100+ professional photos in different poses and styles",
      demo: (
        <div className="mt-4">
          <div className="flex gap-3 justify-center">
            {[Generated1, Generated2, Generated3].map((img, idx) => (
              <div
                key={idx}
                className="w-24 h-24 relative rounded-lg overflow-hidden bg-gray-100 group"
              >
                <img src={img} alt="" className="w-full h-full object-cover" />
                <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                  <div className="flex gap-2">
                    <button className="p-2 bg-white rounded-full shadow-lg hover:bg-gray-100">
                      <svg
                        className="w-4 h-4 text-gray-700"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                        />
                      </svg>
                    </button>
                    <button className="p-2 bg-white rounded-full shadow-lg hover:bg-gray-100">
                      <svg
                        className="w-4 h-4 text-gray-700"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-2 flex justify-between items-center px-1">
            <span className="text-xs text-gray-500">100 photos generated</span>
            <button className="text-xs bg-orange-500 text-white px-3 py-1 rounded-full hover:bg-orange-600">
              Download All
            </button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="max-w-screen-xl mx-auto p-4 mt-16">
      <h2 className="text-3xl md:text-4xl font-semibold text-center mb-4">
        Professional headshots in{" "}
        <span className="text-orange-500">minutes</span>
      </h2>
      <p className="text-center text-gray-600 mb-12">
        Four simple steps to transform your selfies into professional headshots
      </p>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        {steps.map((step, index) => (
          <div key={index} className="relative group">
            <div className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 h-full">
              <div className="absolute -top-3 -left-3 w-8 h-8 bg-orange-500 rounded-full flex items-center justify-center text-white font-semibold text-sm shadow-lg">
                {step.number}
              </div>

              <div className="space-y-2">
                <h3 className="font-semibold text-lg text-gray-900">
                  {step.title}
                </h3>
                <p className="text-gray-600 text-sm">{step.description}</p>
                {step.demo}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-center mt-12 px-4">
        <a
          href="/auth"
          className="bg-orange-500 hover:bg-orange-600 text-white text-sm sm:text-base font-medium px-6 sm:px-8 py-2 sm:py-3 rounded-full transition-transform duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1"
        >
          Create Your Professional Headshots Now
        </a>
      </div>
    </div>
  );
}
