import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const faqs = [
  {
    id: "1",
    question: "What is HeadShotlyAI's Professional Headshot Service?",
    answer:
      "HeadShotlyAI uses advanced AI technology to transform your photos into professional headshots suitable for LinkedIn, resumes, and business profiles. Our AI is trained on thousands of professional photographs to deliver studio-quality results in minutes.",
  },
  {
    id: "2",
    question: "How long does it take to get my headshots?",
    answer:
      "You'll receive your professional headshots within 30 minutes of uploading your photos. Our AI processes your images quickly while maintaining professional studio quality.",
  },
  {
    id: "3",
    question: "Can I use these photos for LinkedIn and business profiles?",
    answer:
      "Yes! Your AI-generated headshots are perfect for LinkedIn, company websites, business cards, and any professional platform. You own full rights to use these photos for all professional purposes.",
  },
  {
    id: "4",
    question: "What if I'm not satisfied with my headshots?",
    answer:
      "We offer a money-back guarantee if you're not completely satisfied with your professional headshots. Our customer support team will work with you to either regenerate your photos or process a refund.",
  },
  {
    id: "5",
    question: "How many different styles can I choose from?",
    answer:
      "Depending on your package, you can access 10-30 different professional styles, including various backgrounds, lighting setups, and poses. Each style is designed to meet professional business standards.",
  },
  {
    id: "6",
    question: "Where can I use these professional headshots?",
    answer:
      "Your AI-generated headshots are perfect for multiple professional uses including LinkedIn, Twitter, company directories, email signatures, conference badges, speaker profiles, company websites, business cards, and any other professional platforms. You have full usage rights for all professional and business purposes.",
  },
  {
    id: "7",
    question:
      "Are these photos suitable for conference and speaking engagements?",
    answer:
      "Absolutely! Our AI-generated headshots meet the high-quality standards required for conference websites, speaker profiles, event marketing materials, and presentation slides. They're specifically designed to make you look professional and approachable in any business setting.",
  },
  {
    id: "8",
    question: "Can I use these photos for my email signature?",
    answer:
      "Yes! Our headshots are perfect for email signatures. They're optimized to look crisp and professional even at smaller sizes, helping you maintain a consistent professional image across all your business communications.",
  },
  {
    id: "9",
    question: "Do these photos work for social media profiles?",
    answer:
      "Definitely! Whether it's LinkedIn, Twitter, Instagram Business, or any other professional social network, our AI-generated headshots are optimized for all social media platforms while maintaining a consistent professional look across your online presence.",
  },
  {
    id: "10",
    question: "What's the difference between these and regular photos?",
    answer:
      "Our AI-generated headshots are specifically optimized for professional use, with perfect lighting, professional backgrounds, and consistent high quality. They're designed to make you look your best while maintaining a natural, approachable appearance that's perfect for business use.",
  },
];

export default function Faq({ faqRef }) {
  const [activeId, setActiveId] = useState(null);

  const handleSetActive = (id) => {
    activeId === id ? setActiveId(null) : setActiveId(id);
  };

  return (
    <div className="max-w-[1100px] mx-auto mt-16 p-4" ref={faqRef}>
      <div className="w-full flex justify-center items-center flex-col mb-8">
        <div className="w-full max-w-xl text-center mb-3 px-4">
          <h1 className="font-semibold text-5xl">FAQ</h1>
        </div>
        <div className="w-full max-w-sm text-center px-4">
          <p className="font-normal text-base">
            Everything you need to know about HeadShotlyAI
          </p>
        </div>
      </div>

      <div className="flex flex-wrap w-full gap-2 md:gap-1 justify-between">
        {faqs.map((faq) => (
          <div
            onClick={() => handleSetActive(faq.id)}
            className="w-full md:w-[49%] bg-gray-50 p-4 rounded-md cursor-pointer transition-transform transform hover:scale-105"
            key={faq.id}
            aria-expanded={activeId === faq.id}
          >
            <div className="flex items-center justify-between">
              <div
                className="text-gray-800 text-sm font-medium select-none"
                role="button"
              >
                {faq.question}
              </div>
              <div>
                {activeId === faq.id ? (
                  <RemoveIcon style={{ fontSize: 18 }} />
                ) : (
                  <AddIcon style={{ fontSize: 18 }} />
                )}
              </div>
            </div>

            {activeId === faq.id && (
              <div onClick={(e) => e.stopPropagation()} className="cursor-text">
                <div className="border-b my-2" />
                <div className="text-gray-600 text-sm font-medium">
                  {faq.answer}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
