import { useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import GHeader from "./layout/GHeader";
import GFooter from "./layout/GFooter";
import Try from "./landing/Try";

const metrics = [
  {
    value: "10min",
    label: "From Upload to Delivery",
  },
  {
    value: "98%",
    label: "Client Success Rate",
  },
  {
    value: "300+",
    label: "Professional Styles",
  },
];

const professionImages = [
  {
    id: 1,
    src: require("../assets/onboarding/styleSelection/man/profession/architect_1.png"),
    title: "Architect",
  },
  {
    id: 2,
    src: require("../assets/onboarding/styleSelection/man/profession/art_director_1.png"),
    title: "Art Director",
  },
  {
    id: 3,
    src: require("../assets/onboarding/styleSelection/man/profession/attorney_1.png"),
    title: "Attorney",
  },
  {
    id: 4,
    src: require("../assets/onboarding/styleSelection/man/profession/business_consultant_1.png"),
    title: "Business Consultant",
  },
  {
    id: 5,
    src: require("../assets/onboarding/styleSelection/man/profession/business_leader_1.png"),
    title: "Business Leader",
  },
  {
    id: 6,
    src: require("../assets/onboarding/styleSelection/man/profession/ceo_1.png"),
    title: "CEO",
  },
  {
    id: 7,
    src: require("../assets/onboarding/styleSelection/man/profession/consultant_1.png"),
    title: "Consultant",
  },
  {
    id: 8,
    src: require("../assets/onboarding/styleSelection/man/profession/creative_director_1.png"),
    title: "Creative Director",
  },
  {
    id: 9,
    src: require("../assets/onboarding/styleSelection/man/profession/cto_1.png"),
    title: "CTO",
  },
  {
    id: 10,
    src: require("../assets/onboarding/styleSelection/man/profession/dean_1.png"),
    title: "Dean",
  },
  {
    id: 11,
    src: require("../assets/onboarding/styleSelection/man/profession/dentist_1.png"),
    title: "Dentist",
  },
  {
    id: 12,
    src: require("../assets/onboarding/styleSelection/man/profession/diplomat_1.png"),
    title: "Diplomat",
  },
  {
    id: 13,
    src: require("../assets/onboarding/styleSelection/man/profession/doctor_1.png"),
    title: "Doctor",
  },
  {
    id: 14,
    src: require("../assets/onboarding/styleSelection/man/profession/education_admin_1.png"),
    title: "Education Admin",
  },
  {
    id: 15,
    src: require("../assets/onboarding/styleSelection/man/profession/engineer_1.png"),
    title: "Engineer",
  },
  {
    id: 16,
    src: require("../assets/onboarding/styleSelection/man/profession/entrepreneur_1.png"),
    title: "Entrepreneur",
  },
  {
    id: 17,
    src: require("../assets/onboarding/styleSelection/man/profession/executive_1.png"),
    title: "Executive",
  },
  {
    id: 18,
    src: require("../assets/onboarding/styleSelection/man/profession/financial_advisor_1.png"),
    title: "Financial Advisor",
  },
  {
    id: 19,
    src: require("../assets/onboarding/styleSelection/man/profession/fire_captain_1.png"),
    title: "Fire Captain",
  },
  {
    id: 20,
    src: require("../assets/onboarding/styleSelection/man/profession/fire_chief_1.png"),
    title: "Fire Chief",
  },
  {
    id: 21,
    src: require("../assets/onboarding/styleSelection/man/profession/firefighter_1.png"),
    title: "Firefighter",
  },
  {
    id: 22,
    src: require("../assets/onboarding/styleSelection/man/profession/government_executive_1.png"),
    title: "Government Executive",
  },
  {
    id: 23,
    src: require("../assets/onboarding/styleSelection/man/profession/healthcare_executive_1.png"),
    title: "Healthcare Executive",
  },
  {
    id: 24,
    src: require("../assets/onboarding/styleSelection/man/profession/investment_banker_1.png"),
    title: "Investment Banker",
  },
  {
    id: 25,
    src: require("../assets/onboarding/styleSelection/man/profession/judge_1.png"),
    title: "Judge",
  },
  {
    id: 26,
    src: require("../assets/onboarding/styleSelection/man/profession/lawyer_1.png"),
    title: "Lawyer",
  },
  {
    id: 27,
    src: require("../assets/onboarding/styleSelection/man/profession/legal_counsel_1.png"),
    title: "Legal Counsel",
  },
  {
    id: 28,
    src: require("../assets/onboarding/styleSelection/man/profession/luxury_realtor_1.png"),
    title: "Luxury Realtor",
  },
  {
    id: 30,
    src: require("../assets/onboarding/styleSelection/man/profession/media_executive_1.png"),
    title: "Media Executive",
  },
  {
    id: 31,
    src: require("../assets/onboarding/styleSelection/man/profession/portfolio_manager_1.png"),
    title: "Portfolio Manager",
  },
  {
    id: 32,
    src: require("../assets/onboarding/styleSelection/man/profession/professor_1.png"),
    title: "Professor",
  },
  {
    id: 33,
    src: require("../assets/onboarding/styleSelection/man/profession/property_developer_1.png"),
    title: "Property Developer",
  },
  {
    id: 34,
    src: require("../assets/onboarding/styleSelection/man/profession/public_official_1.png"),
    title: "Public Official",
  },
  {
    id: 35,
    src: require("../assets/onboarding/styleSelection/man/profession/real_estate_broker_1.png"),
    title: "Real Estate Broker",
  },
  {
    id: 36,
    src: require("../assets/onboarding/styleSelection/man/profession/researcher_1.png"),
    title: "Researcher",
  },
  {
    id: 37,
    src: require("../assets/onboarding/styleSelection/man/profession/sales_director_1.png"),
    title: "Sales Director",
  },
  {
    id: 38,
    src: require("../assets/onboarding/styleSelection/man/profession/software_executive_1.png"),
    title: "Software Executive",
  },
  {
    id: 39,
    src: require("../assets/onboarding/styleSelection/man/profession/startup_founder_1.png"),
    title: "Startup Founder",
  },
  {
    id: 41,
    src: require("../assets/onboarding/styleSelection/man/profession/surgeon_1.png"),
    title: "Surgeon",
  },
  {
    id: 42,
    src: require("../assets/onboarding/styleSelection/man/profession/tech_ceo_1.png"),
    title: "Tech CEO",
  },
  {
    id: 43,
    src: require("../assets/onboarding/styleSelection/man/profession/tech_entrepreneur_1.png"),
    title: "Tech Entrepreneur",
  },
  {
    id: 44,
    src: require("../assets/onboarding/styleSelection/man/profession/tech_lead_1.png"),
    title: "Tech Lead",
  },
  {
    id: 45,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_academic_director_1.png"),
    title: "Academic Director",
  },
  {
    id: 46,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_attorney_1.png"),
    title: "Attorney",
  },
  {
    id: 47,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_business_leader_1.png"),
    title: "Business Leader",
  },
  {
    id: 48,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_ceo_1.png"),
    title: "CEO",
  },
  {
    id: 49,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_corporate_director_1.png"),
    title: "Corporate Director",
  },
  {
    id: 50,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_creative_director_1.png"),
    title: "Creative Director",
  },
  {
    id: 52,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_dean_1.png"),
    title: "Dean",
  },
  {
    id: 53,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_dentist_1.png"),
    title: "Dentist",
  },
  {
    id: 54,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_diplomat_1.png"),
    title: "Diplomat",
  },
  {
    id: 55,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_doctor_1.png"),
    title: "Doctor",
  },
  {
    id: 56,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_education_admin_1.png"),
    title: "Education Admin",
  },
  {
    id: 57,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_environmental_consultant_1.png"),
    title: "Environmental Consultant",
  },
  {
    id: 58,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_environmental_executive_1.png"),
    title: "Environmental Executive",
  },
  {
    id: 59,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_executive_1.png"),
    title: "Executive",
  },
  {
    id: 60,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_financial_advisor_1.png"),
    title: "Financial Advisor",
  },
  {
    id: 61,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_government_executive_1.png"),
    title: "Government Executive",
  },
  {
    id: 62,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_green_technology_leader_1.png"),
    title: "Green Technology Leader",
  },
  {
    id: 63,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_healthcare_executive_1.png"),
    title: "Healthcare Executive",
  },
  {
    id: 64,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_investment_banker_1.png"),
    title: "Investment Banker",
  },
  {
    id: 65,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_judge_1.png"),
    title: "Judge",
  },
  {
    id: 66,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_lead_researcher_1.png"),
    title: "Lead Researcher",
  },
  {
    id: 67,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_legal_counsel_1.png"),
    title: "Legal Counsel",
  },
  {
    id: 68,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_luxury_realtor_1.png"),
    title: "Luxury Realtor",
  },
  {
    id: 69,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_marketing_executive_1.png"),
    title: "Marketing Executive",
  },
  {
    id: 70,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_portfolio_manager_1.png"),
    title: "Portfolio Manager",
  },
  {
    id: 71,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_professor_1.png"),
    title: "Professor",
  },
  {
    id: 72,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_property_developer_1.png"),
    title: "Property Developer",
  },
  {
    id: 73,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_public_official_1.png"),
    title: "Public Official",
  },
  {
    id: 74,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_real_estate_broker_1.png"),
    title: "Real Estate Broker",
  },
  {
    id: 75,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_research_scientist_1.png"),
    title: "Research Scientist",
  },
  {
    id: 76,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_researcher_1.png"),
    title: "Researcher",
  },
  {
    id: 77,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_sales_director_1.png"),
    title: "Sales Director",
  },
  {
    id: 78,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_school_principal_1.png"),
    title: "School Principal",
  },
  {
    id: 79,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_science_administrator_1.png"),
    title: "Science Administrator",
  },
  {
    id: 80,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_scientific_director_1.png"),
    title: "Scientific Director",
  },
  {
    id: 81,
    src: require("../assets/onboarding/styleSelection/woman/profession/female_surgeon_1.png"),
    title: "Surgeon",
  },
];

const additionalWomenImages = [
  {
    id: 92,
    src: require("../assets/onboarding/styleSelection/woman/business_conference_1_1.png"),
    title: "Business Conference",
  },
  {
    id: 93,
    src: require("../assets/onboarding/styleSelection/woman/headshot_minimalist_1.png"),
    title: "Minimalist Style",
  },
  {
    id: 95,
    src: require("../assets/onboarding/styleSelection/woman/environmental_office_1.png"),
    title: "Office Environment",
  },
  {
    id: 96,
    src: require("../assets/onboarding/styleSelection/woman/headshot_gradient_1_1.png"),
    title: "Gradient Style",
  },
  {
    id: 97,
    src: require("../assets/onboarding/styleSelection/woman/headshot_blue_gradient_1.png"),
    title: "Corporate Style",
  },
  {
    id: 98,
    src: require("../assets/onboarding/styleSelection/woman/lighting_studio_dramatic_1.png"),
    title: "Dramatic Studio",
  },
  {
    id: 99,
    src: require("../assets/onboarding/styleSelection/woman/lighting_studio_soft_1.png"),
    title: "Soft Studio",
  },
  {
    id: 100,
    src: require("../assets/onboarding/styleSelection/woman/environment_urban_1_1.png"),
    title: "Urban Professional",
  },
  {
    id: 101,
    src: require("../assets/onboarding/styleSelection/woman/casual_modern_1.png"),
    title: "Modern Casual",
  },
];

const additionalMenImages = [
  {
    id: 111,
    src: require("../assets/onboarding/styleSelection/man/lighting_golden_hour_1.png"),
    title: "Golden Hour",
  },
  {
    id: 112,
    src: require("../assets/onboarding/styleSelection/man/headshot_outdoor_sunset_1.png"),
    title: "Outdoor Sunset",
  },
  {
    id: 113,
    src: require("../assets/onboarding/styleSelection/man/casual_open_collar_1.png"),
    title: "Casual Open Collar",
  },
  {
    id: 114,
    src: require("../assets/onboarding/styleSelection/man/headshot_studio_textured_1.png"),
    title: "Studio Textured",
  },
  {
    id: 115,
    src: require("../assets/onboarding/styleSelection/man/lighting_natural_outdoor_1.png"),
    title: "Natural Outdoor",
  },
  {
    id: 116,
    src: require("../assets/onboarding/styleSelection/man/headshot_outdoor_architecture_1.png"),
    title: "Architectural",
  },
  {
    id: 117,
    src: require("../assets/onboarding/styleSelection/man/clothing_suit_no_tie_1.png"),
    title: "Modern Business",
  },
  {
    id: 118,
    src: require("../assets/onboarding/styleSelection/man/headshot_beige_neutral_1.png"),
    title: "Neutral Tone",
  },
  {
    id: 119,
    src: require("../assets/onboarding/styleSelection/man/lighting_studio_dramatic_1.png"),
    title: "Dramatic Studio",
  },
];

// Add this function before the component
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const GallerySection = ({ images, title, description }) => (
  <div className="mb-16">
    <div className="text-center mb-8">
      <h2 className="text-2xl sm:text-3xl font-bold mb-3">{title}</h2>
      <p className="text-gray-600">{description}</p>
    </div>
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3 sm:gap-4 md:gap-6">
      {images.map((image) => (
        <div
          key={image.id}
          className="group relative transform transition-all duration-300 hover:-translate-y-1 hover:shadow-xl rounded-xl overflow-hidden"
        >
          <div className="aspect-w-1 aspect-h-1 w-full bg-gray-200">
            <img
              src={image.src}
              alt={`${image.title} headshot`}
              className="w-full h-full object-cover object-center transition-transform duration-500 group-hover:scale-110"
              loading="lazy"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-all duration-300" />
            <div className="absolute bottom-0 left-0 right-0 p-4 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300">
              <p className="text-white font-medium text-sm sm:text-base">
                {image.title}
              </p>
              <p className="text-gray-200 text-xs sm:text-sm mt-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-100">
                Professional AI Headshot
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default function SampleGallery() {
  const pricingRef = useRef(null);
  const bannerRef = useRef(null);
  const faqRef = useRef(null);

  // Move the randomization inside the component
  const allImages = [
    ...professionImages,
    ...additionalWomenImages,
    ...additionalMenImages,
  ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const randomizedImages = useMemo(() => shuffleArray([...allImages]), []);

  const CallToAction = () => (
    <div className="px-2 sm:px-4 py-8 sm:py-16">
      <div className="mx-auto max-w-6xl bg-gradient-to-r from-[#FFF5F1] to-[#FFF8F3] rounded-2xl sm:rounded-3xl overflow-hidden">
        <div className="flex flex-col items-center justify-center text-center px-4 sm:px-8 py-8 sm:py-12">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6">
            Stand Out in Your <span className="text-[#F06E28]">Industry</span>
          </h2>
          <p className="text-base sm:text-lg mb-6 sm:mb-8 max-w-2xl">
            Join thousands of professionals who've elevated their personal brand
            with our AI-powered headshots
          </p>
          <Link
            to="/auth"
            className="inline-flex items-center px-6 sm:px-8 py-3 bg-black text-white rounded-full text-base sm:text-lg font-medium hover:opacity-90"
          >
            Get Started Now →
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <GHeader pricingRef={pricingRef} bannerRef={bannerRef} />

      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-16">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6 bg-gradient-to-r from-orange-500 to-amber-400 bg-clip-text text-transparent">
            Transform Your Professional Image in Minutes
          </h1>
          <p className="text-gray-600 text-lg sm:text-xl max-w-3xl mx-auto mb-8">
            Create stunning professional headshots with AI technology. Perfect
            for LinkedIn, company websites, and personal branding
          </p>

          {/* Metrics Cards */}
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 max-w-4xl mx-auto">
            {metrics.map((metric, index) => (
              <div
                key={index}
                className="bg-white rounded-2xl p-6 shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                <div className="text-3xl font-bold text-orange-500 mb-2">
                  {metric.value}
                </div>
                <div className="text-gray-600">{metric.label}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Professional Headshots Section */}
        <GallerySection
          images={randomizedImages.slice(0, 40)}
          title="Professional Headshots by Industry"
          description="Tailored headshots for every profession, powered by AI"
        />

        {/* Middle CTA */}
        <CallToAction />

        {/* Style Variations Section */}
        <GallerySection
          images={randomizedImages.slice(40)}
          title="Explore Different Styles"
          description="From classic corporate to modern creative looks"
        />

        {/* Final CTA */}
        <Try />
      </div>

      <GFooter pricingRef={pricingRef} faqRef={faqRef} />
    </>
  );
}
