import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { asyncGetUser } from "./user";

const initialState = {
  isFetched: false,
  isLoading: false,
  data: [],
  error: null,
};

const localToken = localStorage?.getItem("token");

export const asyncGetVouchers = createAsyncThunk(
  "vouchers/getVouchers",
  async ({ userToken }, { rejectWithValue }) => {
    try {
      const token = localToken || userToken;

      if (!token) {
        window.location.href = "/auth";
        return initialState;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/vouchers`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        return response.data.vouchers;
      }
      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncClaimGiftCard = createAsyncThunk(
  "vouchers/claimGiftCard",
  async ({ userToken, code }, { rejectWithValue, dispatch }) => {
    try {
      const token = localToken || userToken;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payment/apply-gift-card`,
        { code },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        dispatch(asyncGetUser({ userToken: token }));
        return response.data;
      }
      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const vouchersSlice = createSlice({
  name: "vouchers",
  initialState,
  reducers: {
    setVouchers: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetVouchers.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(asyncGetVouchers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
      state.isFetched = true;
    });
    builder.addCase(asyncGetVouchers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isFetched = true;
    });
  },
});

export const vouchersSelector = (state) => state.vouchers;

export const { setVouchers } = vouchersSlice.actions;

export default vouchersSlice.reducer;
