import React, { useRef } from "react";
import GHeader from "./layout/GHeader";
import GFooter from "./layout/GFooter";
import Try from "./landing/Try";

export default function RefundPolicy() {
  const bannerRef = useRef();
  const pricingRef = useRef();

  return (
    <div>
      <GHeader pricingRef={pricingRef} bannerRef={bannerRef} />

      <div className="max-w-screen-xl mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-8">Refund Policy</h1>
        <p className="text-gray-600 mb-4">Last updated: November 22, 2024</p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Our Commitment to You</h2>
          <p className="text-gray-700 mb-4">
            HeadShotlyAI is committed to delivering high-quality AI-enhanced
            photos that meet your expectations. While close to 99% of our
            customers are delighted with their AI headshots, we understand there
            may be instances where your photos don't accurately represent you
            for various reasons.
          </p>
          <p className="text-gray-700 mb-4">
            That's why we offer a comprehensive satisfaction guarantee:
          </p>
          <ul className="list-disc pl-6 mb-4 text-gray-700">
            <li className="mb-3">
              14-day money-back guarantee on all purchases
            </li>
            <li className="mb-3">
              Complimentary redo of your photos if needed
            </li>
            <li className="mb-3">
              100% refund if you're not completely satisfied
            </li>
          </ul>
          <p className="text-gray-700 mb-4">
            To initiate the refund process, simply submit your request with a
            brief explanation of your concerns. Your satisfaction is our top
            priority, and we stand behind the quality of our AI-generated
            headshots.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            Refund Eligibility Requirements
          </h2>
          <p className="text-gray-700 mb-4">
            To maintain the integrity of our service and ensure fair usage, the
            following conditions apply:
          </p>
          <ul className="list-disc pl-6 mb-4 text-gray-700">
            <li className="mb-3">
              <span className="font-semibold">Service Usage Guidelines:</span>{" "}
              Refunds will not be issued in cases where our service has been
              used in violation of our terms, including but not limited to:
              uploading prohibited content, attempting to circumvent system
              limitations, or any other form of service misuse.
            </li>
            <li className="mb-3">
              <span className="font-semibold">Refund Limitation:</span>{" "}
              Customers are eligible for one refund per account. This policy
              ensures a fair evaluation period while maintaining the
              sustainability of our service. Subsequent purchases are considered
              final.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            Technical Issues and Service Interruptions
          </h2>
          <p className="text-gray-700 mb-4">
            In the event of technical difficulties or service interruptions that
            significantly impact your ability to use our service, please contact
            our support team immediately. Such cases will be evaluated
            individually, and appropriate compensation, including potential
            refunds, will be determined based on the specific circumstances.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
          <p className="text-gray-700 mb-4">
            For refund requests or questions regarding our refund policy, please
            contact our support team at{" "}
            <a
              href="mailto:support@headshotly.ai"
              className="text-blue-700 hover:text-blue-700"
            >
              support@headshotly.ai
            </a>
            . We aim to process all refund requests within 3-5 business days.
          </p>
        </section>
      </div>
      <Try />
      <GFooter />
    </div>
  );
}
