import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  histories: [],
  error: null,
};

export const asyncGetHistories = createAsyncThunk(
  "history/getHistories",
  async ({ userToken }, { rejectWithValue }) => {
    const localToken = localStorage?.getItem("token");
    try {
      const token = localToken || userToken;
      if (!token) {
        window.location.href = "/auth";
        return initialState;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/history`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    setHistory: (state, action) => {
      state.histories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetHistories.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(asyncGetHistories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.histories = action.payload;
      state.error = null;
    });
    builder.addCase(asyncGetHistories.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const historySelector = (state) => state.history;

export const getHistory = (model) =>
  createSelector([historySelector], (state) => {
    if (!model) {
      return state;
    } else {
      const _histories = Array.isArray(state?.histories) ? state.histories : [];
      const histories = _histories.filter((item) => item?.model === model);
      return {
        ...state,
        histories,
      };
    }
  });

export const { setHistory } = historySlice.actions;

export default historySlice.reducer;
