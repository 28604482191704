import React, { useRef } from "react";
import { Typography, Container, Box } from "@mui/material";
import GHeader from "./layout/GHeader";
import Try from "./landing/Try";
import GFooter from "./layout/GFooter";

const PrivacyPolicy = () => {
  const bannerRef = useRef();
  const pricingRef = useRef();

  return (
    <>
      <GHeader pricingRef={pricingRef} bannerRef={bannerRef} />
      <Container maxWidth="md">
        <Box sx={{ my: 4 }}>
          <Typography variant="h3" component="h1" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Effective Date: November 25, 2024
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            Introduction
          </Typography>
          <Typography paragraph>
            Welcome to HeadShotlyAI ("we," "us," or "our"). Your privacy is
            important to us, and we are committed to protecting your personal
            data. This Privacy Policy explains how we collect, use, disclose,
            and safeguard your information when you use our services, including
            our website www.headshotly.ai and any related services
            (collectively, the "Service"). It also outlines your rights under
            applicable data protection laws, including the European General Data
            Protection Regulation (GDPR) and the California Consumer Privacy Act
            (CCPA)/California Privacy Rights Act (CPRA).
          </Typography>
          <Typography paragraph>
            By using our Service, you trust us with your personal data, and we
            take that responsibility seriously. We do not sell your personal
            data or use your images for marketing purposes. Please read this
            Privacy Policy carefully to understand how we handle your
            information.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            1. Information We Collect
          </Typography>
          <Typography variant="h6" gutterBottom>
            Personal Data We Collect
          </Typography>
          <Typography paragraph>
            We collect only the information necessary to provide and improve our
            Service:
          </Typography>
          <Typography component="ul">
            <li>
              Email Address: Used for account creation, login, and communication
              purposes.
            </li>
            <li>
              Photographs: Images and selfies you upload for processing by our
              AI to generate professional, studio-quality headshots.
            </li>
            <li>
              Usage Data: Information automatically collected when you interact
              with our Service, including:
            </li>
          </Typography>
          <Typography component="ul" sx={{ pl: 4 }}>
            <li>IP Address</li>
            <li>Browser Type and Version</li>
            <li>Operating System</li>
            <li>Referring URLs</li>
            <li>Pages Visited and Actions Taken</li>
            <li>Date and Time of Access</li>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Cookies and Similar Technologies
          </Typography>
          <Typography paragraph>
            We use cookies and similar tracking technologies to enhance your
            experience:
          </Typography>
          <Typography component="ul">
            <li>
              Essential Cookies: Necessary for the operation of the Service.
            </li>
            <li>
              Analytical Cookies: Help us understand how you use the Service, so
              we can improve it.
            </li>
            <li>
              Preference Cookies: Remember your preferences to provide a more
              personalized experience.
            </li>
          </Typography>
          <Typography paragraph>
            You can control the use of cookies through your browser settings.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            2. How We Use Your Information
          </Typography>
          <Typography paragraph>
            We use your personal data solely for the following purposes:
          </Typography>

          <Typography variant="h6" gutterBottom>
            Service Provision and Enhancement
          </Typography>
          <Typography component="ul">
            <li>
              Processing Photographs: To generate your professional headshots
              using our AI technology.
            </li>
            <li>
              Account Management: To create and maintain your account, including
              authentication and security measures.
            </li>
            <li>
              Customer Support: To respond to your inquiries, troubleshoot
              issues, and provide technical assistance.
            </li>
          </Typography>

          <Typography variant="h6" gutterBottom>
            Communication
          </Typography>
          <Typography component="ul">
            <li>
              Transactional Emails: To send you confirmations, invoices,
              technical notices, updates, security alerts, and support messages.
            </li>
            <li>
              Important Notices: To inform you about changes to our Service,
              terms, and policies.
            </li>
          </Typography>
          <Typography paragraph>
            We do not send marketing or promotional emails unless you have
            explicitly opted in.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Legal and Compliance
          </Typography>
          <Typography component="ul">
            <li>
              Legal Obligations: To comply with applicable laws, regulations,
              legal processes, or governmental requests.
            </li>
            <li>
              Fraud Prevention: To monitor and prevent fraud, unauthorized
              transactions, and other illegal activities.
            </li>
            <li>
              Enforcement: To enforce our Terms of Service and other agreements.
            </li>
          </Typography>

          <Typography variant="h6" gutterBottom>
            Analytics and Improvement
          </Typography>
          <Typography component="ul">
            <li>
              Usage Analysis: To analyze trends, usage, and activities to
              improve the functionality and user experience of our Service.
            </li>
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            3. How We Protect Your Information
          </Typography>
          <Typography paragraph>
            We are committed to safeguarding your personal data:
          </Typography>
          <Typography component="ul">
            <li>
              Data Encryption: All data transmission is secured using TLS 1.2 or
              higher. Data at rest is encrypted using AES-256 encryption.
            </li>
            <li>
              Secure Storage: Your photographs are stored securely on our
              servers hosted by Amazon Web Services (AWS) with enterprise-grade
              security.
            </li>
            <li>
              Access Controls: Strict access controls are in place; only
              authorized personnel with a legitimate need can access your data.
            </li>
            <li>
              Regular Audits: We perform regular security assessments and audits
              to ensure the integrity of our systems.
            </li>
          </Typography>
          <Typography paragraph>
            Despite our efforts, no security measure is completely foolproof. We
            cannot guarantee absolute security, but we strive to protect your
            data to the best of our ability.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            4. How We Share Your Information
          </Typography>
          <Typography paragraph>
            We respect your privacy and do not sell or rent your personal data
            to third parties. We only share your information in the following
            limited circumstances:
          </Typography>

          <Typography variant="h6" gutterBottom>
            Service Providers
          </Typography>
          <Typography paragraph>
            We may share your information with trusted third-party service
            providers who assist us in operating our Service:
          </Typography>
          <Typography component="ul">
            <li>
              Payment Processing: Stripe handles all payment transactions
              securely; we do not store your full payment card details.
            </li>
            <li>
              Cloud Storage and Hosting: Amazon Web Services (AWS) securely
              hosts our servers and data.
            </li>
            <li>
              Analytics: We may use analytics providers to help us understand
              how users interact with our Service.
            </li>
          </Typography>
          <Typography paragraph>
            All service providers are contractually obligated to protect your
            data and are prohibited from using it for any other purposes.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Legal Requirements
          </Typography>
          <Typography paragraph>
            We may disclose your information if required to do so by law or in
            response to valid requests by public authorities (e.g., a court or
            government agency).
          </Typography>

          <Typography variant="h6" gutterBottom>
            Business Transfers
          </Typography>
          <Typography paragraph>
            In the event of a merger, acquisition, or sale of all or a portion
            of our assets, your personal data may be transferred to the new
            owner. We will notify you before your personal data is transferred
            and becomes subject to a different Privacy Policy.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            5. International Data Transfers
          </Typography>
          <Typography paragraph>
            Your information may be transferred to and processed in countries
            other than the country you reside in. These countries may have data
            protection laws that are different from those of your country.
          </Typography>

          <Typography variant="h6" gutterBottom>
            For European Economic Area (EEA) Residents
          </Typography>
          <Typography paragraph>
            When we transfer personal data outside of the EEA, we ensure it is
            protected by:
          </Typography>
          <Typography component="ul">
            <li>
              Standard Contractual Clauses: We use contractual provisions
              approved by the European Commission.
            </li>
            <li>
              Adequacy Decisions: Transferring data to countries recognized by
              the European Commission as providing an adequate level of data
              protection.
            </li>
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            6. Your Rights and Choices
          </Typography>
          <Typography paragraph>
            We respect your rights regarding your personal data and provide you
            with access to and control over your information.
          </Typography>

          <Typography variant="h6" gutterBottom>
            For EEA Residents (GDPR)
          </Typography>
          <Typography paragraph>You have the right to:</Typography>
          <Typography component="ul">
            <li>
              Access: Request a copy of the personal data we hold about you.
            </li>
            <li>
              Rectification: Request correction of inaccurate or incomplete
              data.
            </li>
            <li>
              Erasure: Request deletion of your personal data under certain
              circumstances.
            </li>
            <li>
              Restriction: Request restriction of processing your personal data.
            </li>
            <li>
              Data Portability: Receive your personal data in a structured,
              commonly used format.
            </li>
            <li>
              Objection: Object to processing your personal data for certain
              purposes.
            </li>
            <li>
              Withdraw Consent: Withdraw consent at any time where we are
              relying on consent to process your data.
            </li>
          </Typography>

          <Typography variant="h6" gutterBottom>
            For California Residents (CCPA/CPRA)
          </Typography>
          <Typography paragraph>You have the right to:</Typography>
          <Typography component="ul">
            <li>
              Know: Request disclosure of the categories and specific pieces of
              personal information we have collected.
            </li>
            <li>
              Delete: Request deletion of your personal information, subject to
              certain exceptions.
            </li>
            <li>
              Non-Discrimination: Not be discriminated against for exercising
              your privacy rights.
            </li>
          </Typography>
          <Typography paragraph>
            To exercise any of these rights, please contact us at
            support@headshotly.ai. We will respond to your request within the
            timeframes required by law.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            7. Data Retention
          </Typography>
          <Typography paragraph>
            We retain your personal data only for as long as necessary to
            fulfill the purposes outlined in this Privacy Policy:
          </Typography>
          <Typography component="ul">
            <li>
              Photographs: Stored securely and deleted automatically within 30
              days after processing to allow for any necessary reprocessing.
            </li>
            <li>
              Account Information: Retained as long as your account is active.
              If you choose to delete your account, we will delete your personal
              data unless required to retain it for legal obligations.
            </li>
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            8. Children's Privacy
          </Typography>
          <Typography paragraph>
            Our Service is not intended for individuals under the age of 16. We
            do not knowingly collect personal data from children under 16. If
            you are a parent or guardian and become aware that your child has
            provided us with personal data, please contact us immediately at
            support@headshotly.ai, and we will take steps to remove that
            information.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            9. Third-Party Links and Services
          </Typography>
          <Typography paragraph>
            Our Service may contain links to third-party websites or services
            that are not owned or controlled by HeadShotlyAI. We are not
            responsible for the privacy practices of such third parties. We
            encourage you to review the privacy policies of every website you
            visit.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            10. Do Not Track Signals
          </Typography>
          <Typography paragraph>
            Our Service does not respond to "Do Not Track" signals. However, we
            respect your privacy and provide opt-out options for cookies and
            certain tracking technologies.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            11. Changes to This Privacy Policy
          </Typography>
          <Typography paragraph>
            We may update our Privacy Policy from time to time to reflect
            changes in our practices or for other operational, legal, or
            regulatory reasons. We will notify you of any significant changes
            by:
          </Typography>
          <Typography component="ul">
            <li>Posting the updated Privacy Policy on this page.</li>
            <li>
              Updating the "Effective Date" at the top of this Privacy Policy.
            </li>
            <li>
              Sending an email notification if you have provided your email
              address.
            </li>
          </Typography>
          <Typography paragraph>
            We encourage you to review this Privacy Policy periodically to stay
            informed about how we are protecting your information.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            12. Your Consent
          </Typography>
          <Typography paragraph>
            By using our Service, you consent to this Privacy Policy and agree
            to its terms.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            13. Contact Us
          </Typography>
          <Typography paragraph>
            Your trust is important to us. If you have any questions, concerns,
            or requests regarding this Privacy Policy or our data practices,
            please do not hesitate to contact us:
          </Typography>
          <Typography paragraph>Email: support@headshotly.ai</Typography>
          <Typography paragraph>Postal Address:</Typography>
          <Typography component="div" sx={{ pl: 2 }}>
            HeadShotlyAI
            <br />
            Wileńska 14
            <br />
            03-409 Warszawa, Poland
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            14. Additional Information
          </Typography>
          <Typography variant="h6" gutterBottom>
            No Sale of Personal Data
          </Typography>
          <Typography paragraph>
            We do not sell, rent, or trade your personal data to third parties
            for any purpose.
          </Typography>

          <Typography variant="h6" gutterBottom>
            No Use of Images for Marketing
          </Typography>
          <Typography paragraph>
            We respect your privacy and intellectual property. We will never use
            your uploaded images or generated headshots for marketing,
            advertising, or any purpose other than providing the Service to you.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Data Protection Officer (DPO)
          </Typography>
          <Typography paragraph>
            If you have any concerns about your data, you may contact our Data
            Protection Officer at support@headshotly.ai.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Lodging a Complaint
          </Typography>
          <Typography paragraph>
            If you are in the EEA and believe we have not complied with data
            protection laws, you have the right to lodge a complaint with your
            local supervisory authority.
          </Typography>

          <Typography paragraph sx={{ mt: 4 }}>
            Thank you for trusting HeadShotlyAI with your personal data. We are
            committed to protecting your privacy and ensuring that your
            experience with us is safe and enjoyable.
          </Typography>
        </Box>
      </Container>
      <Try />
      <GFooter />
    </>
  );
};

export default PrivacyPolicy;
