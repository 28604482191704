import { useCallback } from "react";
import { useSelector } from "react-redux";
import Pricing from "./Pricing";
import StyleSelection from "./StyleSelection";

export default function OnboardingFlow() {
  const { data: user, loading } = useSelector((state) => state.user);

  const isCompleted = useCallback(
    (key) => {
      const onboarding = user?.onboarding;
      if (!onboarding || !Object.keys(onboarding).length) return false;
      return Boolean(onboarding[key]?.completed);
    },
    [user?.onboarding]
  );

  if (!user || loading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white" />
    );
  }

  return (
    <div className="min-h-screen">
      {!isCompleted("styleSelection") ? (
        <StyleSelection />
      ) : !isCompleted("pricing") ? (
        <Pricing />
      ) : null}
    </div>
  );
}
