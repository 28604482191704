import { useCallback, useState } from "react";
import axios from "axios";

const STRIPE_CHECKOUT_ENDPOINT = `${process.env.REACT_APP_API_URL}/api/payment/create-checkout-session`;

const useStripeCheckout = (affiliateEnabled = false) => {
  const [disabled, setDisabled] = useState(false);

  const createCheckoutSession = async (payload, token) => {
    return axios.post(STRIPE_CHECKOUT_ENDPOINT, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const openCheckoutWindow = (url, openInNewWindow) => {
    const stripeWindow = window.open("", openInNewWindow ? "_blank" : "_self");

    if (stripeWindow) {
      stripeWindow.location.href = url;
    } else {
      window.location.href = url;
    }
  };

  const handleCheckout = useCallback(
    async ({
      priceId,
      quantity = 1,
      buyFor = "self",
      openInNewWindow = false,
    }) => {
      if (disabled) return;

      setDisabled(true);
      const token = localStorage.getItem("token");

      try {
        const payload = {
          priceKey: priceId,
          buyFor,
          quantity,
          ...(affiliateEnabled && {
            toltReferral: window?.tolt_referral,
          }),
        };

        const { data } = await createCheckoutSession(payload, token);

        if (data?.success && data?.url) {
          openCheckoutWindow(data.url, openInNewWindow);
        }
      } catch (error) {
        console.error("Error creating checkout session");
      } finally {
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          setDisabled(false);
        }, 2000);
      }
    },
    [affiliateEnabled, disabled]
  );

  return { handleCheckout, disabled, openCheckoutWindow };
};

export default useStripeCheckout;
