const ltdPrices = [
  {
    key: "basic",
    name: "LTD Basic",
    price: "65.00",
    period: "lifetime",
    priceId: "price_ltd_basic",
    features: [
      {
        key: "1",
        content: "Professional Photos: 300",
      },
      {
        key: "2",
        content: "Personal Model Training: 5 Sessions",
      },
      {
        key: "3",
        content: "Ready-to-Use AI Photos: 50 per model",
      },
      {
        key: "4",
        content: "Custom Image Prompt Credits: 50",
      },
      {
        key: "5",
        content: "Style Variations: 100+",
      },
      {
        key: "6",
        content: "Premium Backgrounds: 150+",
      },
      {
        key: "7",
        content: "Delivery Time: 30 Minutes",
      },
      {
        key: "8",
        content: "Money-back Guarantee",
      },
      {
        key: "9",
        content: "",
      },
      {
        key: "10",
        content: "",
      },
    ],
  },
  {
    key: "advanced",
    name: "LTD Advanced",
    price: "150.00",
    period: "lifetime",
    priceId: "price_ltd_advanced",
    features: [
      {
        key: "1",
        content: "Professional Photos: 600",
      },
      {
        key: "2",
        content: "Personal Model Training: 10 Sessions",
      },
      {
        key: "3",
        content: "Ready-to-Use AI Photos: 50 per model",
      },
      {
        key: "4",
        content: "Custom Image Prompt Credits: 100",
      },
      {
        key: "5",
        content: "Style Variations: 200+",
      },
      {
        key: "6",
        content: "Premium Backgrounds: 300+",
      },
      {
        key: "7",
        content: "Express Delivery: 20 Minutes",
      },
      {
        key: "8",
        content: "Money-back Guarantee",
      },
      {
        key: "9",
        content: "",
      },
      {
        key: "10",
        content: "",
      },
    ],
  },
  {
    key: "pro",
    name: "LTD Pro",
    price: "200.00",
    period: "lifetime",
    priceId: "price_ltd_pro",
    features: [
      {
        key: "1",
        content: "Professional Photos: 1,000",
      },
      {
        key: "2",
        content: "Personal Model Training: 15 Sessions",
      },
      {
        key: "3",
        content: "Ready-to-Use AI Photos: 50 per model",
      },
      {
        key: "4",
        content: "Custom Image Prompt Credits: 250",
      },
      {
        key: "5",
        content: "Style Variations: 250+",
      },
      {
        key: "6",
        content: "Premium Backgrounds: 400+",
      },
      {
        key: "7",
        content: "Priority Processing: 15 Minutes",
      },
      {
        key: "8",
        content: "Priority Support: Included",
      },
      {
        key: "9",
        content: "Money-back Guarantee",
      },
      {
        key: "10",
        content: "",
      },
    ],
  },
  {
    key: "professional",
    name: "LTD Professional",
    price: "300.00",
    period: "lifetime",
    priceId: "price_ltd_professional",
    features: [
      {
        key: "1",
        content: "Professional Photos: 1,500",
      },
      {
        key: "2",
        content: "Personal Model Training: 25 Sessions",
      },
      {
        key: "3",
        content: "Ready-to-Use AI Photos: 50 per model",
      },
      {
        key: "4",
        content: "Custom Image Prompt Credits: 250",
      },
      {
        key: "5",
        content: "Style Variations: 300+",
      },
      {
        key: "6",
        content: "Premium Backgrounds: 450+",
      },
      {
        key: "7",
        content: "VIP Processing: 10 Minutes",
      },
      {
        key: "8",
        content: "Dedicated Support",
      },
      {
        key: "9",
        content: "Money-back Guarantee",
      },
      {
        key: "10",
        content: "",
      },
    ],
  },
  {
    key: "enterprise",
    name: "LTD Enterprise",
    price: "400.00",
    period: "lifetime",
    priceId: "price_ltd_enterprise",
    features: [
      {
        key: "1",
        content: "Professional Photos: 2,000",
      },
      {
        key: "2",
        content: "Personal Model Training: 35 Sessions",
      },
      {
        key: "3",
        content: "Ready-to-Use AI Photos: 100 per model",
      },
      {
        key: "4",
        content: "Custom Image Prompt Credits: 500",
      },
      {
        key: "5",
        content: "Style Variations: All 300+",
      },
      {
        key: "6",
        content: "Premium Backgrounds: All 500+",
      },
      {
        key: "7",
        content: "Instant Processing: 10 Minutes",
      },
      {
        key: "8",
        content: "Dedicated Account Manager",
      },
      {
        key: "9",
        content: "Custom Branding Options",
      },
      {
        key: "10",
        content: "Money-back Guarantee",
      },
    ],
  }
  // {
  //   key: "content_creator",
  //   name: "LTD For Content Creator",
  //   price: "600.00",
  //   period: "lifetime",
  //   priceId: "price_ltd_content_creator",
  //   features: [
  //     {
  //       key: "1",
  //       content: "Professional Photos In total: 150 per month"
  //     },
  //     {
  //       key: "2",
  //       content: "Personal Model Training: 1 Session per month"
  //     },
  //     {
  //       key: "3",
  //       content: "Ready-to-Use AI Photos: 50 per model"
  //     },
  //     {
  //       key: "4",
  //       content: "Custom Image Prompt Credits: 100 per month"
  //     },
  //     {
  //       key: "5",
  //       content: "Style Variations: All 300+"
  //     },
  //     {
  //       key: "6",
  //       content: "Premium Backgrounds: All 500+"
  //     },
  //     {
  //       key: "7",
  //       content: "Instant Processing: 10 Minutes"
  //     },
  //     {
  //       key: "8",
  //       content: "Dedicated Account Manager"
  //     },
  //     {
  //       key: "9",
  //       content: "Custom Branding Options"
  //     },
  //     {
  //       key: "10",
  //       content: "Money-back Guarantee"
  //     }
  //   ]
  // },
  // {
  //   key: "agency",
  //   name: "LTD For Agency",
  //   price: "1000.00",
  //   period: "lifetime",
  //   priceId: "price_ltd_agency",
  //   features: [
  //     {
  //       key: "1",
  //       content: "Professional Photos In total: 250 per month"
  //     },
  //     {
  //       key: "2",
  //       content: "Personal Model Training: 2 Sessions per month"
  //     },
  //     {
  //       key: "3",
  //       content: "Ready-to-Use AI Photos: 50 per model"
  //     },
  //     {
  //       key: "4",
  //       content: "Custom Image Prompt Credits: 150 per month"
  //     },
  //     {
  //       key: "5",
  //       content: "Style Variations: All 300+"
  //     },
  //     {
  //       key: "6",
  //       content: "Premium Backgrounds: All 500+"
  //     },
  //     {
  //       key: "7",
  //       content: "Priority VIP Processing: 10 Minutes"
  //     },
  //     {
  //       key: "8",
  //       content: "Dedicated Account Manager"
  //     },
  //     {
  //       key: "9",
  //       content: "Custom Branding Options"
  //     },
  //     {
  //       key: "10",
  //       content: "Money-back Guarantee"
  //     }
  //   ]
  // },
  // {
  //   key: "unlimited",
  //   name: "LTD Unlimited",
  //   price: "1500.00",
  //   period: "lifetime",
  //   priceId: "price_ltd_unlimited",
  //   features: [
  //     {
  //       key: "1",
  //       content: "Professional Photos In total: 400 per month"
  //     },
  //     {
  //       key: "2",
  //       content: "Personal Model Training: 5 Sessions per month"
  //     },
  //     {
  //       key: "3",
  //       content: "Ready-to-Use AI Photos: 50 per model"
  //     },
  //     {
  //       key: "4",
  //       content: "Custom Image Prompt Credits: 250 per month"
  //     },
  //     {
  //       key: "5",
  //       content: "Style Variations: All 300+"
  //     },
  //     {
  //       key: "6",
  //       content: "Premium Backgrounds: All 500+"
  //     },
  //     {
  //       key: "7",
  //       content: "Instant VIP Processing: 10 Minutes"
  //     },
  //     {
  //       key: "8",
  //       content: "24/7 Priority Support"
  //     },
  //     {
  //       key: "9",
  //       content: "Custom Branding Options"
  //     },
  //     {
  //       key: "10",
  //       content: "Money-back Guarantee"
  //     }
  //   ]
  // }
];

export default ltdPrices;
