import { useState, useEffect } from "react";
import OneByOne from "../assets/imageratio/1-1.png";
import ThreeByFour from "../assets/imageratio/3-4.png";
import FourByThree from "../assets/imageratio/4-3.png";
import NineBySixteen from "../assets/imageratio/9-16.png";
import SixteenByNine from "../assets/imageratio/16-9.png";

const imageRatios = [
  {
    ratio: "1:1",
    image: OneByOne,
  },
  {
    ratio: "3:4",
    image: ThreeByFour,
  },
  {
    ratio: "4:3",
    image: FourByThree,
  },
  {
    ratio: "9:16",
    image: NineBySixteen,
  },
  {
    ratio: "16:9",
    image: SixteenByNine,
  },
];

export default function ImageRatio({ setSelectedRatio }) {
  const [_selectedRatio, _setSelectedRatio] = useState("");

  useEffect(() => {
    if (typeof setSelectedRatio === "function") {
      setSelectedRatio(_selectedRatio);
    }
  }, [_selectedRatio, setSelectedRatio]);

  const handleSelectRatio = (ratio) => {
    if (ratio === _selectedRatio) {
      _setSelectedRatio("");
      return;
    }
    _setSelectedRatio(ratio);
  };

  return (
    <div className="flex flex-row gap-3 items-baseline select-none">
      {imageRatios.map((item) => (
        <div key={item.ratio}>
          <img
            src={item.image}
            alt={item.ratio}
            className={`cursor-pointer rounded-sm ${
              _selectedRatio === item.ratio ? "ring ring-orange-400" : ""
            }`}
            onClick={() => handleSelectRatio(item.ratio)}
          />
        </div>
      ))}
    </div>
  );
}
