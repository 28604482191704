import React, { useRef } from "react";
import GHeader from "./layout/GHeader";
import Try from "./landing/Try";
import GFooter from "./layout/GFooter";

export default function SecurityPolicy() {
  const bannerRef = useRef();
  const pricingRef = useRef();

  return (
    <>
      <GHeader pricingRef={pricingRef} bannerRef={bannerRef} />
      <div className="max-w-4xl mx-auto px-4 py-12">
        <h1 className="text-3xl md:text-4xl font-bold mb-8">Security Policy</h1>
        <p className="text-gray-600 mb-8">EFFECTIVE DATE: November 25, 2024</p>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
          <p className="text-gray-700 mb-4">
            At HeadShotlyAI ("we," "us," or "our"), the security of your
            personal data is our top priority. We are committed to protecting
            your information and ensuring the confidentiality, integrity, and
            availability of our services. This Security Policy outlines the
            technical and organizational measures we implement to safeguard your
            data when you use our website www.headshotly.ai and related services
            (collectively, the "Service"). This policy is designed to comply
            with both the European General Data Protection Regulation (GDPR) and
            the California Consumer Privacy Act (CCPA)/California Privacy Rights
            Act (CPRA).
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">
            1. Compliance with Laws and Standards
          </h2>

          <h3 className="text-xl font-semibold mb-3">1.1. GDPR Compliance</h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              We comply with the General Data Protection Regulation (GDPR) and
              relevant Polish data protection laws.
            </li>
            <li>
              Data Protection Impact Assessments (DPIAs) are conducted where
              necessary.
            </li>
            <li>
              We have appointed a Data Protection Officer (DPO) who oversees
              compliance efforts.
            </li>
            <li>
              Personal data is processed lawfully, fairly, and transparently.
            </li>
            <li>
              We implement appropriate technical and organizational measures to
              ensure a level of security appropriate to the risk.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">
            1.2. CCPA/CPRA Compliance
          </h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              We comply with the California Consumer Privacy Act (CCPA) and the
              California Privacy Rights Act (CPRA).
            </li>
            <li>
              We implement and maintain reasonable security procedures and
              practices appropriate to the nature of the personal information.
            </li>
            <li>
              California residents are provided with specific rights regarding
              their personal information, including the right to know, delete,
              and correct their personal information.
            </li>
            <li>
              We do not sell or share personal information of California
              residents.
            </li>
            <li>
              We provide notice of our data collection practices and the
              purposes for which personal information is used.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">
            1.3. Industry Standards
          </h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              Our security practices align with industry standards such as ISO
              27001 and OWASP guidelines.
            </li>
            <li>
              We ensure that our service providers also comply with relevant
              security and privacy standards.
            </li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">
            2. Infrastructure Security
          </h2>

          <h3 className="text-xl font-semibold mb-3">
            2.1. Hosting Environment
          </h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              Our services are hosted on Amazon Web Services (AWS), utilizing
              their secure and scalable infrastructure.
            </li>
            <li>
              AWS data centers provide robust physical security measures,
              including biometric access controls and surveillance.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">
            2.2. Data Center Location
          </h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              All data is stored within AWS facilities located in the European
              Union (EU) to ensure data residency compliance.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">2.3. Network Security</h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              Firewalls and Virtual Private Clouds (VPCs) are configured to
              segment and protect our network.
            </li>
            <li>
              Intrusion Detection Systems (IDS) and Intrusion Prevention Systems
              (IPS) monitor network traffic for suspicious activities.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">2.4. Access Controls</h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              Strict access controls are enforced using the principle of least
              privilege.
            </li>
            <li>
              Multi-factor authentication (MFA) is required for administrative
              access to critical systems.
            </li>
            <li>
              Access to production environments is limited to authorized
              personnel only.
            </li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">3. Data Encryption</h2>

          <h3 className="text-xl font-semibold mb-3">
            3.1. Encryption in Transit
          </h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              All data transmitted between your device and our servers is
              encrypted using Transport Layer Security (TLS) 1.2 or higher.
            </li>
            <li>
              Secure protocols such as HTTPS are enforced across all user
              interactions.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">
            3.2. Encryption at Rest
          </h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              Data stored on our servers, including your uploaded images and
              personal data, is encrypted at rest using Advanced Encryption
              Standard (AES) 256-bit encryption.
            </li>
            <li>
              Encryption keys are managed securely using AWS Key Management
              Service (KMS).
            </li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">
            4. Application Security
          </h2>

          <h3 className="text-xl font-semibold mb-3">
            4.1. Secure Development Lifecycle
          </h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              We follow a Secure Software Development Lifecycle (SSDLC)
              incorporating security at every development stage.
            </li>
            <li>
              Developers receive regular training on secure coding practices and
              emerging threats.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">
            4.2. Code Reviews and Testing
          </h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              Regular code reviews are conducted to identify and remediate
              security vulnerabilities.
            </li>
            <li>
              Static Application Security Testing (SAST) and Dynamic Application
              Security Testing (DAST) tools are used.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">
            4.3. Penetration Testing
          </h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              Third-party penetration tests are conducted periodically to assess
              the security of our applications and infrastructure.
            </li>
            <li>Findings are prioritized and remediated promptly.</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">
            5. Data Access and Confidentiality
          </h2>

          <h3 className="text-xl font-semibold mb-3">5.1. Employee Access</h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              Employee access to personal data is limited and granted on a
              need-to-know basis.
            </li>
            <li>
              All employees and contractors are required to sign confidentiality
              agreements.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">5.2. Background Checks</h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              Pre-employment background checks are performed on all personnel
              with access to sensitive data.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">5.3. Access Monitoring</h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>All access to personal data is logged and monitored.</li>
            <li>
              Anomalies and unauthorized access attempts are investigated
              immediately.
            </li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">
            6. Data Backup and Recovery
          </h2>

          <h3 className="text-xl font-semibold mb-3">6.1. Regular Backups</h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              Critical data is backed up regularly using secure, encrypted
              storage solutions.
            </li>
            <li>
              Backups are stored in geographically separate locations within the
              EU to ensure data availability.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">
            6.2. Disaster Recovery Plan
          </h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              A comprehensive Disaster Recovery Plan (DRP) is in place to
              restore services in the event of a catastrophic incident.
            </li>
            <li>
              Regular drills and tests are conducted to validate the
              effectiveness of the DRP.
            </li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">
            7. Payment Processing Security
          </h2>

          <h3 className="text-xl font-semibold mb-3">
            7.1. Secure Payment Processing
          </h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              All payment transactions are processed securely through Stripe, a
              PCI DSS Level 1 certified payment processor.
            </li>
            <li>
              Payment data is transmitted directly to Stripe over encrypted
              channels; we do not store or process full payment card details on
              our servers.
            </li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">
            8. Incident Response and Notification
          </h2>

          <h3 className="text-xl font-semibold mb-3">
            8.1. Incident Response Plan
          </h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              A formal Incident Response Plan (IRP) is established to address
              security incidents promptly and effectively.
            </li>
            <li>
              The IRP outlines roles, responsibilities, and procedures for
              detection, containment, eradication, recovery, and communication.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">
            8.2. Monitoring and Detection
          </h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              Continuous security monitoring tools are deployed to detect and
              alert on potential security incidents.
            </li>
            <li>
              Security Information and Event Management (SIEM) systems aggregate
              logs and provide real-time analysis.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">
            8.3. Breach Notification
          </h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              <strong>GDPR Compliance:</strong> In the event of a personal data
              breach, we will notify the relevant supervisory authority without
              undue delay and, where feasible, within 72 hours of becoming aware
              of the breach, unless the breach is unlikely to result in a risk
              to the rights and freedoms of individuals. If the breach is likely
              to result in a high risk, we will also notify the affected
              individuals without undue delay.
            </li>
            <li>
              <strong>CCPA/CPRA Compliance:</strong> For California residents,
              in the event of a data breach involving unencrypted personal
              information as defined under California law, we will provide
              timely notification to affected individuals in accordance with
              CCPA/CPRA and applicable California data breach notification laws.
            </li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">
            9. Third-Party Service Providers
          </h2>

          <h3 className="text-xl font-semibold mb-3">9.1. Vendor Management</h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              We conduct due diligence on all third-party service providers to
              ensure they meet our security and privacy standards.
            </li>
            <li>
              Data Processing Agreements (DPAs) are in place with all vendors
              who process personal data on our behalf.
            </li>
            <li>
              We ensure that our service providers comply with GDPR and
              CCPA/CPRA requirements, including implementing appropriate
              security measures.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">
            9.2. Regular Assessments
          </h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              Ongoing assessments and audits are performed to verify the
              security posture of critical service providers.
            </li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">
            10. Customer Responsibilities
          </h2>

          <h3 className="text-xl font-semibold mb-3">10.1. Account Security</h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              You are responsible for maintaining the confidentiality of your
              account credentials.
            </li>
            <li>
              We recommend using strong, unique passwords and updating them
              regularly.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">
            10.2. Two-Factor Authentication
          </h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              Where available, we encourage you to enable two-factor
              authentication (2FA) for additional account security.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">
            10.3. Reporting Vulnerabilities
          </h3>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>
              If you discover any security vulnerabilities or incidents, please
              report them immediately to security@headshotly.ai.
            </li>
            <li>
              We appreciate your assistance in keeping our platform secure.
            </li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">
            11. Updates to This Security Policy
          </h2>
          <p className="text-gray-700 mb-4">
            We may update this Security Policy periodically to reflect changes
            in our practices or regulatory requirements. We will notify you of
            any significant changes by:
          </p>
          <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
            <li>Posting the updated policy on this page</li>
            <li>Updating the "Effective Date" at the top of this policy</li>
            <li>
              Sending an email notification if you have provided your email
              address
            </li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">12. Contact Us</h2>
          <p className="text-gray-700 mb-4">
            If you have any questions, concerns, or suggestions regarding our
            Security Policy or security practices, please contact us:
          </p>
          <p className="text-gray-700 mb-2">Email: security@headshotly.ai</p>
          <p className="text-gray-700 mb-4">
            Postal Address:
            <br />
            HeadShotlyAI
            <br />
            Wileńska 14
            <br />
            03-409 Warszawa, Poland
          </p>
          <p className="text-gray-700 mb-4">
            For California residents seeking information about their rights
            under CCPA/CPRA, please refer to our Privacy Policy or contact us at
            privacy@headshotly.ai.
          </p>
          <p className="text-gray-700 italic">
            Thank you for trusting HeadShotlyAI with your personal data. We are
            committed to maintaining the highest levels of security to protect
            your information.
          </p>
        </section>
      </div>
      <Try />
      <GFooter />
    </>
  );
}
