import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Paper,
  Typography,
  Modal,
  Avatar,
  Tabs,
  Tab,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { boosterPack } from "../data/pricing";
import useStripeCheckout from "../hooks/useStripeCheckout";
import Pricing from "./Pricing";
import { logOut, userSelector } from "../app/slices/user";
import { asyncGetVouchers, vouchersSelector } from "../app/slices/vouchers";
import ltdPaymentPlans from "../data/ltd-pricing";
import pricing from "../data/pricing";
import { stringAvatar } from "../utils/stringAvatar";
import { toast } from "react-toastify";
import Referral from "./Referral";
import VoucherTable from "./VoucherTable";
import ApplyVoucher from "./ApplyVoucher";
import { miscActions } from "../app/slices/mics";

const _pricing = pricing.map((item) => ({
  key: item.key,
  name: item.name,
}));

const _ltdPaymentPlans = ltdPaymentPlans.map((item) => ({
  key: item.priceId,
  name: item.name,
}));

const mergedPricing = [..._pricing, ..._ltdPaymentPlans];

const a11yProps = (index) => {
  return {
    id: `pricing-tab-${index}`,
    "aria-controls": `pricing-tabpanel-${index}`,
  };
};

const PricingTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`pricing-tabpanel-${index}`}
      aria-labelledby={`pricing-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
};

export default function AccountSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [deleteType, setDeleteType] = useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [accountDeletionLoading, setAccountDeletionLoading] = useState(false);
  const { data } = useSelector(userSelector);
  const { handleCheckout, disabled } = useStripeCheckout();
  const [userEmail, setUserEmail] = useState("");
  const [userLoading, setUserLoading] = useState(false);
  const [selfMode, setSelfMode] = useState(true);
  const [pricingTab, setPricingTab] = useState(location.state?.defaultTab || 0);
  const {
    isFetched: voucherIsFetched,
    loading: voucherLoading,
    data: vouchers,
  } = useSelector(vouchersSelector);

  const handleChange = (event, newValue) => {
    setPricingTab(newValue);
  };

  const handleLogout = () => {
    dispatch(logOut());
    navigate("/");
  };

  const handleDeleteAccount = async () => {
    try {
      if (!deleteType) return;
      const token = localStorage.getItem("token");
      setAccountDeletionLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/delete`,
        { type: deleteType },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAccountDeletionLoading(false);
      if (response.status === 200) {
        handleLogout();
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setAccountDeletionLoading(false);
    }
  };

  const handleGetUserData = async () => {
    try {
      setUserLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/get-user-info`,
        { email: userEmail },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        localStorage.setItem("token", response.data.userInfo.info);
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          window.location.reload();
        }, 1000);
      } else {
        toast.error("User not found");
      }
    } catch (error) {
      toast.error("User not found");
    } finally {
      setUserLoading(false);
    }
  };

  useEffect(() => {
    const userToken = localStorage?.getItem("token");
    if (!voucherIsFetched && !voucherLoading) {
      dispatch(asyncGetVouchers({ userToken }));
    }
  }, [dispatch, voucherIsFetched, voucherLoading]);

  return (
    <div className="max-w-screen-xl mx-auto p-4">
      <div className="w-full text-center mt-16 mb-8">
        <h1 className="font-semibold text-4xl mb-4">
          <span className="text-orange-500">My</span>{" "}
          <span className="text-amber-400">Account</span>
        </h1>
      </div>

      {/* Profile Info Card - Enhanced */}
      <div className="border-gray-300 border rounded-3xl p-4 sm:p-8 mb-8 bg-gradient-to-br from-orange-50 to-amber-50">
        <div className="flex flex-col sm:flex-row flex-wrap items-center sm:items-start gap-6">
          <Avatar
            {...stringAvatar(data.name)}
            sx={{
              width: { xs: 60, sm: 80 },
              height: { xs: 60, sm: 80 },
              bgcolor: "rgb(249 115 22)",
            }}
          />
          <div className="flex-grow text-center sm:text-left">
            <h2 className="font-semibold text-2xl mb-3">{data.name}</h2>
            <div className="space-y-2 text-gray-700">
              <p>
                <span className="font-medium">Email:</span> {data.email}
              </p>
              <p>
                <span className="font-medium">Current Plan:</span>{" "}
                <span className="text-orange-600">
                  {mergedPricing.find(
                    (item) => item.key === data.subscriptionPlan
                  )?.name ?? data.subscriptionPlan}
                </span>
              </p>
              <p>
                <span className="font-medium">Plan Status:</span>{" "}
                <span
                  className={`font-medium ${
                    data.subscriptionStatus === "active"
                      ? "text-green-600"
                      : "text-red-600"
                  }`}
                >
                  {data.subscriptionStatus}
                </span>
              </p>
            </div>

            <div className="mt-6 flex flex-wrap justify-center sm:justify-start gap-4">
              <div className="bg-orange-100 text-orange-800 px-4 sm:px-8 py-4 rounded-2xl shadow-sm w-full sm:w-auto">
                <p className="text-3xl font-bold text-center">
                  {data.credits?.imageGeneration || 0}
                </p>
                <p className="text-sm font-medium text-center">
                  Available AI Photo Credits
                </p>
              </div>
              <div className="bg-amber-100 text-amber-800 px-4 sm:px-8 py-4 rounded-2xl shadow-sm w-full sm:w-auto">
                <p className="text-3xl font-bold text-center">
                  {data.credits?.modelTraining || 0}
                </p>
                <p className="text-sm font-medium text-center">
                  Available Training Credits
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="border-gray-300 border rounded-3xl p-4 sm:p-8 mb-8">
        <h2 className="font-semibold text-2xl mb-6">Purchase Credits</h2>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            "& .MuiTabs-indicator": {
              backgroundColor: "rgb(249 115 22)",
            },
            "& .MuiTabs-flexContainer": {
              flexWrap: "wrap",
            },
          }}
        >
          <Tabs
            value={pricingTab}
            onChange={handleChange}
            aria-label="credit purchase options"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              "& .Mui-selected": {
                color: "rgb(249 115 22) !important",
              },
            }}
          >
            <Tab
              label="Quick Booster Packs"
              value={0}
              {...a11yProps(0)}
              sx={{
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: 500,
              }}
            />
            <Tab
              label="Regular Subscription Plans"
              value={1}
              {...a11yProps(1)}
              sx={{
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: 500,
              }}
            />
            {data?.referral && (
              <Tab
                label="Earn credits by referring friends"
                value={2}
                {...a11yProps(2)}
                sx={{
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              />
            )}
            {Array.isArray(vouchers) && vouchers.length > 0 && (
              <Tab
                label="My Vouchers"
                value={3}
                {...a11yProps(3)}
                sx={{
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              />
            )}
          </Tabs>
        </Box>
        <PricingTabPanel value={pricingTab} index={0}>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
            {boosterPack.map((item) => (
              <LoadingButton
                key={item.plan}
                disabled={disabled}
                variant="contained"
                onClick={() => handleCheckout({ priceId: item.plan })}
                fullWidth
                style={{ color: "white" }}
                sx={{
                  bgcolor: "rgb(249 115 22)",
                  borderRadius: "24px",
                  py: 2,
                  fontSize: "1rem",
                  boxShadow: "0 4px 6px -1px rgb(249 115 22 / 0.2)",
                  "&:hover": {
                    bgcolor: "rgb(234 88 12)",
                  },
                }}
              >
                {item.name}
              </LoadingButton>
            ))}
          </div>
        </PricingTabPanel>
        <PricingTabPanel value={pricingTab} index={1}>
          <div className="w-full flex flex-col justify-center items-center mb-4">
            <div className="text-sm text-gray-500 mb-2">
              Buy for <span className={selfMode && "font-bold"}>yourself</span>{" "}
              or as a{" "}
              <span className={!selfMode && "font-bold"}>gift voucher</span>
            </div>
            <div className="flex rounded-md bg-orange-50 text-orange-800 font-bold text-sm overflow-hidden select-none">
              <button
                onClick={() => setSelfMode(true)}
                className={`px-4 py-2 m-1 mr-0 rounded-tl-md rounded-bl-md ${
                  selfMode ? "bg-orange-200" : "bg-orange-100"
                }`}
              >
                Self
              </button>
              <button
                onClick={() => setSelfMode(false)}
                className={`px-4 py-2 m-1 ml-0 rounded-tr-md rounded-br-md ${
                  !selfMode ? "bg-orange-200" : "bg-orange-100"
                }`}
              >
                Gift
              </button>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-6 w-full">
            <Pricing
              showCoupon={false}
              affiliateEnabled={false}
              buyForSelf={selfMode}
              customStyles={{
                container: {
                  display: "flex",
                  flexDirection: "column",
                  lg: {
                    flexDirection: "row",
                  },
                  gap: "1.5rem",
                  width: "100%",
                },
                card: {
                  flex: 1,
                  border: "1px solid rgb(229 231 235)",
                  borderRadius: "1.5rem",
                  padding: "1.5rem 2rem",
                  transition: "all 0.3s",
                  "&:hover": {
                    boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1)",
                  },
                },
                button: {
                  bgcolor: "rgb(249 115 22)",
                  borderRadius: "0.75rem",
                  py: 1.5,
                  width: "100%",
                  fontWeight: 500,
                  "&:hover": {
                    bgcolor: "rgb(234 88 12)",
                  },
                },
              }}
            />
          </div>
        </PricingTabPanel>
        <PricingTabPanel value={pricingTab} index={2}>
          <Referral referralUrl={data?.referral} />
        </PricingTabPanel>
        <PricingTabPanel value={pricingTab} index={3}>
          <VoucherTable />
        </PricingTabPanel>
      </div>

      {data.isAdmin === true && (
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Admin Control:
          </Typography>
          <div className="flex flex-col gap-y-2">
            <TextField
              label="Enter email"
              variant="outlined"
              placeholder="Enter email"
              onChange={(e) => setUserEmail(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              disabled={userLoading}
              loading={userLoading}
              sx={{ mt: 2 }}
              onClick={() => handleGetUserData()}
            >
              Submit
            </Button>
          </div>
        </Paper>
      )}

      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Typography variant="h6">Voucher Apply:</Typography>
        <ApplyVoucher />
        <div className="text-sm text-gray-500 mt-4 flex items-center justify-center gap-x-2">
          <span>I have gift card, apply it here</span>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            onClick={() =>
              dispatch(miscActions.setModal({ name: "gift", value: true }))
            }
          >
            Apply
          </Button>
        </div>
      </Paper>

      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Typography variant="h6">Account Control:</Typography>
        <Button
          disabled={accountDeletionLoading}
          variant="contained"
          color="secondary"
          style={{ color: "white" }}
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => handleLogout()}
        >
          Logout
        </Button>
        <Button
          disabled={accountDeletionLoading}
          variant="contained"
          color="warning"
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => {
            setIsConfirmModalOpen(true);
            setDeleteType("data");
          }}
        >
          Delete Account Data
        </Button>
        <Button
          disabled={accountDeletionLoading}
          variant="contained"
          color="error"
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => {
            setIsConfirmModalOpen(true);
            setDeleteType("full");
          }}
        >
          Delete Full Account
        </Button>
      </Paper>
      <Modal
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        aria-labelledby="confirm-modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "90%",
              sm: 300,
            },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" id="confirm-modal-title">
            Confirm Deletion
          </Typography>
          <Typography>
            Are you sure you want to delete your{" "}
            {deleteType === "data" ? "account data" : "full account"}?
          </Typography>
          <div className="flex flex-col gap-y-2">
            <Button
              variant="contained"
              color="error"
              fullWidth
              onClick={() => {
                handleDeleteAccount();
                setIsConfirmModalOpen(false);
              }}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                setDeleteType("");
                setIsConfirmModalOpen(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
