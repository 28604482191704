import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "../../app/slices/user";
// import Logo from "../../assets/logo.png";

export default function GuestHeader({ pricingRef, bannerRef }) {
  const [height, setHeight] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isAuthenticated, isFetched } = useSelector(userSelector);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setHeight(entry.target.clientHeight);
      }
    });

    if (bannerRef.current) {
      resizeObserver.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef?.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(bannerRef?.current);
      }
    };
  }, [bannerRef]);

  if (isAuthenticated && isFetched) {
    return null;
  }

  return (
    <div style={{ paddingTop: `${height}px` }}>
      <nav className="bg-white">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link
            to="/"
            className="flex items-center space-x-1 rtl:space-x-reverse"
          >
            {/* <img src={Logo} className="h-6" alt="" /> */}
            <span className="self-center text-2xl font-semibold whitespace-nowrap">
              HeadShotlyAI
            </span>
          </Link>
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-700 rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-100"
            aria-controls="navbar-dropdown"
            aria-expanded={isMenuOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className={`${
              isMenuOpen ? "block" : "hidden"
            } w-full md:block md:w-auto`}
            id="navbar-dropdown"
          >
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white">
              <li>
                <Link
                  to="/christmas"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-orange-500 md:p-0"
                >
                  Christmas
                </Link>
              </li>
              <li>
                <Link
                  to="/lifetime-deal"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-orange-500 md:p-0 relative"
                >
                  Lifetime Deal
                  <span className="absolute -top-3 -right-12 bg-red-500 text-white text-xs px-2 py-0.5 rounded-full animate-pulse hidden md:block">
                    Limited Time
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/about-us"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-orange-500 md:p-0"
                >
                  About Us
                </Link>
              </li>
              <li>
                <button
                  onClick={() =>
                    pricingRef?.current?.scrollIntoView({ behavior: "smooth" })
                  }
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-orange-500 md:p-0"
                >
                  Pricing
                </button>
              </li>
              <li>
                <Link
                  to="/affiliate"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-orange-500 md:p-0"
                >
                  Affiliate Program
                </Link>
              </li>
              <li className="mt-3 md:mt-0">
                <Link
                  to="/auth"
                  className="bg-orange-500 font-normal text-base text-white rounded-3xl px-6 py-3 hover:bg-orange-600"
                >
                  Sign Up
                </Link>
              </li>
              <li>
                <Link
                  to="/auth"
                  className="block py-2 px-3 text-orange-500 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-orange-500 md:p-0"
                >
                  Login
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
