import React, { useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Auth from "./components/Auth";
import AuthCallback from "./components/AuthCallback";
import PaymentSuccess from "./components/PaymentSuccess";
import Navbar from "./components/Navbar";
import Homepage from "./components/Homepage";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import ModelTraining from "./components/ModelTraining";
import ImageGeneration from "./components/ImageGeneration";
import Receiver from "./components/Receiver";
import Modal from "./components/Modal";
import {
  asyncGetUser,
  asyncUpdateUser,
  userSelector,
  getIsOnboarded,
  asyncGetReferral,
} from "./app/slices/user";
import { miscActions } from "./app/slices/mics";
import { asyncGetUserModels } from "./app/slices/model";
import { asyncGetHistories } from "./app/slices/history";
import { useDispatch, useSelector } from "react-redux";
import Affiliate from "./components/Affiliate";
import RefundPolicy from "./components/RefundPolicy";
import Head from "./components/Head";
import "react-toastify/dist/ReactToastify.css";
import StyleSelection from "./components/onboarding/StyleSelection";
import OnboardingFlow from "./components/onboarding/index";
import useAppLoading from "./hooks/useAppLoading";
import SecurityPolicy from "./components/SecurityPolicy";
import AboutUs from "./components/AboutUs";
import Account from "./components/Account";
import LTDPricing from "./components/landing/LTDPricing";
import Christmas from "./components/Christmas";
import SampleGallery from "./components/Sample-Gallery";
import CookieBanner from "./components/CookieBanner";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f97316",
    },
    secondary: {
      main: "#3F51B5",
    },
  },
});

const App = () => {
  const dispatch = useDispatch();
  const { onboarded } = useSelector(getIsOnboarded);
  const { isAuthenticated, isFetched, data } = useSelector(userSelector);
  const token = localStorage?.getItem("token");
  const appLoading = useAppLoading(token, isFetched, 200);
  const isActiveUser = data?.subscriptionStatus === "active";
  const _isAuth = isAuthenticated && isFetched;

  useEffect(() => {
    if (!isAuthenticated && token) {
      dispatch(asyncGetUser({ userToken: token }));
    }
  }, [dispatch, isAuthenticated, token]);

  useEffect(() => {
    if (_isAuth) {
      dispatch(asyncGetUserModels());
      dispatch(asyncGetHistories({ userToken: token }));
      isActiveUser && dispatch(asyncGetReferral({ userToken: token }));
    }
  }, [dispatch, token, isActiveUser, _isAuth]);

  useEffect(() => {
    if (!localStorage) return;
    if (!onboarded && _isAuth) {
      const referral = localStorage.getItem("hsref");
      if (referral) {
        dispatch(asyncUpdateUser({ body: { referral } }));
      }
    }
  }, [_isAuth, dispatch, onboarded]);

  useEffect(() => {
    if (_isAuth) {
      const giftCard = localStorage?.getItem("giftCard");
      if (giftCard) {
        dispatch(miscActions.setModal({ name: "gift", value: true }));
      }
    }
  }, [_isAuth, dispatch]);

  if (appLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div
          className="animate-spin w-8 h-8 border-4 border-t-transparent border-text-blue-500 rounded-full"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Head />
        {isAuthenticated && <Navbar />}
        <Routes>
          {isAuthenticated ? (
            <>
              {onboarded ? (
                <>
                  <Route path="/" element={<ModelTraining />} />
                  <Route
                    path="/image-generation"
                    element={<ImageGeneration />}
                  />
                  <Route path="/style-selection" element={<StyleSelection />} />
                  <Route path="/account" element={<Account />} />
                </>
              ) : (
                <Route path="/" element={<OnboardingFlow />} />
              )}
            </>
          ) : (
            <>
              {/* <Route path="/" element={<LandingPage />} /> */}
              <Route path="/" element={<Homepage />} />
              <Route path="/auth" element={<Auth />} />
              <Route path="/auth/callback" element={<AuthCallback />} />
              <Route path="/payment-success" element={<PaymentSuccess />} />
            </>
          )}
          {/* Update these two routes */}
          <Route path="/lifetime-deal" element={<LTDPricing />} />
          <Route path="/christmas" element={<Christmas />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/security-policy" element={<SecurityPolicy />} />
          <Route path="/affiliate" element={<Affiliate />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/gallery" element={<SampleGallery />} />
          {isFetched ? (
            <Route
              path="*"
              element={<Navigate to={isAuthenticated ? "/" : "/auth"} />}
            />
          ) : null}
        </Routes>
        {isAuthenticated ? <Footer /> : null}
        <Receiver />
        <Modal />
        <CookieBanner />
      </Router>
      <ToastContainer position="top-right" autoClose={5000} theme="light" />
    </ThemeProvider>
  );
};

export default App;
