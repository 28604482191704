import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getIsOnboarded, logOut } from "../app/slices/user";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { onboarded } = useSelector(getIsOnboarded);
  const { isAuthenticated } = useSelector((state) => state.user);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleLogout = () => {
    dispatch(logOut());
    navigate("/");
  };

  const handleProfileClick = () => {
    navigate("/account");
  };

  const handleLifetimeDealClick = () => {
    navigate("/lifetime-deal");
  };

  const handleChristmasClick = () => {
    navigate("/christmas");
  };

  const menuItems = onboarded ? [] : [{ label: "Home", path: "/" }];

  const renderMenuItems = (isDrawer = false) =>
    menuItems.map(({ label, path, onClick }) => (
      <Button
        key={label}
        component={path ? Link : undefined}
        to={path || undefined}
        onClick={onClick}
        sx={{
          textTransform: "none",
          color: "text.primary",
          width: isDrawer ? "100%" : "auto",
          display: isDrawer ? "block" : "inline-block",
          padding: isDrawer ? "10px 0" : "auto",
          "&:hover": {
            color: "#f97316", // orange-500
          },
        }}
      >
        {label}
      </Button>
    ));

  return (
    <AppBar position="static" sx={{ backgroundColor: "white", boxShadow: 1 }}>
      <Toolbar>
        {/* Logo */}
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{ flexGrow: 1, color: "black", textDecoration: "none" }}
        >
          <div className="flex items-center gap-x-1">
            {/* <img src={Logo} className="h-6" alt="Logo" /> */}
            HeadShotlyAI
          </div>
        </Typography>

        {isMobile ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setIsDrawerOpen(true)}
              sx={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
            >
              <Box
                sx={{ width: 250, padding: "16px" }}
                onClick={() => setIsDrawerOpen(false)}
              >
                {renderMenuItems(true)}
                {isAuthenticated && (
                  <>
                    <Button
                      onClick={handleLifetimeDealClick}
                      sx={{
                        textTransform: "none",
                        color: "text.primary",
                        width: "100%",
                      }}
                    >
                      Lifetime deal
                    </Button>
                    <Button
                      onClick={handleChristmasClick}
                      sx={{
                        textTransform: "none",
                        color: "text.primary",
                        width: "100%",
                      }}
                    >
                      Christmas
                    </Button>
                    <Button
                      onClick={handleProfileClick}
                      sx={{
                        textTransform: "none",
                        color: "text.primary",
                        width: "100%",
                      }}
                    >
                      Profile
                    </Button>
                    <Button
                      onClick={handleLogout}
                      sx={{
                        textTransform: "none",
                        color: "text.primary",
                        width: "100%",
                      }}
                    >
                      Logout
                    </Button>
                  </>
                )}
              </Box>
            </Drawer>
          </>
        ) : (
          <>
            {renderMenuItems()}
            {isAuthenticated && (
              <>
                {" "}
                <Button
                  onClick={handleLifetimeDealClick}
                  sx={{ textTransform: "none", color: "text.primary" }}
                >
                  Lifetime deal
                </Button>
                <Button
                  onClick={handleChristmasClick}
                  sx={{ textTransform: "none", color: "text.primary" }}
                >
                  Christmas
                </Button>
                <Button
                  onClick={handleProfileClick}
                  sx={{ textTransform: "none", color: "text.primary" }}
                >
                  Profile
                </Button>
                <Button
                  onClick={handleLogout}
                  sx={{ textTransform: "none", color: "text.primary" }}
                >
                  Logout
                </Button>
              </>
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
