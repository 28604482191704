// ImageGeneration.js
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ImageIcon from "@mui/icons-material/Image";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  Modal,
  IconButton,
  CircularProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/system";
import axios from "axios";
import { saveAs } from "file-saver";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import JSZip from "jszip";
import { modelSelectors } from "../app/slices/model";
import { setRemainingToken } from "../app/slices/user";
import { getHistory } from "../app/slices/history";
// import UpgradePrompt from "./UpgradePrompt";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ImageRatio from "./ImageRatio";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import InputAdornment from "@mui/material/InputAdornment";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Referral from "./Referral";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.03)",
    boxShadow: theme.shadows[4],
  },
}));

const LargeCardMedia = styled(CardMedia)({
  paddingTop: "100%",
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "100%",
  display: "flex",
  flexDirection: "column",
  marginBottom: "10px",
  borderRadius: theme.shape.borderRadius * 2,
}));

const ImageGeneration = () => {
  const dispatch = useDispatch();
  const { state: locationState } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const _modelSelectors = useSelector(modelSelectors);
  const models = _modelSelectors.data.models;
  const [selectedModel, setSelectedModel] = useState(
    locationState?.selectedModel || models?.[0]?.name || ""
  );
  const [error, setError] = useState("");
  const [generationPrompt, setGenerationPrompt] = useState("");
  const [outfitPrompt, setOutfitPrompt] = useState("");
  const [generatedImages, setGeneratedImages] = useState([]);
  const [generating, setGenerating] = useState(false);
  const [similarGenerating, setSimilarGenerating] = useState(false);
  const [scale] = useState(1);
  const [seed, setSeed] = useState("");
  const [numImages, setNumImages] = useState(1);
  const [activeTab, setActiveTab] = useState("pre");
  const { data } = useSelector((state) => state.user);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { histories } = useSelector(getHistory(selectedModel));
  const [selectedRatio, setSelectedRatio] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false);

  const [_generatedImg, _setGeneratedImg] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const navigate = useNavigate();

  const handleBuyCredits = () => {
    navigate("/account", { state: { defaultTab: 0 } });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!selectedModel) {
        navigate("/");
      }
    }, 800);
    return () => clearTimeout(timeout);
  }, [navigate, selectedModel]);

  useEffect(() => {
    const model = searchParams.get("model");
    if (model) {
      const _model = models.find((item) => item?.name === model);
      if (_model) {
        setSelectedModel(model);
      }
      setSearchParams({});
    }
  }, [models, searchParams, setSearchParams]);

  useEffect(() => {
    if (activeTab === "pre" && Array.isArray(models)) {
      const model = models.find((model) => model?.name === selectedModel);
      if (model && Array.isArray(model?.preGenerated)) {
        const _preGenerated = model.preGenerated.map((preImg) => ({
          url: preImg?.url,
          id: preImg?._id,
        }));
        _setGeneratedImg([..._preGenerated]);
      } else {
        _setGeneratedImg([]);
      }
    } else if (activeTab === "new" && Array.isArray(generatedImages)) {
      const _generated = generatedImages.map((img) => ({
        url: img?.url,
      }));
      _setGeneratedImg([..._generated]);
    } else if (activeTab === "history" && Array.isArray(histories)) {
      const _histories = histories.map((img) => ({
        url: img?.imgUrl,
      }));
      _setGeneratedImg([..._histories]);
    } else {
      _setGeneratedImg([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, models, selectedModel, generatedImages]);

  const generateImage = async () => {
    const token = localStorage.getItem("token");

    if (!selectedModel) {
      setError("No valid trained model selected. Please select a model first.");
      return;
    }

    if (numImages > 4) {
      setError("You cannot generate more than 4 images at once.");
      return;
    }

    setGenerating(true);
    setError("");

    try {
      const selectedModelData = models.find(
        (model) => model.name === selectedModel
      );
      if (
        !selectedModelData ||
        (!selectedModelData.s3Url && !selectedModelData.falUrl)
      ) {
        throw new Error("Selected model does not have a valid URL");
      }
      const requestData = {
        prompt: generationPrompt,
        outfit_prompt: outfitPrompt,
        ratio: selectedRatio,
        modelName: selectedModel,
        scale: scale,
        seed: seed ? parseInt(seed) : undefined,
        num_images: numImages,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/engine/generate-image`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (
        response.data.generatedImages &&
        response.data.generatedImages.length > 0
      ) {
        dispatch(
          setRemainingToken({
            numberOfToken: numImages,
            creaditKey: "credits.imageGeneration",
          })
        );
        setGeneratedImages(response.data.generatedImages);
      } else {
        throw new Error("No image data in the response");
      }

      if (response.data.remainingCredits !== undefined) {
        // setCredits(response.data.remainingCredits);
      }
    } catch (error) {
      setError(
        error.response?.data?.message ||
          "Error generating image. Please try again."
      );
    } finally {
      setGenerating(false);
    }
  };

  const handleDownload = (imageUrl = "") => {
    const fileName = imageUrl.split("/").pop();
    saveAs(imageUrl, fileName);
  };

  const handleTabChange = (_, tabId) => {
    setActiveTab(tabId);
  };

  const handlePreviewOpen = (image, index) => {
    setSelectedImage(image);
    setCurrentImageIndex(index);
    setPreviewOpen(true);
  };

  const handlePreviewClose = useCallback(() => {
    setPreviewOpen(false);
    setSelectedImage(null);
  }, []);

  const handleTryFromFree = async (id) => {
    try {
      if (id) {
        const token = localStorage.getItem("token");
        setSimilarGenerating(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/engine/try-from-pre`,
          { promptId: id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSimilarGenerating(false);
        const generatedImages = response.data.generatedImages;
        if (generatedImages && generatedImages.length > 0) {
          dispatch(
            setRemainingToken({
              numberOfToken: 1,
              creaditKey: "credits.imageGeneration",
            })
          );
          const urls = generatedImages.map((img) => ({ url: img.url }));
          _setGeneratedImg([..._generatedImg, ...urls]);
        }
      }
    } catch (error) {}
  };

  const handleDownloadAll = async () => {
    if (downloadLoading) return;
    const urls = _generatedImg.map((img) => img.url);
    const token = localStorage.getItem("token");
    const BATCH_SIZE = 2;

    try {
      setDownloadLoading(true);
      const zip = new JSZip();

      for (let i = 0; i < urls.length; i += BATCH_SIZE) {
        const batch = urls.slice(i, i + BATCH_SIZE);
        const batchPromises = batch.map(async (url, batchIndex) => {
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/tool/download-image`,
              { imageUrl: url },
              {
                responseType: "blob",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            const blob = response.data;
            const fileName = `image_${i + batchIndex + 1}.png`;
            zip.file(fileName, blob);
          } catch (err) {
            console.error(
              `Failed to download image ${i + batchIndex + 1}:`,
              err
            );
          }
        });

        await Promise.all(batchPromises);
      }

      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, `headshot_images_${Date.now()}.zip`);
    } catch (error) {
      setError("Failed to download images. Please try again.");
    } finally {
      setDownloadLoading(false);
    }
  };

  const handleNextImage = useCallback(() => {
    if (currentImageIndex < _generatedImg.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
      setSelectedImage(_generatedImg[currentImageIndex + 1].url);
    }
  }, [currentImageIndex, _generatedImg]);

  const handlePrevImage = useCallback(() => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
      setSelectedImage(_generatedImg[currentImageIndex - 1].url);
    }
  }, [currentImageIndex, _generatedImg]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!previewOpen) return;

      if (e.key === "ArrowRight") {
        handleNextImage();
      } else if (e.key === "ArrowLeft") {
        handlePrevImage();
      } else if (e.key === "Escape") {
        handlePreviewClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [previewOpen, handleNextImage, handlePrevImage, handlePreviewClose]);

  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 4, mt: 4 }}>
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{ fontWeight: "bold", color: "rgb(249 115 22)" }}
        >
          Create Professional AI Headshots
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary">
          Transform your professional presence with AI-powered executive
          portraits
        </Typography>
      </Box>

      <Paper
        elevation={3}
        sx={{
          p: 4,
          mb: 4,
          backgroundColor: "rgb(255 247 237)",
          border: "1px solid rgb(255 237 213)",
        }}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid
            item
            xs={12}
            sm={8}
            sx={{
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: "rgb(249 115 22)",
                fontSize: { xs: "1.2rem", sm: "1.5rem" },
              }}
            >
              Your Remaining Professional AI Photo Credits:{" "}
              <span style={{ fontWeight: "bold" }}>
                {data.credits?.imageGeneration ?? 0}
              </span>
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            container
            justifyContent={{ xs: "center", sm: "flex-end" }}
          >
            <Button
              variant="contained"
              onClick={handleBuyCredits}
              startIcon={<CloudDownloadIcon />}
              sx={{
                color: "white",
                bgcolor: "rgb(249 115 22)",
                "&:hover": {
                  bgcolor: "rgb(234 88 12)",
                },
                fontSize: { xs: "0.8rem", sm: "1rem" },
                padding: { xs: "6px 12px", sm: "8px 16px" },
              }}
            >
              Buy More Credits
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <StyledPaper elevation={3}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            mb: 3,
            "& .MuiTabs-flexContainer": {
              flexWrap: { xs: "wrap", sm: "nowrap" },
              gap: { xs: 1, sm: 0 },
            },
            "& .MuiTab-root": {
              minWidth: {
                xs: "calc(50% - 8px)",
                sm: "auto",
              },
              margin: { xs: "4px", sm: 0 },
              fontSize: { xs: "0.75rem", sm: "0.875rem" },
              padding: { xs: 1, sm: 2 },
            },
            "& .MuiTabs-indicator": {
              display: { xs: "none", sm: "block" }, // Hide indicator only on mobile
            },
          }}
        >
          <Tab label="Ready-to-Use Professional Headshots" value="pre" />
          <Tab label="Create Custom Headshots" value="new" />
          <Tab label="Your Generated Photos" value="history" />
          {data?.referral && (
            <Tab label="Earn credits by referring friends" value="refer" />
          )}
        </Tabs>

        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} md={6}>
            <Box>
              {activeTab !== "refer" ? (
                <>
                  <Typography variant="h5" gutterBottom color="primary">
                    Settings
                  </Typography>
                  <FormControl fullWidth sx={{ mb: 3 }}>
                    <InputLabel id="model-select-label">
                      Select Your AI Model
                    </InputLabel>
                    <Select
                      labelId="model-select-label"
                      value={selectedModel}
                      label="Select Your AI Model"
                      onChange={(e) => setSelectedModel(e.target.value)}
                    >
                      {models.map((model) => (
                        <MenuItem key={model.name} value={model.name}>
                          {model.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              ) : (
                <>
                  <Referral referralUrl={data?.referral} />
                </>
              )}

              {activeTab === "new" ? (
                <>
                  <TextField
                    fullWidth
                    label="What kind of professional photo do you want?"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={generationPrompt}
                    onChange={(e) => setGenerationPrompt(e.target.value)}
                    sx={{ mb: 3 }}
                    placeholder="Example: A confident business headshot with a natural smile, looking directly at camera..."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Describe your ideal professional photo. Include details about expression, pose, and background">
                            <InfoIcon sx={{ color: "rgb(249 115 22)" }} />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    label="How would you like to be dressed?"
                    variant="outlined"
                    multiline
                    rows={2}
                    value={outfitPrompt}
                    onChange={(e) => setOutfitPrompt(e.target.value)}
                    sx={{ mb: 3 }}
                    placeholder="Example: Navy blue business suit with a light blue shirt..."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Describe your preferred outfit and style">
                            <InfoIcon sx={{ color: "rgb(249 115 22)" }} />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Grid container spacing={2} sx={{ mb: 3 }}>
                    <Grid item xs={12} sm={6}>
                      <Tooltip title="The same seed and prompt will generate identical images. Use this to recreate specific results">
                        <TextField
                          fullWidth
                          label="Seed (Optional)"
                          variant="outlined"
                          value={seed}
                          onChange={(e) => setSeed(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <InfoIcon
                                  sx={{ color: "rgb(249 115 22)" }}
                                  fontSize="small"
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sx={{ mb: 3 }}>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Number of Variations
                      <Tooltip title="Generate up to 4 variations at once">
                        <InfoIcon
                          sx={{ color: "rgb(249 115 22)" }}
                          fontSize="small"
                        />
                      </Tooltip>
                    </Typography>
                    <ToggleButtonGroup
                      value={numImages}
                      exclusive
                      onChange={(e, newValue) => {
                        if (newValue !== null) {
                          setNumImages(newValue);
                        }
                      }}
                      size="small"
                      sx={{ width: "100%" }}
                    >
                      {[1, 2, 3, 4].map((number) => (
                        <ToggleButton
                          key={number}
                          value={number}
                          sx={{
                            flex: 1,
                            "&.Mui-selected": {
                              backgroundColor: "rgb(249 115 22) !important",
                              color: "white !important",
                              "&:hover": {
                                backgroundColor: "rgb(234 88 12) !important",
                              },
                            },
                          }}
                        >
                          {number}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Grid>

                  <Grid container item xs={12} sx={{ mb: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      Photo Size (Optional)
                      <Tooltip title="Choose the dimensions for your photos">
                        <InfoIcon
                          sx={{ color: "rgb(249 115 22)" }}
                          fontSize="small"
                        />
                      </Tooltip>
                    </Typography>
                    <Box
                      sx={{
                        "& .MuiToggleButton-root": {
                          py: 0.5,
                          px: 1,
                          minWidth: "60px",
                          fontSize: "0.875rem",
                        },
                      }}
                    >
                      <ImageRatio setSelectedRatio={setSelectedRatio} />
                    </Box>
                  </Grid>

                  <Button
                    variant="contained"
                    onClick={generateImage}
                    style={{ color: "white" }}
                    disabled={
                      generating ||
                      !selectedModel ||
                      activeTab === "pre" ||
                      !generationPrompt
                    }
                    fullWidth
                    startIcon={<ImageIcon />}
                    size="large"
                    sx={{
                      bgcolor: "rgb(249 115 22)",
                      "&:hover": {
                        bgcolor: "rgb(234 88 12)",
                      },
                      "&.Mui-disabled": {
                        bgcolor: "rgb(254 215 170)",
                      },
                    }}
                  >
                    {generating
                      ? "Creating Your Professional Headshots..."
                      : "Generate My Professional Headshots"}
                  </Button>
                </>
              ) : null}
            </Box>
          </Grid>
        </Grid>

        <Box marginTop={8}>
          <Typography
            variant="subtitle1"
            gutterBottom
            align="center"
            color="primary"
          >
            {Array.isArray(_generatedImg) && _generatedImg.length ? (
              <>
                {activeTab === "pre" ? "Premium Professional Styles For" : ""}{" "}
                Executive Headshots
                <br />
                <LoadingButton
                  loading={downloadLoading}
                  disabled={downloadLoading}
                  onClick={() => handleDownloadAll()}
                  variant="contained"
                  color="primary"
                  startIcon={<CloudDownloadIcon />}
                  style={{
                    marginTop: "10px",
                    marginBottom: "8px",
                    color: "white",
                  }}
                >
                  Download All
                </LoadingButton>
              </>
            ) : null}
          </Typography>

          {generating ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="200px"
            >
              <CircularProgress sx={{ color: "rgb(249 115 22)" }} />
            </Box>
          ) : (
            <Grid container spacing={2}>
              {_generatedImg.map((image, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <StyledCard>
                    <LargeCardMedia
                      image={image.url}
                      title={`Pre-generated ${index + 1}`}
                    />
                    <CardActions>
                      <Button
                        size="small"
                        sx={{
                          color: "rgb(249 115 22)",
                          "&:hover": {
                            bgcolor: "rgb(255 247 237)",
                          },
                        }}
                        fullWidth
                        startIcon={<CloudDownloadIcon />}
                        onClick={() => handleDownload(image.url)}
                      >
                        Download
                      </Button>
                      <Button
                        size="small"
                        sx={{
                          color: "rgb(249 115 22)",
                          "&:hover": {
                            bgcolor: "rgb(255 247 237)",
                          },
                        }}
                        fullWidth
                        startIcon={<VisibilityIcon />}
                        onClick={() => handlePreviewOpen(image.url, index)}
                      >
                        Preview
                      </Button>
                    </CardActions>
                    {image?.id && (
                      <CardActions>
                        <Button
                          disabled={similarGenerating}
                          size="small"
                          sx={{
                            color: "rgb(249 115 22)",
                            "&:hover": {
                              bgcolor: "rgb(255 247 237)",
                            },
                          }}
                          fullWidth
                          startIcon={<TextSnippetIcon />}
                          onClick={() => handleTryFromFree(image.id)}
                        >
                          Create Similar Style
                        </Button>
                      </CardActions>
                    )}
                  </StyledCard>
                </Grid>
              ))}
              {similarGenerating ? (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <StyledCard className="flex items-center justify-center">
                    <CircularProgress sx={{ color: "rgb(249 115 22)" }} />
                  </StyledCard>
                </Grid>
              ) : null}
            </Grid>
          )}
        </Box>
      </StyledPaper>

      <Modal
        open={previewOpen}
        onClose={handlePreviewClose}
        style={{ overflow: "auto" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "80%", md: "70%" },
            maxWidth: "1200px",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <div className="flex justify-between items-center mx-2">
            <div className="flex gap-2">
              <Button
                size="small"
                sx={{
                  color: "rgb(249 115 22)",
                  "&:hover": {
                    bgcolor: "rgb(255 247 237)",
                  },
                }}
                fullWidth
                startIcon={<CloudDownloadIcon />}
                onClick={() => handleDownload(selectedImage)}
              >
                Download
              </Button>
            </div>
            <IconButton onClick={handlePreviewClose}>
              <CloseIcon />
            </IconButton>
          </div>
          {selectedImage && (
            <Box
              sx={{
                width: "100%",
                position: "relative",
                paddingTop: "56.25%", // 16:9 aspect ratio
              }}
            >
              <img
                src={selectedImage}
                alt="Preview"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  backgroundColor: "black",
                }}
              />
              <IconButton
                sx={{
                  position: "absolute",
                  left: 8,
                  top: "50%",
                  transform: "translateY(-50%)",
                  bgcolor: "rgba(255, 255, 255, 0.8)",
                  "&:hover": { bgcolor: "rgba(255, 255, 255, 0.9)" },
                  display: currentImageIndex === 0 ? "none" : "flex",
                }}
                onClick={handlePrevImage}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <IconButton
                sx={{
                  position: "absolute",
                  right: 8,
                  top: "50%",
                  transform: "translateY(-50%)",
                  bgcolor: "rgba(255, 255, 255, 0.8)",
                  "&:hover": { bgcolor: "rgba(255, 255, 255, 0.9)" },
                  display:
                    currentImageIndex === _generatedImg.length - 1
                      ? "none"
                      : "flex",
                }}
                onClick={handleNextImage}
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Modal>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Container>
  );
};

export default ImageGeneration;
