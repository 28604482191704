import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const API_ENDPOINT = `${process.env.REACT_APP_API_URL}/api/payment/apply-coupon`;
const MESSAGES = {
  SUCCESS: "Great! Your voucher has been successfully applied to your account",
  INVALID:
    "This voucher code is not valid. Please check the code and try again, or contact support if you need help.",
  ERROR:
    "Oops! We couldn't verify this voucher. Please make sure you've entered the code correctly and that it hasn't expired.",
};

const InfoIcon = () => (
  <svg
    className="w-4 h-4 text-blue-600 mt-0.5"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const LockIcon = () => (
  <svg
    className="w-4 h-4"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
    />
  </svg>
);

export default function ApplyVoucher() {
  const [voucher, setVoucher] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const handleApplyCoupon = async () => {
    const voucherCode = voucher.trim();
    if (isProcessing || !voucherCode) return;

    setIsProcessing(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        API_ENDPOINT,
        { coupon: voucherCode },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      toast[response?.data?.success ? "success" : "error"](
        response?.data?.success ? MESSAGES.SUCCESS : MESSAGES.INVALID
      );
    } catch (error) {
      toast.error(error?.response?.data?.message || MESSAGES.ERROR);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="flex w-full justify-center items-center my-10">
      <div className="min-w-96 max-[400px]:w-full border rounded-3xl p-6 lg:p-8 hover:shadow-lg transition-all duration-300 border-gray-200">
        <div className="flex items-center gap-2 mb-3">
          <div className="font-semibold text-lg">Apply Voucher</div>
          <span className="bg-blue-100 text-blue-700 text-xs px-2 py-1 rounded-full">
            All Users
          </span>
        </div>

        <div className="text-sm text-gray-600 mb-4">
          <p className="mb-2">
            Enter your voucher code to receive special discounts and offers. You
            can use vouchers from:
          </p>
          <ul className="list-disc list-inside mb-2 ml-2">
            <li>Your team leader or organization</li>
            <li>Friends or referral programs</li>
            <li>Personal promotional codes</li>
          </ul>

          <div className="flex items-start gap-2 text-xs bg-gray-50 p-3 rounded-lg">
            <InfoIcon />
            <p>
              Make sure to enter the voucher code exactly as provided. Vouchers
              are case-sensitive and cannot be combined with other offers.
            </p>
          </div>
        </div>

        <div className="space-y-3">
          <input
            type="text"
            value={voucher}
            readOnly={isProcessing}
            placeholder="Enter your voucher code"
            onChange={(e) => setVoucher(e.target.value)}
            className="border-2 border-gray-300 rounded-lg p-2.5 w-full focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all"
          />

          <button
            onClick={handleApplyCoupon}
            disabled={isProcessing}
            className={`
              w-full py-2.5 px-6 rounded-lg font-medium transition-all duration-300
              bg-blue-600 text-white hover:bg-blue-700 hover:shadow-sm
              ${isProcessing ? "opacity-75 cursor-not-allowed" : ""}
            `}
          >
            {isProcessing ? "Processing..." : "Apply Voucher"}
          </button>
        </div>

        <div className="mt-4 flex items-center gap-2 text-xs text-gray-500">
          <LockIcon />
          <span>Valid voucher codes only</span>
        </div>
      </div>
    </div>
  );
}
