import { Box, Button, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { userSelector } from "../app/slices/user";
import HappyChristmas from "../assets/happy-christmas.png";
import GFooter from "./layout/GFooter";
import GHeader from "./layout/GHeader";
import prices from "../data/pricing";
import useStripeCheckout from "../hooks/useStripeCheckout";

const _prices = prices.map((item) => ({
  name: item.name,
  price: item.price,
  priceId: item.priceId,
}));

export default function ChristmasGift() {
  const navigate = useNavigate();
  const pricingRef = useRef(null);
  const bannerRef = useRef(null);
  const faqRef = useRef(null);
  const user = useSelector(userSelector);
  const { openCheckoutWindow } = useStripeCheckout();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState(_prices[0].priceId);
  const [formData, setFormData] = useState({
    eventName: "christmas",
    firstName: "",
    lastName: "",
    email: "",
    from: "",
    message: "",
  });

  const handleAmountSelect = (priceId) => {
    setSelectedPriceId(priceId);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isAuthenticated = user.isFetched && user.isAuthenticated;
    if (!isAuthenticated) {
      return navigate("/auth");
    }
    const token = localStorage?.getItem("token");

    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payment/event-gift`,
        { ...formData, priceId: selectedPriceId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        const url = response.data.url;
        openCheckoutWindow(url, false);
      } else {
        toast.warn(response.data.message);
      }
    } catch (error) {
    } finally {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        setIsLoading(false);
      }, 1000);
    }
  };

  return (
    <>
      <GHeader pricingRef={pricingRef} bannerRef={bannerRef} />
      <div className="max-w-screen-xl mx-auto flex min-h-screen">
        <div className="w-full max-w-[1200px] mx-auto flex m-4 flex-col lg:flex-row gap-8 border rounded-3xl border-gray-200">
          <div className="w-full lg:w-1/2 rounded-3xl p-8 pt-2 mt-5">
            <img
              src={HappyChristmas}
              alt="Happy Christmas"
              className="w-full"
            />
          </div>

          <div className="w-full lg:w-1/2 p-8">
            <Typography
              variant="h5"
              sx={{
                color: "#F97316",
                fontSize: { xs: "2rem", md: "2.5rem" },
                fontWeight: 600,
                mb: 4,
              }}
            >
              Send a Christmas gift to your loved ones🎄
            </Typography>

            <Typography variant="h6" sx={{ mb: 2 }}>
              Select a gift plan
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 3,
                mb: 4,
              }}
            >
              {_prices.map(({ name, price, priceId }) => (
                <Button
                  key={price}
                  variant={"outlined"}
                  onClick={() => handleAmountSelect(priceId)}
                  sx={{
                    flex: 1,
                    borderRadius: "24px",
                    borderColor:
                      selectedPriceId === priceId ? "#F97316" : "#E0E0E0",
                    p: 2,
                    textTransform: "none",
                    transition: "transform 0.3s ease-in-out",
                    transform:
                      selectedPriceId === priceId ? "scale(1.05)" : "scale(1)",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle1" color="black">
                      {name}
                    </Typography>
                    <Typography
                      variant="h6"
                      color={
                        selectedPriceId === priceId ? "inherit" : "#FF5722"
                      }
                    >
                      ${price}
                    </Typography>
                  </Box>
                </Button>
              ))}
            </Box>

            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ display: "grid", gap: 3 }}
            >
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Recipient details
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                  gap: 2,
                }}
              >
                <TextField
                  type="text"
                  required
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  focused={false}
                  onChange={handleInputChange}
                />
                <TextField
                  type="text"
                  required
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  focused={false}
                  onChange={handleInputChange}
                />
              </Box>
              <TextField
                type="email"
                required
                name="email"
                label="Email address"
                variant="outlined"
                fullWidth
                focused={false}
                onChange={handleInputChange}
              />

              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Additional
              </Typography>
              <TextField
                name="from"
                label="From (Optional)"
                variant="outlined"
                fullWidth
                focused={false}
                onChange={handleInputChange}
              />
              <TextField
                name="message"
                label="Message (Optional)"
                variant="outlined"
                fullWidth
                focused={false}
                multiline
                rows={4}
                onChange={handleInputChange}
              />

              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                The gift card will be sent to recipient's email directly after
                successful payment.
              </Typography>

              <LoadingButton
                type="submit"
                variant="contained"
                fullWidth
                disabled={isLoading}
                loading={isLoading}
                sx={{
                  color: "white",
                  mt: 2,
                  bgcolor: "#FF5722",
                  borderRadius: "100px",
                  py: 1.5,
                  "&:hover": {
                    bgcolor: "#F4511E",
                  },
                }}
              >
                Go to checkout
              </LoadingButton>
            </Box>
          </div>
        </div>
      </div>
      <GFooter pricingRef={pricingRef} faqRef={faqRef} />
    </>
  );
}
