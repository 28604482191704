import { useState, useCallback, useMemo, memo } from "react";
import { useDispatch } from "react-redux";
import { Tab } from "@headlessui/react";
import Fuse from "fuse.js";
import { asyncGetUser } from "../../app/slices/user";
import {
  styleCategories,
  professionCategories,
  GENDER,
  PROMPT_TYPE,
} from "../../data/styleCategories";

const classNames = (...classes) => classes.filter(Boolean).join(" ");

const SearchInput = memo(({ searchQuery, setSearchQuery, promptType }) => (
  <div className="max-w-md mx-auto mb-8">
    <div className="relative">
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder={
          promptType === PROMPT_TYPE.PROFESSION
            ? "Search professions..."
            : "Search styles..."
        }
        className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
        <svg
          className="h-5 w-5 text-gray-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </div>
    </div>
  </div>
));

const StyleCard = memo(({ category, isSelected, onClick }) => (
  <div
    className={classNames(
      "group relative rounded-xl overflow-hidden cursor-pointer transition-all duration-300",
      "border-2 hover:shadow-xl transform hover:-translate-y-1",
      isSelected
        ? "border-blue-600 shadow-lg"
        : "border-gray-200 hover:border-blue-300"
    )}
    onClick={onClick}
  >
    <div className="aspect-w-16 aspect-h-9">
      <img
        src={category.image}
        alt={category.title}
        className="w-full h-full object-cover"
      />
    </div>
    <div className="p-6 bg-white">
      <h3 className="text-lg font-semibold text-gray-900 mb-2">
        {category.title}
      </h3>
      <p className="text-sm text-gray-600">{category.description}</p>
    </div>
    {isSelected && (
      <div className="absolute top-4 right-4 bg-blue-700 text-white rounded-full p-2.5 shadow-lg">
        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    )}
  </div>
));

const TabList = memo(({ items, selectedIndex, onChange, disabled = [] }) => (
  <Tab.List className="flex space-x-1 rounded-xl bg-blue-100 p-1 max-w-md mx-auto mb-8">
    {items.map((item, idx) => (
      <Tab
        key={item}
        disabled={disabled[idx]}
        className={({ selected }) =>
          classNames(
            "w-full rounded-lg py-3 text-sm font-medium leading-5 transition-all duration-200",
            "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400",
            selected
              ? "bg-white text-blue-700 shadow-md"
              : "text-blue-700 hover:bg-white/[0.12] hover:text-blue-700"
          )
        }
      >
        {item}
      </Tab>
    ))}
  </Tab.List>
));

export default function StyleSelection() {
  const dispatch = useDispatch();
  const [promptType, setPromptType] = useState(PROMPT_TYPE.PROFESSION);
  const [selectedProfessions, setSelectedProfessions] = useState(new Set());
  const [selectedStyles, setSelectedStyles] = useState(new Set());
  const [activeGender, setActiveGender] = useState(GENDER.MAN);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const MIN_PROFESSION = 1;
  const MAX_PROFESSION = 5;
  const MIN_STYLE = 1;
  const MAX_STYLE =
    activeGender === GENDER.MAN
      ? Object.keys(styleCategories[GENDER.MAN]).length
      : Object.keys(styleCategories[GENDER.WOMAN]).length;

  const toggleSelection = useCallback(
    (setSelected, maxLimit) => (category) => {
      setSelected((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(category)) {
          newSet.delete(category);
        } else if (newSet.size < maxLimit) {
          newSet.add(category);
        }
        return newSet;
      });
    },
    []
  );

  const handleProfessionSelect = useCallback(
    (category) => {
      toggleSelection(setSelectedProfessions, MAX_PROFESSION)(category);
    },
    [toggleSelection, setSelectedProfessions, MAX_PROFESSION]
  );

  const handleStyleSelect = useCallback(
    (category) => {
      toggleSelection(setSelectedStyles, MAX_STYLE)(category);
    },
    [toggleSelection, setSelectedStyles, MAX_STYLE]
  );

  const selectableStyle = useMemo(
    () => (genderType) => {
      const sourceData =
        promptType === PROMPT_TYPE.STYLE
          ? styleCategories[genderType]
          : professionCategories[genderType];

      const styleData = Object.entries(sourceData).map(([key, value]) => ({
        key,
        ...value,
      }));

      if (!searchQuery.trim()) return styleData;

      const fuse = new Fuse(styleData, {
        keys: ["title", "description"],
        threshold: 0.3,
        includeScore: true,
        ignoreLocation: true,
      });

      return fuse.search(searchQuery).map(({ item }) => item);
    },
    [promptType, searchQuery]
  );

  const handleContinue = useCallback(async () => {
    if (isLoading) return;

    if (
      promptType === PROMPT_TYPE.PROFESSION &&
      selectedProfessions.size >= MIN_PROFESSION
    ) {
      setPromptType(PROMPT_TYPE.STYLE);
      window.scrollTo(0, 0);
      return;
    }

    if (selectedStyles.size >= MIN_STYLE) {
      setIsLoading(true);
      const authToken = localStorage.getItem("token");
      const styles = [...new Set([...selectedStyles, ...selectedProfessions])];

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/onboarding/style-selection`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({
              styles,
              gender: activeGender,
            }),
          }
        );

        const data = await response.json();
        if (!response.ok)
          throw new Error(data.message || "Something went wrong");
        if (data?.success) dispatch(asyncGetUser({ authToken }));
      } catch (error) {
        console.error("Style selection error:", error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    isLoading,
    promptType,
    selectedProfessions,
    selectedStyles,
    activeGender,
    dispatch,
  ]);

  const renderStyleGrid = useCallback(
    (genderType) => (
      <Tab.Panel className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {selectableStyle(genderType).map((style) => (
          <StyleCard
            key={style.key}
            category={style}
            isSelected={
              promptType === PROMPT_TYPE.STYLE
                ? selectedStyles.has(style.key)
                : selectedProfessions.has(style.key)
            }
            onClick={() =>
              promptType === PROMPT_TYPE.STYLE
                ? handleStyleSelect(style.key)
                : handleProfessionSelect(style.key)
            }
          />
        ))}
      </Tab.Panel>
    ),
    [
      promptType,
      selectedStyles,
      selectedProfessions,
      handleStyleSelect,
      handleProfessionSelect,
      selectableStyle,
    ]
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12 max-w-3xl mx-auto">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
            Create Your Professional Headshots
          </h1>

          <Tab.Group
            selectedIndex={promptType === PROMPT_TYPE.PROFESSION ? 0 : 1}
            onChange={(index) =>
              setPromptType(
                index === 0 ? PROMPT_TYPE.PROFESSION : PROMPT_TYPE.STYLE
              )
            }
          >
            <TabList
              items={["Profession", "Style"]}
              disabled={[false, selectedProfessions.size < MIN_PROFESSION]}
            />
          </Tab.Group>

          <p className="text-xl text-gray-600 mb-6">
            Choose a professional style that matches your industry. Our AI will
            transform your regular photos into stunning professional headshots
            that elevate your personal brand.
          </p>

          <div className="bg-blue-100 rounded-lg p-4 text-blue-800 text-sm">
            <span className="font-semibold">Pro tip:</span> Select at least one
            profession that best represents your professional identity.
          </div>
        </div>

        <Tab.Group
          onChange={(index) => {
            const newGender = index === 0 ? GENDER.MAN : GENDER.WOMAN;
            setActiveGender(newGender);
            setSelectedProfessions(new Set());
            setSelectedStyles(new Set());
            setSearchQuery("");
          }}
        >
          <TabList items={["Male Photos", "Female Photos"]} />

          <Tab.Panels>
            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              promptType={promptType}
            />
            {[GENDER.MAN, GENDER.WOMAN].map((genderType) =>
              renderStyleGrid(genderType)
            )}
          </Tab.Panels>
        </Tab.Group>

        <div className="mt-12 flex flex-col items-center">
          <button
            onClick={handleContinue}
            disabled={
              promptType === PROMPT_TYPE.PROFESSION
                ? selectedProfessions.size < MIN_PROFESSION
                : selectedStyles.size < MIN_STYLE
            }
            className={classNames(
              "px-12 py-4 rounded-xl font-medium text-lg transition-all duration-300",
              "transform hover:scale-105 focus:outline-none focus:ring-4",
              (
                promptType === PROMPT_TYPE.PROFESSION
                  ? selectedProfessions.size >= MIN_PROFESSION
                  : selectedStyles.size >= MIN_STYLE
              )
                ? "bg-blue-700 hover:bg-blue-700 text-white shadow-lg hover:shadow-xl"
                : "bg-gray-300 cursor-not-allowed text-gray-500"
            )}
          >
            {promptType === PROMPT_TYPE.PROFESSION
              ? "Continue to Style Selection"
              : "Continue to Pricing"}
          </button>

          <p className="mt-4 text-sm text-gray-500">
            {promptType === PROMPT_TYPE.PROFESSION
              ? selectedProfessions.size < MIN_PROFESSION
                ? `Select at least ${MIN_PROFESSION} profession${
                    MIN_PROFESSION > 1 ? "s" : ""
                  } to continue`
                : `You have selected ${
                    selectedProfessions.size
                  } out of ${MAX_PROFESSION} profession${
                    MAX_PROFESSION > 1 ? "s" : ""
                  }`
              : selectedStyles.size < MIN_STYLE
              ? `Select at least ${MIN_STYLE} style${
                  MIN_STYLE > 1 ? "s" : ""
                } to continue`
              : `You have selected ${
                  selectedStyles.size
                } out of ${MAX_STYLE} style${MAX_STYLE > 1 ? "s" : ""}`}
          </p>
        </div>
      </div>
    </div>
  );
}
