import { useNavigate } from "react-router-dom";
import TryMoreImg from "../../assets/landing/try-now.png";

export default function Try() {
  const navigate = useNavigate();
  return (
    <div className="px-4 py-16">
      <div className="mx-auto max-w-6xl bg-gradient-to-r from-[#FFF5F1] to-[#FFF8F3] rounded-3xl overflow-hidden">
        <div className="flex flex-col md:flex-row items-center justify-between gap-8 px-8 md:px-12">
          {/* Left Content */}
          <div className="w-full md:w-1/2 text-center md:text-left">
            <h2 className="text-4xl md:text-5xl font-bold mb-6 md:mb-8">
              Get <span className="text-[#F06E28]">your</span>{" "}
              <span className="text-[#FAB347]">AI</span>
              <br />
              Headshots today
            </h2>
            <button
              onClick={() => navigate("/auth")}
              className="inline-flex items-center px-6 md:px-8 py-3 bg-black text-white rounded-full text-lg font-medium hover:opacity-90"
            >
              Generate AI Headshots →
            </button>
          </div>

          {/* Right Image */}
          <div className="w-full md:w-1/2 flex justify-center md:justify-end relative">
            <div className="absolute w-48 h-48 md:w-72 md:h-72 bg-[#FAB34710] rounded-full -right-2 md:-right-5 mt-5" />
            <img
              src={TryMoreImg}
              alt="Try AI Headshots"
              className="relative z-10 max-w-full h-auto object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
