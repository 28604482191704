import { useNavigate } from "react-router-dom";
import GiftIcon from "../../assets/merry-christmas.png";
import Snowflake from "../../assets/snowflake.png";

export default function EventGift() {
  const navigate = useNavigate();

  return (
    <div className="max-w-screen-xl mx-auto p-4 mt-16">
      <div
        className="rounded-3xl overflow-hidden bg-no-repeat bg-cover bg-center"
        style={{ backgroundImage: `url(${Snowflake})` }}
      >
        <div className="w-full h-full bg-gradient-to-r from-[#f9e3db7a] to-[#fad6bd70] rounded-3xl overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between gap-8 p-8 md:p-12">
            <div className="w-full md:w-1/2 flex items-center gap-4">
              <div className="w-16 h-16 flex-shrink-0">
                <img
                  src={GiftIcon}
                  alt="Gift"
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="ml-2">
                <h2 className="text-2xl md:text-3xl font-bold mb-2">
                  Send a Christmas gift to your loved ones
                </h2>
                <p className="text-gray-600">
                  Perfect for spreading holiday cheer in a truly memorable way
                </p>
              </div>
            </div>

            {/* Right Content */}
            <div className="w-full md:w-auto">
              <button
                onClick={() => navigate("/christmas")}
                className="w-full md:w-auto px-8 py-3 bg-orange-500 text-white rounded-full font-medium hover:bg-orange-600 transition-colors"
              >
                Send Gift Now!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
