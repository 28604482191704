import { Link } from "react-router-dom";
import { Container, Typography, Box, Grid } from "@mui/material";
import EUImg from "../assets/landing/eu.jpg";
import CreateImage from "../assets/create.png";
import ShareImage from "../assets/share.png";
import EarnImage from "../assets/earn.png";
// import Logo from "../assets/logo.png";

export default function Affiliate() {
  return (
    <div>
      {/* Navigation */}
      <nav className="bg-white">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link
            to="/"
            className="flex items-center space-x-1 rtl:space-x-reverse"
          >
            {/* <img src={Logo} className="h-6" alt="" /> */}
            <span className="self-center text-2xl font-semibold whitespace-nowrap">
              HeadShotlyAI
            </span>
          </Link>
          <button
            data-collapse-toggle="navbar-dropdown"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-700 rounded-lg md:hidden hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-dropdown"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className="hidden w-full md:block md:w-auto"
            id="navbar-dropdown"
          >
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white">
              <li>
                <Link
                  to="/affiliate"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-700 md:p-0"
                >
                  Affiliate Program
                </Link>
              </li>
              <li>
                <Link
                  to="/auth"
                  className="bg-orange-500 font-normal text-base text-white rounded-3xl px-6 py-3"
                >
                  Get Professional Photos
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <Container maxWidth="lg">
        {/* Hero Section */}
        <Box sx={{ textAlign: "center", my: 6, pt: 2 }}>
          <Typography
            variant="h1"
            className="font-semibold text-4xl md:text-5xl mb-3"
            sx={{ color: "#1a1a1a" }}
          >
            Partner With Us to Transform Professional Images 📸
          </Typography>

          <Typography
            className="text-gray-600 text-xl text-center mb-8"
            sx={{ maxWidth: "600px", mx: "auto" }}
          >
            Earn up to 20% commission helping professionals enhance their
            business presence
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: { xs: 3, md: 8 },
              mb: 8,
              mt: 6,
              maxWidth: "900px",
              mx: "auto",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                p: 3,
                borderRadius: "16px",
                backgroundColor: "#fff",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "translateY(-4px)",
                  boxShadow: "0 6px 24px rgba(0, 0, 0, 0.08)",
                },
              }}
            >
              <Typography
                variant="h3"
                className="font-bold text-orange-500"
                sx={{ mb: 1, fontSize: { xs: "2rem", md: "2.5rem" } }}
              >
                20%
              </Typography>
              <Typography
                sx={{
                  color: "#666",
                  fontWeight: 500,
                  fontSize: "0.95rem",
                }}
              >
                Commission Rate
              </Typography>
              <Typography
                sx={{
                  color: "#888",
                  fontSize: "0.85rem",
                  mt: 1,
                }}
              >
                Highest in the industry
              </Typography>
            </Box>

            <Box
              sx={{
                textAlign: "center",
                p: 3,
                borderRadius: "16px",
                backgroundColor: "#fff",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "translateY(-4px)",
                  boxShadow: "0 6px 24px rgba(0, 0, 0, 0.08)",
                },
              }}
            >
              <Typography
                variant="h3"
                className="font-bold text-orange-500"
                sx={{ mb: 1, fontSize: { xs: "2rem", md: "2.5rem" } }}
              >
                ~5%
              </Typography>
              <Typography
                sx={{
                  color: "#666",
                  fontWeight: 500,
                  fontSize: "0.95rem",
                }}
              >
                Conversion Rate
              </Typography>
              <Typography
                sx={{
                  color: "#888",
                  fontSize: "0.85rem",
                  mt: 1,
                }}
              >
                Above market average
              </Typography>
            </Box>

            <Box
              sx={{
                textAlign: "center",
                p: 3,
                borderRadius: "16px",
                backgroundColor: "#fff",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "translateY(-4px)",
                  boxShadow: "0 6px 24px rgba(0, 0, 0, 0.08)",
                },
              }}
            >
              <Typography
                variant="h3"
                className="font-bold text-orange-500"
                sx={{ mb: 1, fontSize: { xs: "2rem", md: "2.5rem" } }}
              >
                2 Weeks
              </Typography>
              <Typography
                sx={{
                  color: "#666",
                  fontWeight: 500,
                  fontSize: "0.95rem",
                }}
              >
                Payment Cycle
              </Typography>
              <Typography
                sx={{
                  color: "#888",
                  fontSize: "0.85rem",
                  mt: 1,
                }}
              >
                Fast & reliable payouts
              </Typography>
            </Box>
          </Box>

          <button
            onClick={() =>
              window.open("https://forms.gle/CYCzj84dYEPtFZh38", "_blank")
            }
            className="bg-orange-500 hover:bg-orange-600 transition-colors font-semibold text-base text-white rounded-full px-8 py-3 mt-6 mb-16"
          >
            START EARNING TODAY →
          </button>
        </Box>

        {/* Steps Section */}
        <Box sx={{ textAlign: "center", mb: 20 }}>
          <Typography variant="h2" sx={{ mb: 4 }}>
            Start Earning in 3 Simple Steps
          </Typography>
          <Typography
            sx={{
              mb: 6,
              color: "#4B5563",
              fontSize: "1rem",
            }}
          >
            Earning with HeadShotlyAI is simple, follow the steps below to
            kickstart your affiliate income
          </Typography>

          {/* Step cards with consistent spacing */}
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box
                className="border-gray-300 border rounded-3xl p-8 hover:shadow-xl transition-all"
                sx={{
                  backgroundColor: "#fff",
                  "&:hover": {
                    borderColor: "#ff5a1f",
                    transform: "translateY(-4px)",
                  },
                }}
              >
                <Typography
                  className="bg-orange-500 w-10 h-10 rounded-full flex items-center justify-center text-white font-bold text-lg"
                  sx={{
                    mb: 1,
                    minWidth: "40px",
                    minHeight: "40px",
                  }}
                >
                  1
                </Typography>
                <Typography
                  variant="h4"
                  className="font-semibold text-2xl"
                  gutterBottom
                >
                  Create
                </Typography>
                <Typography color="text.secondary">
                  Create engaging content showing how HeadShotlyAI transforms
                  ordinary photos into professional headshots. Share real
                  success stories and the impact of AI-powered professional
                  image enhancement.
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ mt: 2, color: "primary.main" }}
                >
                  Get our proven B2B content templates and examples →
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  width: "100%",
                  height: "400px",
                  bgcolor: "#f5f5f5",
                  borderRadius: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={CreateImage}
                  alt="Create"
                  style={{ maxWidth: "100%", borderRadius: "20px" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Step 2 */}
        <Grid container spacing={8} alignItems="center" sx={{ mb: 12 }}>
          <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
            <Box
              sx={{
                width: "100%",
                height: "400px",
                bgcolor: "#f5f5f5",
                borderRadius: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={ShareImage}
                alt="Share"
                style={{ maxWidth: "100%", borderRadius: "20px" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
            <Box
              sx={{ textAlign: "left" }}
              className="border-gray-300 border rounded-3xl p-6"
            >
              <Typography
                className="bg-orange-500 w-10 h-10 rounded-full flex items-center justify-center text-white font-bold text-lg"
                sx={{
                  mb: 1,
                  minWidth: "40px",
                  minHeight: "40px",
                }}
              >
                2
              </Typography>
              <Typography
                variant="h4"
                className="font-semibold text-2xl"
                gutterBottom
              >
                Share
              </Typography>
              <Typography color="text.secondary">
                Share your unique affiliate link on LinkedIn, Instagram &
                Twitter. Show before/after transformations and how HeadShotlyAI
                helps professionals enhance their business presence with
                AI-powered headshots.
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                Perfect for business coaches, professional photographers &
                content creators
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Step 3 */}
        <Grid container spacing={8} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              sx={{ textAlign: "left" }}
              className="border-gray-300 border rounded-3xl p-6"
            >
              <Typography
                className="bg-orange-500 w-10 h-10 rounded-full flex items-center justify-center text-white font-bold text-lg"
                sx={{
                  mb: 1,
                  minWidth: "40px",
                  minHeight: "40px",
                }}
              >
                3
              </Typography>
              <Typography
                variant="h4"
                className="font-semibold text-2xl"
                gutterBottom
              >
                Earn
              </Typography>
              <Typography color="text.secondary" component="div" sx={{ mb: 2 }}>
                Get rewarded generously for every professional you help succeed:
                <ul
                  style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                    marginTop: "8px",
                  }}
                >
                  <li>Earn $10.50 instantly per Basic headshot package</li>
                  <li>Make $13.50 for each Professional package conversion</li>
                  <li>Get $22.50 for every Enterprise package sale</li>
                  <li className="text-orange-500 font-medium mt-2">
                    Industry-leading 20% commission on all packages!
                  </li>
                </ul>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                height: "400px",
                bgcolor: "#f5f5f5",
                borderRadius: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={EarnImage}
                alt="Earn"
                style={{ maxWidth: "100%", borderRadius: "20px" }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* FAQ Section */}
        <Box sx={{ maxWidth: 800, mx: "auto", mb: 8, px: 4 }}>
          <Typography variant="h3" className="font-semibold text-3xl mb-4">
            Questions, answered!
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
              px: 3,
            }}
          >
            {[
              {
                question: "Who can become an affiliate?",
                answer:
                  "Professional photographers, business coaches, career consultants, and content creators focused on professional development.",
              },
              {
                question: "How do payouts work?",
                answer:
                  "We process payments bi-weekly via PayPal or bank transfer. Minimum payout is $50.",
              },
              {
                question: "What content should I create?",
                answer:
                  "Share professional transformation stories, before/after results, or tips for better business headshots.",
              },
            ].map((faq, index) => (
              <Box
                key={index}
                sx={{
                  p: 4,
                  border: "1px solid #e5e7eb",
                  borderRadius: "24px",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                    borderColor: "#ff5a1f",
                  },
                }}
              >
                <Typography variant="h6" className="font-semibold text-xl mb-2">
                  {faq.question}
                </Typography>
                <Typography color="text.secondary" className="text-gray-600">
                  {faq.answer}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Final CTA */}
        <Box
          sx={{
            textAlign: "center",
            mb: 16,
            py: 12,
            px: 6,
            backgroundColor: "#f7f9fc",
            borderRadius: "24px",
          }}
        >
          <Typography variant="h3" className="font-semibold text-3xl mb-4">
            Join Our Network of Professional Partners
          </Typography>
          <Typography
            sx={{
              mb: 6,
              maxWidth: 500,
              mx: "auto",
              color: "#4B5563",
              fontSize: "1.125rem",
              lineHeight: 1.6,
            }}
          >
            Start creating content and grow your professional network with
            HeadShotlyAI.
          </Typography>
          <button
            onClick={() =>
              window.open("https://forms.gle/CYCzj84dYEPtFZh38", "_blank")
            }
            className="bg-orange-500 hover:bg-orange-600 transition-colors font-semibold text-base text-white rounded-full px-8 py-3"
          >
            Join Affiliate Program
          </button>
        </Box>
      </Container>

      {/* Footer */}
      <div className="border-b w-full my-3" />
      <div className="max-w-screen-xl flex flex-wrap items-center mx-auto p-4">
        <div className="w-64">
          <div className="font-semibold text-xl mb-1.5 flex items-center">
            {/* <img src={Logo} className="h-6 mr-1" alt="" /> */}
            HeadShotlyAI
          </div>
          <p className="font-normal text-sm">
            Studio-Quality Professional Headshots in Minutes
          </p>
        </div>
        <div className="flex ml-24 gap-x-20 text-sm font-medium">
          <div>
            <div>
              <Link to={"/privacy-policy"}>Privacy policy</Link>
            </div>
          </div>
        </div>
        <div className="border-b w-full mt-8"></div>
        <div className="flex items-center justify-between my-4 w-full">
          <div className="flex items-center justify-center">
            <img src={EUImg} alt="" className="h-4" />
            <p className="font-normal text-xs ml-1">
              Made in the EU. We take your privacy seriously.
            </p>
          </div>
          <div className="font-normal text-xs">headshotly.ai</div>
        </div>
      </div>
    </div>
  );
}
