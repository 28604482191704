const isProduction = process.env.NODE_ENV === "production";

const prices = [
  {
    key: "basic",
    name: "Basic",
    price: "30.00",
    period: "onetime",
    priceId: isProduction
      ? "price_1QO4aKB2ts8J0Qj6mGdkXJL0"
      : "price_1QFeo7DlHYvrTMaeR4B1czaX",
    features: [
      {
        key: "1",
        content: "Ready-to-Use AI Photos: 40 Unique Backgrounds",
      },
      {
        key: "2",
        content: "Ready-to-Use AI Photos: 40 Unique Clothing Styles",
      },
      {
        key: "3",
        content: "Ready-to-Use AI Photos: 40 Different Poses",
      },
      {
        key: "4",
        content: "Custom AI Photo Creation: 10 Prompts (Write your own photo ideas)",
      },
      {
        key: "5",
        content: "Training Model: 1 credit",
      },
      {
        key: "6",
        content: "Turnaround Time: Less than 30 Minutes",
      },
      {
        key: "7",
        content: "Money-back Guarantee",
      },
    ],
  },
  {
    key: "standard",
    name: "Standard",
    price: "50.00",
    period: "onetime",
    priceId: isProduction
      ? "price_1QO4d7B2ts8J0Qj6D5JQmddw"
      : "price_1QFeqEDlHYvrTMaeKxyu4R74",
    features: [
      {
        key: "1",
        content: "Ready-to-Use AI Photos: 100 Unique Backgrounds",
      },
      {
        key: "2",
        content: "Ready-to-Use AI Photos: 100 Unique Clothing Styles",
      },
      {
        key: "3",
        content: "Ready-to-Use AI Photos: 100 Different Poses",
      },
      {
        key: "4",
        content: "Custom AI Photo Creation: 20 Prompts (Write your own photo ideas)",
      },
      {
        key: "5",
        content: "Training Model: 2 Credits",
      },
      {
        key: "6",
        content: "Turnaround Time: Less than 30 Minutes",
      },
      {
        key: "7",
        content: "Money-back Guarantee",
      },
    ],
  },
  {
    key: "premium",
    name: "Premium",
    price: "79.00",
    period: "onetime",
    priceId: isProduction
      ? "price_1QO4emB2ts8J0Qj6EEvV2aHB"
      : "price_1QFeqhDlHYvrTMaeDV4eTU7a",
    features: [
      {
        key: "1",
        content: "Ready-to-Use AI Photos: 240 Unique Backgrounds",
      },
      {
        key: "2",
        content: "Ready-to-Use AI Photos: 240 Unique Clothing Styles",
      },
      {
        key: "3",
        content: "Ready-to-Use AI Photos: 240 Different Poses",
      },
      {
        key: "4",
        content: "Custom AI Photo Creation: 30 Prompts (Write your own photo ideas)",
      },
      {
        key: "5",
        content: "Training Model: 4 credits",
      },
      {
        key: "6",
        content: "Turnaround Time: Less than 30 Minutes",
      },
      {
        key: "7",
        content: "Money-back Guarantee",
      },
    ],
  },
];

export const boosterPack = [
  {
    name: "$5.00 for 30 credits",
    plan: "price_5",
  },
  {
    name: "$10.00 for 60 credits",
    plan: "price_10",
  },
  {
    name: "$15.00 for 90 credits",
    plan: "price_15",
  },
];

export default prices;
