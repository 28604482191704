import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userSelector } from "../../app/slices/user";
import ltdPrices from "../../data/ltd-pricing";
import useStripeCheckout from "../../hooks/useStripeCheckout";
import GFooter from "../layout/GFooter";
import GHeader from "../layout/GHeader";

const FormatFeature = ({ text }) => {
  if (text === "Money-back Guarantee") {
    return (
      <div className="flex items-center justify-center gap-2 mb-3">
        <span className="text-orange-500 text-lg">•</span>
        <span className="text-sm font-semibold text-orange-500">{text}</span>
      </div>
    );
  }

  const [title, value] = text.split(":");
  if (!value) {
    return (
      <div
        className={`flex items-start w-full gap-2 px-2 py-1.5 ${
          !title && "invisible"
        }`}
      >
        <div className="text-orange-500 text-lg mt-0.5">•</div>
        <div className="text-sm text-left flex-1">
          <span className="text-gray-900">{text}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-start w-full gap-2 px-2 py-1.5">
      <div className="text-orange-500 text-lg mt-0.5">•</div>
      <div className="text-sm text-left flex-1">
        <span className="text-gray-900 font-medium block">{title}</span>
        <span className="text-gray-600 block">{value.trim()}</span>
      </div>
    </div>
  );
};

const metrics = [
  {
    value: "10min",
    label: "Delivery Time",
  },
  {
    value: "98%",
    label: "Satisfaction Rate",
  },
  {
    value: "100+",
    label: "Variations",
  },
];

const faqs = [
  {
    question: "What are Credits and how do they work?",
    answer:
      "Credits are one-time use tokens for generating AI headshots. Once purchased, there's no time limit - you can use your credits whenever you want until they're all used up.",
  },
  {
    question: "What is Model Training?",
    answer:
      "Model Training is the process where our AI creates a personalized model based on your uploaded photos. This custom model captures your unique features and style, ensuring the generated photos look authentically like you.",
  },
  {
    question: "What are Custom Generation Credits?",
    answer:
      "Custom Generation Credits allow you to write your own prompts and generate pictures using your personalized AI model. This gives you more control over the final output and lets you create specific scenarios or styles.",
  },
  {
    question: "Why are style variations limited?",
    answer:
      "We focus on quality over quantity. Each style variation is carefully crafted and tested to ensure professional, high-quality results. As we continue our R&D, we'll add more premium variations and backgrounds, which will be automatically available to all lifetime deal users.",
  },
  {
    question: "Will I get access to future styles and features?",
    answer:
      "Yes! As a lifetime deal member, you'll get access to all new style variations and premium backgrounds as they're released.",
  },
  {
    question: "How long does it take to get my AI headshots?",
    answer:
      "The entire process typically takes about 10 minutes from uploading your photos to receiving your AI-generated headshots. Model training takes approximately 5-7 minutes, and generation takes 2-3 minutes.",
  },
  {
    question: "What happens if I'm not satisfied with the results?",
    answer:
      "We offer a 100% satisfaction guarantee. If you're not completely satisfied with your AI headshots, our support team will work with you to either regenerate your photos or provide a full refund.",
  },
  {
    question: "How many photos do I need to upload?",
    answer:
      "We recommend uploading 10-15 high-quality selfies or photos for best results. These photos help our AI understand your features and create a more accurate personalized model.",
  },
  {
    question: "Can I use these photos professionally?",
    answer:
      "Yes! You own full rights to all generated photos and can use them for any professional purpose including LinkedIn, company websites, business cards, and other marketing materials.",
  },
  {
    question: "Is my payment secure?",
    answer:
      "Yes! We use Stripe, a PCI Level 1 certified payment processor (the highest security certification in the payments industry) trusted by companies like Amazon, Google, and Microsoft. Your payment data is encrypted using bank-grade security standards, and we never store your credit card information on our servers.",
  },
  {
    question: "How do you handle my data and privacy?",
    answer:
      "We take your privacy extremely seriously. Your photos and personal data belong to you, and we never sell or use them for marketing or AI training purposes. You have complete control over your data - you can delete your photos, AI model, or entire account at any time through your dashboard or by contacting us. We follow strict data protection guidelines and only use your photos to create your personal AI model, which is used exclusively for generating your headshots.",
  },
  {
    question: "What happens to my photos and data?",
    answer:
      "Your privacy is our priority. We never sell your data or use your photos for marketing or AI training purposes. Your photos are used solely to create your personal AI model for generating your headshots. All data is encrypted and securely stored following industry best practices.",
  },
  {
    question: "Can I delete my data?",
    answer:
      "Absolutely! You have complete control over your data. You can delete your photos, AI model, or entire account at any time directly through your dashboard without needing to contact support. Once deleted, your data is permanently removed from our systems.",
  },
];

const selectedGalleryImages = [
  {
    id: 6,
    src: require("../../assets/onboarding/styleSelection/man/profession/ceo_1.png"),
    title: "CEO",
  },
  {
    id: 42,
    src: require("../../assets/onboarding/styleSelection/man/profession/tech_ceo_1.png"),
    title: "Tech CEO",
  },
  {
    id: 17,
    src: require("../../assets/onboarding/styleSelection/man/profession/executive_1.png"),
    title: "Executive",
  },
  {
    id: 38,
    src: require("../../assets/onboarding/styleSelection/man/profession/software_executive_1.png"),
    title: "Software Executive",
  },
  {
    id: 24,
    src: require("../../assets/onboarding/styleSelection/man/profession/investment_banker_1.png"),
    title: "Investment Banker",
  },
  {
    id: 22,
    src: require("../../assets/onboarding/styleSelection/man/profession/government_executive_1.png"),
    title: "Government Executive",
  },

  // Female Executives - Indoor Studio
  {
    id: 48,
    src: require("../../assets/onboarding/styleSelection/woman/profession/female_ceo_1.png"),
    title: "CEO",
  },
  {
    id: 59,
    src: require("../../assets/onboarding/styleSelection/woman/profession/female_executive_1.png"),
    title: "Executive",
  },
  {
    id: 47,
    src: require("../../assets/onboarding/styleSelection/woman/profession/female_business_leader_1.png"),
    title: "Business Leader",
  },
  {
    id: 69,
    src: require("../../assets/onboarding/styleSelection/woman/profession/female_marketing_executive_1.png"),
    title: "Marketing Executive",
  },
  {
    id: 64,
    src: require("../../assets/onboarding/styleSelection/woman/profession/female_investment_banker_1.png"),
    title: "Investment Banker",
  },
  {
    id: 61,
    src: require("../../assets/onboarding/styleSelection/woman/profession/female_government_executive_1.png"),
    title: "Government Executive",
  },
  {
    id: 63,
    src: require("../../assets/onboarding/styleSelection/woman/profession/female_healthcare_executive_1.png"),
    title: "Healthcare Executive",
  },

  // Modern & Outdoor Styles - Men
  {
    id: 111,
    src: require("../../assets/onboarding/styleSelection/man/lighting_golden_hour_1.png"),
    title: "Golden Hour",
  },
  {
    id: 112,
    src: require("../../assets/onboarding/styleSelection/man/headshot_outdoor_sunset_1.png"),
    title: "Outdoor Sunset",
  },
  {
    id: 117,
    src: require("../../assets/onboarding/styleSelection/man/clothing_suit_no_tie_1.png"),
    title: "Modern Business",
  },

  // Modern & Outdoor Styles - Women
  {
    id: 93,
    src: require("../../assets/onboarding/styleSelection/woman/headshot_minimalist_1.png"),
    title: "Minimalist Style",
  },
  {
    id: 96,
    src: require("../../assets/onboarding/styleSelection/woman/headshot_gradient_1_1.png"),
    title: "Gradient Style",
  },
  {
    id: 98,
    src: require("../../assets/onboarding/styleSelection/woman/lighting_studio_dramatic_1.png"),
    title: "Dramatic Studio",
  },
  {
    id: 92,
    src: require("../../assets/onboarding/styleSelection/woman/business_conference_1_1.png"),
    title: "Business Conference",
  },
];

const GalleryPreview = () => (
  <div className="mt-20 mb-16">
    <div className="text-center mb-12">
      <h2 className="text-2xl sm:text-3xl font-bold mb-4">
        Premium Executive Headshots
      </h2>
      <p className="text-gray-600 mb-8">
        Discover our premium collection of AI-powered headshots trusted by
        Fortune 500 CEOs, tech leaders, and industry executives worldwide
      </p>
    </div>

    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3 sm:gap-4 mb-12">
      {selectedGalleryImages.map((image) => (
        <div
          key={image.id}
          className="group relative transform transition-all duration-300 hover:-translate-y-1 hover:shadow-xl rounded-xl overflow-hidden"
        >
          <div className="aspect-w-1 aspect-h-1 w-full bg-gray-200">
            <img
              src={image.src}
              alt={image.title}
              className="w-full h-full object-cover object-center transition-transform duration-500 group-hover:scale-110"
              loading="lazy"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-all duration-300" />
            <div className="absolute bottom-0 left-0 right-0 p-4 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300">
              <p className="text-white font-medium text-sm sm:text-base">
                {image.title}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>

    <div className="text-center">
      <Link
        to="/gallery"
        className="inline-flex items-center px-6 sm:px-8 py-3 sm:py-4 bg-black text-white rounded-full text-base sm:text-lg font-medium hover:opacity-90 transition-opacity duration-300 group"
      >
        Explore All Styles
        <span className="ml-2 group-hover:translate-x-1 transition-transform duration-300">
          →
        </span>
      </Link>
      <p className="text-gray-500 text-sm mt-4">
        300+ professional styles for every industry
      </p>
    </div>
  </div>
);

const FAQ = ({ faqRef }) => (
  <div ref={faqRef} className="max-w-4xl mx-auto px-4 py-16">
    <h2 className="text-2xl sm:text-3xl font-bold text-center mb-12">
      Frequently Asked Questions
    </h2>
    <div className="space-y-6">
      {faqs.map((faq, index) => (
        <div key={index} className="border-b border-gray-200 pb-6">
          <h3 className="text-lg font-semibold mb-2">{faq.question}</h3>
          <p className="text-gray-600">{faq.answer}</p>
        </div>
      ))}
    </div>
  </div>
);

export default function LTDPricing() {
  const pricingRef = useRef(null);
  const bannerRef = useRef(null);
  const faqRef = useRef(null);
  const { isAuthenticated, isFetched } = useSelector(userSelector);
  const { handleCheckout, disabled } = useStripeCheckout(true);
  const [numVouchers, setNumVouchers] = useState(1);
  const [buyForSelf, setBuyForSelf] = useState(true);

  const isAuth = isAuthenticated && isFetched;

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value);
    if (typeof value === "number") {
      if (value > 0) setNumVouchers(value);
    }
  };

  return (
    <>
      <GHeader pricingRef={pricingRef} bannerRef={bannerRef} />

      <div ref={pricingRef} className="max-w-screen-xl mx-auto p-4 mt-16">
        <div className="w-full flex justify-center items-center flex-col mb-12">
          <div className="w-full max-w-xl text-center mb-3 px-4">
            <h1 className="font-semibold text-3xl md:text-5xl">
              Lifetime <span className="text-orange-500">Deal</span> - Never Pay{" "}
              <span className="text-amber-400">Again</span>
            </h1>
          </div>
          <div className="w-full max-w-6xl text-center">
            <p className="font-normal text-base mb-8">
              One-time payment for lifetime access to AI-powered professional
              headshots
            </p>
            <div className="bg-orange-100 text-orange-700 text-sm px-4 py-2 rounded-full font-medium inline-block">
              ⚡ Limited Time Offer - Lifetime Access
            </div>

            {/* Metrics */}
            <div className="flex justify-center gap-12 mt-10 text-xs text-gray-600">
              {metrics.map((metric, index) => (
                <div key={index} className="flex flex-col items-center gap-1">
                  <span className="font-semibold text-orange-500 text-lg">
                    {metric.value}
                  </span>
                  <span>{metric.label}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col justify-center items-center mb-4">
          <div className="text-sm text-gray-500 mb-2">
            Buy for <span className={buyForSelf && "font-bold"}>yourself</span>{" "}
            or as a{" "}
            <span className={!buyForSelf && "font-bold"}>gift voucher</span>
          </div>
          <div className="flex rounded-md bg-orange-50 text-orange-800 font-bold text-sm overflow-hidden select-none">
            <button
              onClick={() => setBuyForSelf(true)}
              className={`px-4 py-2 m-1 mr-0 rounded-tl-md rounded-bl-md ${
                buyForSelf ? "bg-orange-200" : "bg-orange-100"
              }`}
            >
              Self
            </button>
            <button
              onClick={() => setBuyForSelf(false)}
              className={`px-4 py-2 m-1 ml-0 rounded-tr-md rounded-br-md ${
                !buyForSelf ? "bg-orange-200" : "bg-orange-100"
              }`}
            >
              Gift
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 w-full justify-items-center">
          {ltdPrices.map((item) => (
            <div key={item.key} className="flex-1 w-full">
              <div className="border rounded-3xl p-4 lg:p-6 hover:shadow-lg transition-all duration-300 border-gray-200 h-full">
                <div className="text-center">
                  <div className="font-semibold text-xl md:text-2xl mb-2">
                    {item.name}
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="font-bold text-3xl md:text-4xl text-orange-500 mb-1">
                      ${parseInt(item.price)}.00
                      <span className="text-base font-normal text-gray-600 ml-1">
                        lifetime
                      </span>
                    </div>
                    <div className="bg-green-100 text-green-700 text-sm px-3 py-1 rounded-full font-medium">
                      Limited Time Offer
                    </div>
                  </div>

                  <div className="h-px bg-gray-200 my-6 " />

                  <div className="space-y-2">
                    {item.features.map((feature) => (
                      <FormatFeature key={feature.key} text={feature.content} />
                    ))}
                  </div>

                  <div className="mt-8 bottom-0">
                    {!buyForSelf && (
                      <>
                        <div className="text-sm text-gray-500 mb-2">
                          Number of vouchers
                        </div>
                        <input
                          onChange={handleInputChange}
                          value={numVouchers}
                          type="number"
                          className="py-1 px-2 bg-gray-100 mb-2 outline-0 rounded"
                          min={1}
                        />
                      </>
                    )}

                    {isAuth ? (
                      <button
                        disabled={disabled}
                        onClick={() =>
                          handleCheckout({
                            priceId: item.priceId,
                            quantity: numVouchers,
                            buyFor: buyForSelf ? "self" : "team",
                          })
                        }
                        className="w-full py-3 px-6 rounded-xl font-medium transition-colors duration-300 bg-gray-100 text-gray-900 hover:bg-gray-200"
                      >
                        Get Started
                      </button>
                    ) : (
                      <Link to="/auth" className="block">
                        <button className="w-full py-3 px-6 rounded-xl font-medium transition-colors duration-300 bg-gray-100 text-gray-900 hover:bg-gray-200">
                          Get Started
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <FAQ faqRef={faqRef} />
        <GalleryPreview />
      </div>
      <GFooter pricingRef={pricingRef} faqRef={faqRef} />
    </>
  );
}
