import React, { useRef } from "react";
import { Typography, Container, Box } from "@mui/material";
import GHeader from "./layout/GHeader";
import Try from "./landing/Try";
import GFooter from "./layout/GFooter";

const TermsOfService = () => {
  const bannerRef = useRef();
  const pricingRef = useRef();

  return (
    <>
      <GHeader pricingRef={pricingRef} bannerRef={bannerRef} />
      <Container maxWidth="md">
        <Box sx={{ my: 4 }}>
          <Typography variant="h3" component="h1" gutterBottom>
            Terms of Service
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Effective Date: November 25, 2024
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            1. Acceptance of Terms
          </Typography>
          <Typography paragraph>
            Welcome to HeadShotlyAI ("we," "us," or "our"). By accessing or
            using our website www.headshotly.ai and related services
            (collectively, the "Service"), you ("User," "you," or "your") agree
            to be bound by these Terms of Service ("Terms"). If you do not agree
            to these Terms, please do not use our Service.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            2. Eligibility
          </Typography>
          <Typography component="ul">
            <li>
              Age Requirement: You must be at least 16 years old to use our
              Service.
            </li>
            <li>
              Authority: By using our Service, you represent and warrant that
              you have the legal capacity to enter into these Terms under the
              laws of Poland and your place of residence.
            </li>
            <li>
              Compliance: You agree to comply with all applicable laws and
              regulations when using the Service.
            </li>
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            3. Account Registration and Security
          </Typography>

          <Typography variant="h6" gutterBottom>
            3.1. Account Creation
          </Typography>
          <Typography component="ul">
            <li>
              Information Accuracy: You agree to provide accurate, current, and
              complete information during the registration process.
            </li>
            <li>
              Account Credentials: You are responsible for safeguarding your
              password and agree not to disclose it to any third party.
            </li>
          </Typography>

          <Typography variant="h6" gutterBottom>
            3.2. Account Responsibility
          </Typography>
          <Typography component="ul">
            <li>
              Activity Under Your Account: You are responsible for all
              activities that occur under your account.
            </li>
            <li>
              Notification of Breach: You agree to notify us immediately at
              support@headshotly.ai of any unauthorized use of your account.
            </li>
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            4. User Conduct
          </Typography>
          <Typography paragraph>
            You agree not to engage in any of the following prohibited
            activities:
          </Typography>
          <Typography component="ul">
            <li>
              Illegal Activities: Using the Service for any unlawful purpose or
              in violation of any local, national, or international law or
              regulation.
            </li>
            <li>
              Harmful Conduct: Uploading or sharing content that is defamatory,
              obscene, harassing, threatening, hateful, or otherwise
              objectionable.
            </li>
            <li>
              Infringement: Uploading content that infringes on any third
              party's intellectual property rights, privacy rights, or other
              proprietary rights.
            </li>
            <li>
              Interference: Interfering with or disrupting the integrity or
              performance of the Service.
            </li>
            <li>
              Unauthorized Access: Attempting to gain unauthorized access to the
              Service or its related systems or networks.
            </li>
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            5. User Content
          </Typography>

          <Typography variant="h6" gutterBottom>
            5.1. Ownership
          </Typography>
          <Typography paragraph>
            Your Content: You retain all rights, title, and interest in and to
            any photographs or content you upload to the Service ("User
            Content").
          </Typography>

          <Typography variant="h6" gutterBottom>
            5.2. License to Us
          </Typography>
          <Typography component="ul">
            <li>
              Limited License: By uploading User Content, you grant us a
              non-exclusive, worldwide, royalty-free, and transferable license
              to use, reproduce, modify, and process your User Content solely
              for the purpose of providing the Service to you.
            </li>
            <li>
              No Use for Marketing: We will never use your User Content for
              marketing, advertising, or any purpose other than providing the
              Service without your explicit consent.
            </li>
          </Typography>

          <Typography variant="h6" gutterBottom>
            5.3. Your Responsibilities
          </Typography>
          <Typography component="ul">
            <li>
              Rights and Permissions: You represent and warrant that you have
              all necessary rights and permissions to upload the User Content
              and grant the license described above.
            </li>
            <li>
              Content Compliance: You agree that your User Content will not
              violate any laws or third-party rights.
            </li>
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            6. Fees and Payment
          </Typography>

          <Typography variant="h6" gutterBottom>
            6.1. Pricing
          </Typography>
          <Typography component="ul">
            <li>
              Service Fees: Access to certain features of the Service may
              require payment of fees as described on our website.
            </li>
            <li>
              Price Changes: We reserve the right to change our fees at any time
              upon reasonable notice.
            </li>
          </Typography>

          <Typography variant="h6" gutterBottom>
            6.2. Payment Terms
          </Typography>
          <Typography component="ul">
            <li>
              Payment Processing: All payments are processed securely through
              Stripe. We do not store your full payment card details.
            </li>
            <li>
              Billing Information: You agree to provide current, complete, and
              accurate billing information.
            </li>
            <li>
              Taxes: You are responsible for all applicable taxes under Polish
              law and any other applicable jurisdictions.
            </li>
          </Typography>

          <Typography variant="h6" gutterBottom>
            6.3. Refund Policy
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Last Updated: November 25, 2024
          </Typography>

          <Typography variant="h6" gutterBottom>
            Our Commitment to You
          </Typography>
          <Typography paragraph>
            HeadShotlyAI is committed to delivering high-quality AI-enhanced
            photos that meet your expectations. While close to 99% of our
            customers are delighted with their AI headshots, we understand there
            may be instances where your photos don't accurately represent you
            for various reasons.
          </Typography>

          <Typography paragraph>
            That's why we offer a comprehensive satisfaction guarantee:
          </Typography>
          <Typography component="ul">
            <li>
              14-Day Money-Back Guarantee: You may request a refund within 14
              days of your purchase.
            </li>
            <li>
              Complimentary Redo: We offer a complimentary redo of your photos
              if needed.
            </li>
            <li>
              100% Refund: If you're not completely satisfied, we'll issue a
              full refund.
            </li>
          </Typography>

          <Typography paragraph>
            To initiate the refund process, please submit your request to
            support@headshotly.ai with a brief explanation of your concerns.
            Your satisfaction is our top priority, and we stand behind the
            quality of our AI-generated headshots.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Refund Eligibility Requirements
          </Typography>
          <Typography paragraph>
            To maintain the integrity of our service and ensure fair usage, the
            following conditions apply:
          </Typography>
          <Typography paragraph>
            Service Usage Guidelines: Refunds will not be issued in cases where
            our Service has been used in violation of our Terms, including but
            not limited to:
          </Typography>
          <Typography component="ul">
            <li>Uploading prohibited content.</li>
            <li>Attempting to circumvent system limitations.</li>
            <li>Any other form of service misuse.</li>
          </Typography>
          <Typography paragraph>
            Refund Limitation: Customers are eligible for one refund per
            account. This policy ensures a fair evaluation period while
            maintaining the sustainability of our Service. Subsequent purchases
            are considered final.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Technical Issues and Service Interruptions
          </Typography>
          <Typography paragraph>
            In the event of technical difficulties or service interruptions that
            significantly impact your ability to use our Service, please contact
            our support team immediately. Such cases will be evaluated
            individually, and appropriate compensation, including potential
            refunds, will be determined based on the specific circumstances.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Contact Information
          </Typography>
          <Typography paragraph>
            For refund requests or questions regarding our Refund Policy, please
            contact our support team at support@headshotly.ai. We aim to process
            all refund requests within 3-5 business days.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            7. Privacy and Data Protection
          </Typography>
          <Typography paragraph>
            Your privacy is important to us. Our collection and use of your
            personal information is governed by our Privacy Policy, which is
            incorporated into these Terms by reference. Our Privacy Policy
            details:
          </Typography>
          <Typography component="ul">
            <li>How we collect, use, and protect your personal information</li>
            <li>
              Your rights under applicable privacy laws, including GDPR and
              CCPA/CPRA
            </li>
            <li>Our data retention and security practices</li>
            <li>How to exercise your privacy rights</li>
          </Typography>
          <Typography paragraph>
            By using our Service, you acknowledge that you have read and
            understood our Privacy Policy at www.headshotly.ai/privacy.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            8. Intellectual Property Rights
          </Typography>

          <Typography variant="h6" gutterBottom>
            8.1. Our Content
          </Typography>
          <Typography component="ul">
            <li>
              Ownership: We own all rights, title, and interest in and to the
              Service, including all software, text, images (excluding User
              Content), and other content ("Our Content").
            </li>
            <li>
              License: We grant you a limited, non-exclusive, non-transferable
              license to access and use Our Content solely for personal,
              non-commercial use.
            </li>
          </Typography>

          <Typography variant="h6" gutterBottom>
            8.2. Restrictions
          </Typography>
          <Typography paragraph>
            Prohibited Uses: You may not copy, modify, distribute, sell, or
            lease any part of the Service or Our Content without our prior
            written consent.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            9. Termination
          </Typography>

          <Typography variant="h6" gutterBottom>
            9.1. By You
          </Typography>
          <Typography paragraph>
            Account Deletion: You may terminate your account at any time by
            following the instructions on the Service or by contacting us at
            support@headshotly.ai.
          </Typography>

          <Typography variant="h6" gutterBottom>
            9.2. By Us
          </Typography>
          <Typography paragraph>
            Termination Rights: We may suspend or terminate your access to the
            Service at our sole discretion, without prior notice, for any
            reason, including violation of these Terms.
          </Typography>

          <Typography variant="h6" gutterBottom>
            9.3. Effect of Termination
          </Typography>
          <Typography component="ul">
            <li>
              Access Rights: Upon termination, your right to access and use the
              Service will immediately cease.
            </li>
            <li>
              Data Deletion: We will delete your User Content in accordance with
              our Privacy Policy and data retention policies.
            </li>
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            10. Disclaimers
          </Typography>
          <Typography component="ul">
            <li>
              "As-Is" Basis: The Service is provided on an "as-is" and
              "as-available" basis without warranties of any kind.
            </li>
            <li>
              No Warranties: We disclaim all warranties, express or implied,
              including warranties of merchantability, fitness for a particular
              purpose, and non-infringement.
            </li>
            <li>
              No Guarantee: We do not guarantee that the Service will meet your
              requirements, be uninterrupted, secure, or error-free.
            </li>
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            11. Limitation of Liability
          </Typography>
          <Typography component="ul">
            <li>
              Exclusion of Damages: To the fullest extent permitted by law, we
              will not be liable for any indirect, incidental, special,
              consequential, or punitive damages.
            </li>
            <li>
              Maximum Liability: Our total liability for any claim arising out
              of or relating to these Terms or the Service is limited to the
              amount you have paid us in the last twelve (12) months, if any.
            </li>
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            12. Indemnification
          </Typography>
          <Typography paragraph>
            You agree to indemnify, defend, and hold harmless HeadShotlyAI and
            its affiliates, officers, agents, and employees from any claim,
            liability, damage, loss, or expense arising out of or related to:
          </Typography>
          <Typography component="ul">
            <li>
              Your Use of the Service: Any use or misuse of the Service by you.
            </li>
            <li>Violation of Terms: Your breach of these Terms.</li>
            <li>
              Violation of Laws or Rights: Your violation of any law or the
              rights of any third party.
            </li>
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            13. Governing Law and Dispute Resolution
          </Typography>

          <Typography variant="h6" gutterBottom>
            13.1. Governing Law
          </Typography>
          <Typography paragraph>
            Jurisdiction: These Terms and any dispute arising out of or relating
            to them will be governed by and construed in accordance with the
            laws of Poland, without regard to its conflict of law provisions.
          </Typography>

          <Typography variant="h6" gutterBottom>
            13.2. Dispute Resolution
          </Typography>
          <Typography component="ul">
            <li>
              Good Faith Efforts: Parties agree to attempt to resolve any
              disputes informally before initiating legal action.
            </li>
            <li>
              Arbitration: If a dispute cannot be resolved informally, it shall
              be settled by binding arbitration in accordance with the rules of
              the Court of Arbitration at the Polish Chamber of Commerce.
            </li>
            <li>
              Location: The arbitration proceedings shall take place in Warsaw,
              Poland.
            </li>
            <li>
              Language: The arbitration shall be conducted in Polish or English,
              depending on mutual agreement.
            </li>
            <li>
              Exceptions: Either party may seek injunctive or other equitable
              relief in the courts of Warsaw, Poland, for alleged unlawful use
              of intellectual property or confidential information.
            </li>
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            14. Changes to Terms
          </Typography>
          <Typography component="ul">
            <li>
              Right to Modify: We reserve the right to modify these Terms at any
              time.
            </li>
            <li>
              Notification: We will notify you of material changes by posting
              the new Terms on this page and updating the "Effective Date."
            </li>
            <li>
              Continued Use: Your continued use of the Service after changes
              become effective constitutes acceptance of the new Terms.
            </li>
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            15. Miscellaneous
          </Typography>

          <Typography variant="h6" gutterBottom>
            15.1. Entire Agreement
          </Typography>
          <Typography paragraph>
            These Terms, along with our Privacy Policy and Refund Policy,
            constitute the entire agreement between you and HeadShotlyAI
            regarding the Service.
          </Typography>

          <Typography variant="h6" gutterBottom>
            15.2. Severability
          </Typography>
          <Typography paragraph>
            If any provision of these Terms is found to be unenforceable, the
            remaining provisions will remain in full force and effect.
          </Typography>

          <Typography variant="h6" gutterBottom>
            15.3. Waiver
          </Typography>
          <Typography paragraph>
            Our failure to enforce any right or provision of these Terms will
            not be considered a waiver of those rights.
          </Typography>

          <Typography variant="h6" gutterBottom>
            15.4. Assignment
          </Typography>
          <Typography paragraph>
            You may not assign or transfer these Terms without our prior written
            consent. We may assign these Terms without restriction.
          </Typography>

          <Typography variant="h6" gutterBottom>
            15.5. Language
          </Typography>
          <Typography paragraph>
            These Terms are provided in English. If they are translated into
            another language and there is a conflict between the English version
            and the translation, the English version will prevail.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            16. Contact Us
          </Typography>
          <Typography paragraph>
            If you have any questions or concerns about these Terms, please
            contact us:
          </Typography>
          <Typography paragraph>Email: support@headshotly.ai</Typography>
          <Typography paragraph>Postal Address:</Typography>
          <Typography component="div" sx={{ pl: 2 }}>
            HeadShotlyAI
            <br />
            Wileńska 14
            <br />
            03-409 Warszawa, Poland
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            17. Additional Terms
          </Typography>

          <Typography variant="h6" gutterBottom>
            17.1. Feedback
          </Typography>
          <Typography component="ul">
            <li>
              Voluntary Submission: Any feedback, comments, or suggestions you
              provide regarding the Service is entirely voluntary.
            </li>
            <li>
              License to Feedback: We are free to use such feedback without any
              obligation to you.
            </li>
          </Typography>

          <Typography variant="h6" gutterBottom>
            17.2. Electronic Communications
          </Typography>
          <Typography component="ul">
            <li>
              Consent: By using the Service, you consent to receive electronic
              communications from us.
            </li>
            <li>
              Notices: All notices and communications we provide to you
              electronically satisfy any legal requirement that such
              communications be in writing.
            </li>
          </Typography>

          <Typography variant="h6" gutterBottom>
            17.3. Compliance with Laws
          </Typography>
          <Typography paragraph>
            Your Compliance: You agree to comply with all applicable laws,
            regulations, and ordinances related to your use of the Service,
            including Polish laws and regulations.
          </Typography>

          <Typography variant="h6" gutterBottom>
            17.4. International Users
          </Typography>
          <Typography paragraph>
            Access from Other Locations: The Service is controlled and operated
            from facilities in Poland. Those who access the Service from other
            locations do so at their own risk and are responsible for compliance
            with local laws.
          </Typography>

          <Typography paragraph sx={{ mt: 4 }}>
            Thank you for choosing HeadShotlyAI. We are committed to providing
            you with exceptional service and appreciate your trust in us.
          </Typography>
        </Box>
      </Container>
      <Try />
      <GFooter />
    </>
  );
};

export default TermsOfService;
