import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const COOKIE_CONSENT_KEY = "cookieConsent";

export default function CookieBanner() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hasConsent = localStorage.getItem(COOKIE_CONSENT_KEY);
    if (!hasConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem(COOKIE_CONSENT_KEY, "true");
    setIsVisible(false);
  };

  const handleReject = () => {
    localStorage.setItem(COOKIE_CONSENT_KEY, "false");
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 shadow-lg z-50">
      <div className="max-w-screen-xl mx-auto p-4 flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="text-sm text-gray-600 text-center sm:text-left">
          We use cookies to enhance your experience. By continuing to visit this
          site you agree to our use of cookies.{" "}
          <Link
            to="/privacy-policy"
            className="text-orange-500 hover:text-orange-600 underline transition-colors"
          >
            Learn more
          </Link>
        </div>

        <div className="flex gap-3">
          <button
            onClick={handleAccept}
            className="px-6 py-2 bg-orange-500 hover:bg-orange-600 text-white rounded-full text-sm font-medium transition-colors"
          >
            Accept
          </button>
          <button
            onClick={handleReject}
            className="px-6 py-2 border border-gray-300 hover:border-gray-400 rounded-full text-sm font-medium transition-colors"
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  );
}
