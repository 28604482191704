// man images
import CasualBlazer from "../assets/onboarding/styleSelection/man/casual_blazer_1.png";
import CasualModern from "../assets/onboarding/styleSelection/man/casual_modern_1.png";
import CasualOpenCollar from "../assets/onboarding/styleSelection/man/casual_open_collar_1.png";
import ClothingDarkSuit from "../assets/onboarding/styleSelection/man/clothing_dark_suit_1.png";
import ClothingLightSuit from "../assets/onboarding/styleSelection/man/clothing_light_suit_1.png";
import ClothingSuitNoTie from "../assets/onboarding/styleSelection/man/clothing_suit_no_tie_1.png";
import FullbodyArchitecture from "../assets/onboarding/styleSelection/man/fullbody_architecture_1.png";
import FullbodyCityscape from "../assets/onboarding/styleSelection/man/fullbody_cityscape_1.png";
import FullbodyModernInterior from "../assets/onboarding/styleSelection/man/fullbody_modern_interior_1.png";
import FullbodyNature from "../assets/onboarding/styleSelection/man/fullbody_nature_1.png";
import FullbodyOffice from "../assets/onboarding/styleSelection/man/fullbody_office_1.png";
import FullbodyStudio from "../assets/onboarding/styleSelection/man/fullbody_studio_1.png";
import HeadshotBeigeNeutral from "../assets/onboarding/styleSelection/man/headshot_beige_neutral_1.png";
import HeadshotBlueGradient from "../assets/onboarding/styleSelection/man/headshot_blue_gradient_1.png";
import HeadshotGrayGradient from "../assets/onboarding/styleSelection/man/headshot_gray_gradient_1.png";
import HeadshotMinimalist from "../assets/onboarding/styleSelection/man/headshot_minimalist_1.png";
import HeadshotOutdoorArchitecture from "../assets/onboarding/styleSelection/man/headshot_outdoor_architecture_1.png";
import HeadshotOutdoorSunset from "../assets/onboarding/styleSelection/man/headshot_outdoor_sunset_1.png";
import HeadshotOutdoorUrban from "../assets/onboarding/styleSelection/man/headshot_outdoor_urban_1.png";
import HeadshotStudioBlue from "../assets/onboarding/styleSelection/man/headshot_studio_blue_1.png";
import HeadshotStudioBrown from "../assets/onboarding/styleSelection/man/headshot_studio_brown_1.png";
import HeadshotStudioDark from "../assets/onboarding/styleSelection/man/headshot_studio_dark_1.png";
import HeadshotStudioTextured from "../assets/onboarding/styleSelection/man/headshot_studio_textured_1.png";
import HeadshotWhiteBackground from "../assets/onboarding/styleSelection/man/headshot_white_background_1.png";
import LightingGoldenHour from "../assets/onboarding/styleSelection/man/lighting_golden_hour_1.png";
import LightingNaturalOutdoor from "../assets/onboarding/styleSelection/man/lighting_natural_outdoor_1.png";
import LightingNaturalWindow from "../assets/onboarding/styleSelection/man/lighting_natural_window_1.png";
import LightingStudioDramatic from "../assets/onboarding/styleSelection/man/lighting_studio_dramatic_1.png";
import LightingStudioProfessional from "../assets/onboarding/styleSelection/man/lighting_studio_professional_1.png";
import LightingStudioSoft from "../assets/onboarding/styleSelection/man/lighting_studio_soft_1.png";
import PoseArmsCrossed from "../assets/onboarding/styleSelection/man/pose_arms_crossed_1.png";
import PoseHandsPockets from "../assets/onboarding/styleSelection/man/pose_hands_pockets_1.png";
import PoseLeaning from "../assets/onboarding/styleSelection/man/pose_leaning_1.png";
import PoseWalking from "../assets/onboarding/styleSelection/man/pose_walking_1.png";
import SeatedChair from "../assets/onboarding/styleSelection/man/seated_chair_1.png";
import SeatedDesk from "../assets/onboarding/styleSelection/man/seated_desk_1.png";
import SeatedStool from "../assets/onboarding/styleSelection/man/seated_stool_1.png";
import SeatedTable from "../assets/onboarding/styleSelection/man/seated_table_1.png";

// woman images
import BusinessConference from "../assets/onboarding/styleSelection/woman/business_conference_1_1.png";
import BusinessMeeting from "../assets/onboarding/styleSelection/woman/business_meeting_1_1.png";
import BusinessTablet from "../assets/onboarding/styleSelection/woman/business_tablet_1_1.png";
import CasualBlazerWoman from "../assets/onboarding/styleSelection/woman/casual_blazer_1.png";
import CasualModernWoman from "../assets/onboarding/styleSelection/woman/casual_modern_1.png";
import EnvironmentAutumn from "../assets/onboarding/styleSelection/woman/environment_autumn_1_1.png";
import EnvironmentGarden from "../assets/onboarding/styleSelection/woman/environment_garden_1_1.png";
import EnvironmentPark from "../assets/onboarding/styleSelection/woman/environment_park_1_1.png";
import EnvironmentRain from "../assets/onboarding/styleSelection/woman/environment_rain_1_1.png";
import EnvironmentUrban from "../assets/onboarding/styleSelection/woman/environment_urban_1_1.png";
import EnvironmentalCreative from "../assets/onboarding/styleSelection/woman/environmental_creative_1.png";
import EnvironmentalOffice from "../assets/onboarding/styleSelection/woman/environmental_office_1.png";
import FullbodyDesk from "../assets/onboarding/styleSelection/woman/fullbody_desk_1_1.jpeg";
import FullbodyModern from "../assets/onboarding/styleSelection/woman/fullbody_modern_1_1.png";
import FullbodyOfficeWoman from "../assets/onboarding/styleSelection/woman/fullbody_office_1_1.png";
import FullbodyProfessional from "../assets/onboarding/styleSelection/woman/fullbody_professional_1_1.png";
import FullbodyWhiteBackground from "../assets/onboarding/styleSelection/woman/fullbody_white_background_1.png";
import FullbodyWindow from "../assets/onboarding/styleSelection/woman/fullbody_window_1_1.png";
import HeadshotAutumn from "../assets/onboarding/styleSelection/woman/headshot_autumn_1_1.png";
import HeadshotBlueGradientWoman from "../assets/onboarding/styleSelection/woman/headshot_blue_gradient_1.png";
import HeadshotGarden from "../assets/onboarding/styleSelection/woman/headshot_garden_1_1.png";
import HeadshotGolden from "../assets/onboarding/styleSelection/woman/headshot_golden_1_1.png";
import HeadshotGradient from "../assets/onboarding/styleSelection/woman/headshot_gradient_1_1.png";
import HeadshotGrayGradientWoman from "../assets/onboarding/styleSelection/woman/headshot_gray_gradient_1.png";
import HeadshotMinimalistWoman from "../assets/onboarding/styleSelection/woman/headshot_minimalist_1.png";
import HeadshotOutdoor from "../assets/onboarding/styleSelection/woman/headshot_outdoor_1_1.png";
import HeadshotUrban from "../assets/onboarding/styleSelection/woman/headshot_urban_1_1.png";
import HeadshotWhite from "../assets/onboarding/styleSelection/woman/headshot_white_1_1.png";
// import HeadshotWhiteBackgroundWoman from "../assets/onboarding/styleSelection/woman/headshot_white_background_1.png";
import LightingNaturalWindowWoman from "../assets/onboarding/styleSelection/woman/lighting_natural_window_1.png";
import LightingStudioDramaticWoman from "../assets/onboarding/styleSelection/woman/lighting_studio_dramatic_1.png";
import LightingStudioSoftWoman from "../assets/onboarding/styleSelection/woman/lighting_studio_soft_1.png";
import PoseRailing from "../assets/onboarding/styleSelection/woman/pose_railing_1_1.png";
import PoseSeated from "../assets/onboarding/styleSelection/woman/pose_seated_1_1.png";
import PoseStairs from "../assets/onboarding/styleSelection/woman/pose_stairs_1_1.png";
import PoseStanding from "../assets/onboarding/styleSelection/woman/pose_standing_1_1.png";

// professional portraits
// Man professions
import Architect from "../assets/onboarding/styleSelection/man/profession/architect_1.png";
import ArtDirector from "../assets/onboarding/styleSelection/man/profession/art_director_1.png";
import Attorney from "../assets/onboarding/styleSelection/man/profession/attorney_1.png";
import BusinessConsultant from "../assets/onboarding/styleSelection/man/profession/business_consultant_1.png";
import BusinessLeader from "../assets/onboarding/styleSelection/man/profession/business_leader_1.png";
import CEO from "../assets/onboarding/styleSelection/man/profession/ceo_1.png";
// import Consultant from "../assets/onboarding/styleSelection/man/profession/consultant_1.png";
import CreativeDirector from "../assets/onboarding/styleSelection/man/profession/creative_director_1.png";
import CTO from "../assets/onboarding/styleSelection/man/profession/cto_1.png";
import Dean from "../assets/onboarding/styleSelection/man/profession/dean_1.png";
import Dentist from "../assets/onboarding/styleSelection/man/profession/dentist_1.png";
import Diplomat from "../assets/onboarding/styleSelection/man/profession/diplomat_1.png";
import Doctor from "../assets/onboarding/styleSelection/man/profession/doctor_1.png";
import EducationAdmin from "../assets/onboarding/styleSelection/man/profession/education_admin_1.png";
import Engineer from "../assets/onboarding/styleSelection/man/profession/engineer_1.png";
import Entrepreneur from "../assets/onboarding/styleSelection/man/profession/entrepreneur_1.png";
import Executive from "../assets/onboarding/styleSelection/man/profession/executive_1.png";
import FinancialAdvisor from "../assets/onboarding/styleSelection/man/profession/financial_advisor_1.png";
import FireCaptain from "../assets/onboarding/styleSelection/man/profession/fire_captain_1.png";
import FireChief from "../assets/onboarding/styleSelection/man/profession/fire_chief_1.png";
import Firefighter from "../assets/onboarding/styleSelection/man/profession/firefighter_1.png";
import GovernmentExecutive from "../assets/onboarding/styleSelection/man/profession/government_executive_1.png";
import HealthcareExecutive from "../assets/onboarding/styleSelection/man/profession/healthcare_executive_1.png";
import InvestmentBanker from "../assets/onboarding/styleSelection/man/profession/investment_banker_1.png";
import Judge from "../assets/onboarding/styleSelection/man/profession/judge_1.png";
import Lawyer from "../assets/onboarding/styleSelection/man/profession/lawyer_1.png";
import LegalCounsel from "../assets/onboarding/styleSelection/man/profession/legal_counsel_1.png";
import LuxuryRealtor from "../assets/onboarding/styleSelection/man/profession/luxury_realtor_1.png";
import MarketingExecutive from "../assets/onboarding/styleSelection/man/profession/marketing_executive_1.png";
import MediaExecutive from "../assets/onboarding/styleSelection/man/profession/media_executive_1.png";
import PortfolioManager from "../assets/onboarding/styleSelection/man/profession/portfolio_manager_1.png";
import Professor from "../assets/onboarding/styleSelection/man/profession/professor_1.png";
import PropertyDeveloper from "../assets/onboarding/styleSelection/man/profession/property_developer_1.png";
import PublicOfficial from "../assets/onboarding/styleSelection/man/profession/public_official_1.png";
import RealEstateBroker from "../assets/onboarding/styleSelection/man/profession/real_estate_broker_1.png";
// import Researcher from "../assets/onboarding/styleSelection/man/profession/researcher_1.png";
import SalesDirector from "../assets/onboarding/styleSelection/man/profession/sales_director_1.png";
import SoftwareExecutive from "../assets/onboarding/styleSelection/man/profession/software_executive_1.png";
import StartupFounder from "../assets/onboarding/styleSelection/man/profession/startup_founder_1.png";
import StrategyAdvisor from "../assets/onboarding/styleSelection/man/profession/strategy_advisor_1.png";
import Surgeon from "../assets/onboarding/styleSelection/man/profession/surgeon_1.png";
import TechCEO from "../assets/onboarding/styleSelection/man/profession/tech_ceo_1.png";
import TechEntrepreneur from "../assets/onboarding/styleSelection/man/profession/tech_entrepreneur_1.png";
import TechLead from "../assets/onboarding/styleSelection/man/profession/tech_lead_1.png";

// Woman professions
import FemaleAcademicDirector from "../assets/onboarding/styleSelection/woman/profession/female_academic_director_1.png";
import FemaleAttorney from "../assets/onboarding/styleSelection/woman/profession/female_attorney_1.png";
import FemaleBusinessLeader from "../assets/onboarding/styleSelection/woman/profession/female_business_leader_1.png";
import FemaleCEO from "../assets/onboarding/styleSelection/woman/profession/female_ceo_1.png";
import FemaleCorporateDirector from "../assets/onboarding/styleSelection/woman/profession/female_corporate_director_1.png";
import FemaleCreativeDirector from "../assets/onboarding/styleSelection/woman/profession/female_creative_director_1.png";
import FemaleCTO from "../assets/onboarding/styleSelection/woman/profession/female_cto_1.png";
import FemaleDean from "../assets/onboarding/styleSelection/woman/profession/female_dean_1.png";
import FemaleDentist from "../assets/onboarding/styleSelection/woman/profession/female_dentist_1.png";
import FemaleDiplomat from "../assets/onboarding/styleSelection/woman/profession/female_diplomat_1.png";
import FemaleDoctor from "../assets/onboarding/styleSelection/woman/profession/female_doctor_1.png";
import FemaleEducationAdmin from "../assets/onboarding/styleSelection/woman/profession/female_education_admin_1.png";
import FemaleEnvironmentalConsultant from "../assets/onboarding/styleSelection/woman/profession/female_environmental_consultant_1.png";
import FemaleEnvironmentalExecutive from "../assets/onboarding/styleSelection/woman/profession/female_environmental_executive_1.png";
// import FemaleExecutive from "../assets/onboarding/styleSelection/woman/profession/female_executive_1.png";
import FemaleFinancialAdvisor from "../assets/onboarding/styleSelection/woman/profession/female_financial_advisor_1.png";
import FemaleGovernmentExecutive from "../assets/onboarding/styleSelection/woman/profession/female_government_executive_1.png";
import FemaleGreenTechnologyLeader from "../assets/onboarding/styleSelection/woman/profession/female_green_technology_leader_1.png";
import FemaleHealthcareExecutive from "../assets/onboarding/styleSelection/woman/profession/female_healthcare_executive_1.png";
import FemaleInvestmentBanker from "../assets/onboarding/styleSelection/woman/profession/female_investment_banker_1.png";
import FemaleJudge from "../assets/onboarding/styleSelection/woman/profession/female_judge_1.png";
import FemaleLeadResearcher from "../assets/onboarding/styleSelection/woman/profession/female_lead_researcher_1.png";
import FemaleLegalCounsel from "../assets/onboarding/styleSelection/woman/profession/female_legal_counsel_1.png";
import FemaleLuxuryRealtor from "../assets/onboarding/styleSelection/woman/profession/female_luxury_realtor_1.png";
import FemaleMarketingExecutive from "../assets/onboarding/styleSelection/woman/profession/female_marketing_executive_1.png";
import FemalePortfolioManager from "../assets/onboarding/styleSelection/woman/profession/female_portfolio_manager_1.png";
import FemaleProfessor from "../assets/onboarding/styleSelection/woman/profession/female_professor_1.png";
import FemalePropertyDeveloper from "../assets/onboarding/styleSelection/woman/profession/female_property_developer_1.png";
import FemalePublicOfficial from "../assets/onboarding/styleSelection/woman/profession/female_public_official_1.png";
import FemaleRealEstateBroker from "../assets/onboarding/styleSelection/woman/profession/female_real_estate_broker_1.png";
import FemaleResearchScientist from "../assets/onboarding/styleSelection/woman/profession/female_research_scientist_1.png";
import FemaleResearcher from "../assets/onboarding/styleSelection/woman/profession/female_researcher_1.png";
import FemaleSalesDirector from "../assets/onboarding/styleSelection/woman/profession/female_sales_director_1.png";
import FemaleSchoolPrincipal from "../assets/onboarding/styleSelection/woman/profession/female_school_principal_1.png";
import FemaleScienceAdministrator from "../assets/onboarding/styleSelection/woman/profession/female_science_administrator_1.png";
import FemaleScientificDirector from "../assets/onboarding/styleSelection/woman/profession/female_scientific_director_1.png";
import FemaleSurgeon from "../assets/onboarding/styleSelection/woman/profession/female_surgeon_1.png";
import FemaleSustainabilityDirector from "../assets/onboarding/styleSelection/woman/profession/female_sustainability_director_1.png";
import FemaleTechExecutive from "../assets/onboarding/styleSelection/woman/profession/female_tech_executive_1.png";

// Temporary placeholder images
const PLACEHOLDER_IMAGES = {
  MAN: {
    DEFAULT:
      "https://placehold.co/400x300/2563eb/ffffff?text=Professional+Portrait",
  },
  WOMAN: {
    DEFAULT:
      "https://placehold.co/400x300/2563eb/ffffff?text=Professional+Portrait",
  },
};

export const GENDER = {
  MAN: "man",
  WOMAN: "woman",
};

export const PROMPT_TYPE = {
  PROFESSION: "profession",
  STYLE: "style",
};

// Then update the professionCategories object to use these images
export const professionCategories = {
  [GENDER.MAN]: {
    CEO: {
      title: "CEO",
      description:
        "Chief Executive Officer portraits with premium executive style",
      image: CEO || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Business Leader": {
      title: "Business Leader",
      description: "Business leadership portraits with professional presence",
      image: BusinessLeader || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    Executive: {
      title: "Executive",
      description: "Executive portraits with confident corporate style",
      image: Executive || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    CTO: {
      title: "CTO",
      description:
        "Chief Technology Officer portraits with modern tech leadership style",
      image: CTO || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Tech CEO": {
      title: "Tech CEO",
      description: "Technology company CEO portraits with innovative style",
      image: TechCEO || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Software Executive": {
      title: "Software Executive",
      description: "Software industry executive portraits",
      image: SoftwareExecutive || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    Attorney: {
      title: "Attorney",
      description: "Attorney portraits with professional legal presence",
      image: Attorney || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    Lawyer: {
      title: "Lawyer",
      description: "Lawyer portraits with authoritative style",
      image: Lawyer || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    Judge: {
      title: "Judge",
      description: "Judge portraits with distinguished judicial presence",
      image: Judge || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    Doctor: {
      title: "Doctor",
      description: "Medical doctor portraits with trustworthy healthcare style",
      image: Doctor || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    Surgeon: {
      title: "Surgeon",
      description: "Surgeon portraits with professional medical presence",
      image: Surgeon || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    Dentist: {
      title: "Dentist",
      description: "Dentist portraits with approachable healthcare style",
      image: Dentist || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    Professor: {
      title: "Professor",
      description: "Professor portraits with academic distinction",
      image: Professor || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    Dean: {
      title: "Dean",
      description: "Dean portraits with educational leadership presence",
      image: Dean || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    // Researcher: {
    //   title: "Researcher",
    //   description: "Researcher portraits with scholarly expertise",
    //   image: Researcher || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    // },
    "Investment Banker": {
      title: "Investment Banker",
      description: "Investment banker portraits with financial expertise",
      image: InvestmentBanker || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Financial Advisor": {
      title: "Financial Advisor",
      description: "Financial advisor portraits with trustworthy style",
      image: FinancialAdvisor || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Portfolio Manager": {
      title: "Portfolio Manager",
      description: "Portfolio manager portraits with financial professionalism",
      image: PortfolioManager || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Art Director": {
      title: "Art Director",
      description: "Art director portraits with creative leadership style",
      image: ArtDirector || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Creative Director": {
      title: "Creative Director",
      description: "Creative director portraits with artistic vision",
      image: CreativeDirector || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Media Executive": {
      title: "Media Executive",
      description: "Media executive portraits with industry influence",
      image: MediaExecutive || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    Engineer: {
      title: "Engineer",
      description: "Engineer portraits with technical expertise",
      image: Engineer || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    Architect: {
      title: "Architect",
      description: "Architect portraits with design professionalism",
      image: Architect || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    Entrepreneur: {
      title: "Entrepreneur",
      description: "Entrepreneur portraits with innovative spirit",
      image: Entrepreneur || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Startup Founder": {
      title: "Startup Founder",
      description: "Startup founder portraits with visionary style",
      image: StartupFounder || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Tech Entrepreneur": {
      title: "Tech Entrepreneur",
      description: "Tech entrepreneur portraits with modern innovation",
      image: TechEntrepreneur || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Fire Chief": {
      title: "Fire Chief",
      description: "Fire chief portraits with leadership command",
      image: FireChief || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    Firefighter: {
      title: "Firefighter",
      description: "Firefighter portraits with service dedication",
      image: Firefighter || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    Diplomat: {
      title: "Diplomat",
      description: "Diplomat portraits with international presence",
      image: Diplomat || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Public Official": {
      title: "Public Official",
      description: "Public official portraits with civic leadership",
      image: PublicOfficial || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Legal Counsel": {
      title: "Legal Counsel",
      description: "Legal counsel portraits with professional authority",
      image: LegalCounsel || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Luxury Realtor": {
      title: "Luxury Realtor",
      description:
        "Luxury real estate professional portraits with sophisticated style",
      image: LuxuryRealtor || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Marketing Executive": {
      title: "Marketing Executive",
      description: "Marketing executive portraits with dynamic presence",
      image: MarketingExecutive || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Property Developer": {
      title: "Property Developer",
      description:
        "Property developer portraits with professional real estate style",
      image: PropertyDeveloper || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Real Estate Broker": {
      title: "Real Estate Broker",
      description: "Real estate broker portraits with trustworthy presence",
      image: RealEstateBroker || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Sales Director": {
      title: "Sales Director",
      description: "Sales director portraits with confident business style",
      image: SalesDirector || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Tech Lead": {
      title: "Tech Lead",
      description: "Tech lead portraits with technical leadership style",
      image: TechLead || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Education Admin": {
      title: "Education Admin",
      description: "Education administrator portraits with academic leadership",
      image: EducationAdmin || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Healthcare Executive": {
      title: "Healthcare Executive",
      description:
        "Healthcare executive portraits with medical leadership presence",
      image: HealthcareExecutive || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Fire Captain": {
      title: "Fire Captain",
      description: "Fire captain portraits with service leadership",
      image: FireCaptain || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Government Executive": {
      title: "Government Executive",
      description:
        "Government executive portraits with public service leadership",
      image: GovernmentExecutive || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    // Consultant: {
    //   title: "Consultant",
    //   description: "Consultant portraits with professional advisory presence",
    //   image: Consultant || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    // },
    "Business Consultant": {
      title: "Business Consultant",
      description:
        "Business consultant portraits with strategic expertise and professional presence",
      image: BusinessConsultant || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Strategy Advisor": {
      title: "Strategy Advisor",
      description: "Strategy advisor portraits with consulting expertise",
      image: StrategyAdvisor || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
  },
  [GENDER.WOMAN]: {
    CEO: {
      title: "CEO",
      description:
        "Chief Executive Officer portraits with premium executive style",
      image: FemaleCEO || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Business Leader": {
      title: "Business Leader",
      description: "Business leadership portraits with professional presence",
      image: FemaleBusinessLeader || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    // Executive: {
    //   title: "Executive",
    //   description: "Executive portraits with confident corporate style",
    //   image: FemaleExecutive || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    // },
    "Corporate Director": {
      title: "Corporate Director",
      description: "Corporate director portraits with executive presence",
      image: FemaleCorporateDirector || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    CTO: {
      title: "CTO",
      description:
        "Chief Technology Officer portraits with modern tech leadership style",
      image: FemaleCTO || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Tech Executive": {
      title: "Tech Executive",
      description: "Technology executive portraits with innovative style",
      image: FemaleTechExecutive || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Healthcare Executive": {
      title: "Healthcare Executive",
      description:
        "Healthcare executive portraits with medical leadership presence",
      image: FemaleHealthcareExecutive || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Government Executive": {
      title: "Government Executive",
      description:
        "Government executive portraits with public service leadership",
      image: FemaleGovernmentExecutive || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Environmental Executive": {
      title: "Environmental Executive",
      description:
        "Environmental executive portraits with green leadership style",
      image: FemaleEnvironmentalExecutive || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Marketing Executive": {
      title: "Marketing Executive",
      description: "Marketing executive portraits with dynamic presence",
      image: FemaleMarketingExecutive || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Legal Counsel": {
      title: "Legal Counsel",
      description: "Legal counsel portraits with professional authority",
      image: FemaleLegalCounsel || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    Attorney: {
      title: "Attorney",
      description: "Attorney portraits with professional legal presence",
      image: FemaleAttorney || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    Judge: {
      title: "Judge",
      description: "Judge portraits with distinguished judicial presence",
      image: FemaleJudge || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    Doctor: {
      title: "Doctor",
      description: "Medical doctor portraits with trustworthy healthcare style",
      image: FemaleDoctor || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    Surgeon: {
      title: "Surgeon",
      description: "Surgeon portraits with professional medical presence",
      image: FemaleSurgeon || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    Dentist: {
      title: "Dentist",
      description: "Dentist portraits with professional healthcare presence",
      image: FemaleDentist || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },

    // Academic & Research
    Professor: {
      title: "Professor",
      description: "Professor portraits with academic distinction",
      image: FemaleProfessor || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    Dean: {
      title: "Dean",
      description: "Dean portraits with educational leadership presence",
      image: FemaleDean || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Academic Director": {
      title: "Academic Director",
      description:
        "Academic director portraits with educational leadership style",
      image: FemaleAcademicDirector || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Education Admin": {
      title: "Education Admin",
      description: "Education administrator portraits with academic leadership",
      image: FemaleEducationAdmin || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "School Principal": {
      title: "School Principal",
      description: "School principal portraits with educational leadership",
      image: FemaleSchoolPrincipal || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    Researcher: {
      title: "Researcher",
      description: "Researcher portraits with scholarly expertise",
      image: FemaleResearcher || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Research Scientist": {
      title: "Research Scientist",
      description: "Research scientist portraits with academic expertise",
      image: FemaleResearchScientist || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Lead Researcher": {
      title: "Lead Researcher",
      description: "Lead researcher portraits with scientific authority",
      image: FemaleLeadResearcher || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Scientific Director": {
      title: "Scientific Director",
      description:
        "Scientific director portraits with research leadership presence",
      image: FemaleScientificDirector || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Science Administrator": {
      title: "Science Administrator",
      description: "Science administrator portraits with research leadership",
      image: FemaleScienceAdministrator || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },

    // Financial & Business
    "Investment Banker": {
      title: "Investment Banker",
      description: "Investment banker portraits with financial expertise",
      image: FemaleInvestmentBanker || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Financial Advisor": {
      title: "Financial Advisor",
      description: "Financial advisor portraits with trustworthy expertise",
      image: FemaleFinancialAdvisor || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Portfolio Manager": {
      title: "Portfolio Manager",
      description: "Portfolio manager portraits with financial expertise",
      image: FemalePortfolioManager || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },

    // Creative & Media
    "Creative Director": {
      title: "Creative Director",
      description: "Creative director portraits with artistic vision",
      image: FemaleCreativeDirector || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },

    // Real Estate
    "Luxury Realtor": {
      title: "Luxury Realtor",
      description: "Luxury realtor portraits with premium real estate style",
      image: FemaleLuxuryRealtor || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Property Developer": {
      title: "Property Developer",
      description:
        "Property developer portraits with professional real estate style",
      image: FemalePropertyDeveloper || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Real Estate Broker": {
      title: "Real Estate Broker",
      description: "Real estate broker portraits with professional presence",
      image: FemaleRealEstateBroker || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },

    // Sales & Marketing
    "Sales Director": {
      title: "Sales Director",
      description: "Sales director portraits with confident business style",
      image: FemaleSalesDirector || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },

    // Government & Public Service
    "Public Official": {
      title: "Public Official",
      description: "Public official portraits with civic leadership",
      image: FemalePublicOfficial || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    Diplomat: {
      title: "Diplomat",
      description: "Diplomat portraits with international presence",
      image: FemaleDiplomat || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },

    // Environmental & Sustainability
    "Environmental Consultant": {
      title: "Environmental Consultant",
      description:
        "Environmental consultant portraits with sustainability expertise",
      image: FemaleEnvironmentalConsultant || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Sustainability Director": {
      title: "Sustainability Director",
      description:
        "Sustainability director portraits with environmental leadership",
      image: FemaleSustainabilityDirector || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Green Technology Leader": {
      title: "Green Technology Leader",
      description:
        "Green technology leader portraits with innovative sustainability focus",
      image: FemaleGreenTechnologyLeader || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
  },
};

export const styleCategories = {
  [GENDER.MAN]: {
    "Casual Blazer": {
      title: "Casual Blazer",
      description: "Professional yet relaxed look with a modern blazer style",
      image: CasualBlazer || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Casual Modern": {
      title: "Casual Modern",
      description: "Contemporary casual style with a professional edge",
      image: CasualModern || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Casual Open Collar": {
      title: "Casual Open Collar",
      description: "Relaxed professional look with open collar styling",
      image: CasualOpenCollar || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Dark Suit": {
      title: "Dark Suit",
      description: "Classic professional look with a dark suit",
      image: ClothingDarkSuit || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Light Suit": {
      title: "Light Suit",
      description: "Fresh professional appearance with a light colored suit",
      image: ClothingLightSuit || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Suit No Tie": {
      title: "Suit No Tie",
      description: "Modern professional look without a tie",
      image: ClothingSuitNoTie || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Architecture Background": {
      title: "Architecture Background",
      description: "Full body portraits with architectural elements",
      image: FullbodyArchitecture || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Cityscape Background": {
      title: "Cityscape Background",
      description: "Urban professional look with city backdrop",
      image: FullbodyCityscape || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Modern Interior": {
      title: "Modern Interior",
      description: "Professional portraits in contemporary interior settings",
      image: FullbodyModernInterior || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Nature Background": {
      title: "Nature Background",
      description: "Professional portraits in natural settings",
      image: FullbodyNature || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Office Setting": {
      title: "Office Setting",
      description: "Professional portraits in office environment",
      image: FullbodyOffice || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Studio Full Body": {
      title: "Studio Full Body",
      description: "Professional full body portraits in studio setting",
      image: FullbodyStudio || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Beige Neutral Background": {
      title: "Beige Neutral Background",
      description: "Professional headshots with neutral beige background",
      image: HeadshotBeigeNeutral || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Blue Gradient Background": {
      title: "Blue Gradient Background",
      description: "Professional headshots with blue gradient background",
      image: HeadshotBlueGradient || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Gray Gradient Background": {
      title: "Gray Gradient Background",
      description: "Professional headshots with gray gradient background",
      image: HeadshotGrayGradient || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Minimalist Style": {
      title: "Minimalist Style",
      description: "Clean, minimalist professional portraits",
      image: HeadshotMinimalist || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Outdoor Architecture": {
      title: "Outdoor Architecture",
      description: "Professional portraits with architectural elements",
      image: HeadshotOutdoorArchitecture || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Outdoor Sunset": {
      title: "Outdoor Sunset",
      description: "Professional portraits in golden hour lighting",
      image: HeadshotOutdoorSunset || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Urban Outdoor": {
      title: "Urban Outdoor",
      description: "Professional portraits in urban settings",
      image: HeadshotOutdoorUrban || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Studio Blue": {
      title: "Studio Blue",
      description: "Professional studio portraits with blue toning",
      image: HeadshotStudioBlue || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Studio Brown": {
      title: "Studio Brown",
      description: "Professional studio portraits with warm toning",
      image: HeadshotStudioBrown || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Studio Dark": {
      title: "Studio Dark",
      description: "Professional studio portraits with dramatic dark toning",
      image: HeadshotStudioDark || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Studio Textured": {
      title: "Studio Textured",
      description: "Professional studio portraits with textured background",
      image: HeadshotStudioTextured || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "White Background": {
      title: "White Background",
      description: "Clean professional portraits with white background",
      image: HeadshotWhiteBackground || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Golden Hour": {
      title: "Golden Hour",
      description: "Portraits in warm, natural golden hour lighting",
      image: LightingGoldenHour || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Natural Outdoor": {
      title: "Natural Outdoor",
      description: "Professional portraits in natural outdoor lighting",
      image: LightingNaturalOutdoor || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Natural Window": {
      title: "Natural Window",
      description: "Professional portraits with natural window lighting",
      image: LightingNaturalWindow || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Studio Dramatic": {
      title: "Studio Dramatic",
      description: "Professional portraits with dramatic studio lighting",
      image: LightingStudioDramatic || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Studio Professional": {
      title: "Studio Professional",
      description: "Classic professional studio portraits",
      image: LightingStudioProfessional || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Studio Soft": {
      title: "Studio Soft",
      description: "Professional portraits with soft studio lighting",
      image: LightingStudioSoft || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Arms Crossed": {
      title: "Arms Crossed",
      description: "Professional pose with arms crossed",
      image: PoseArmsCrossed || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Hands in Pockets": {
      title: "Hands in Pockets",
      description: "Casual professional pose with hands in pockets",
      image: PoseHandsPockets || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Leaning Pose": {
      title: "Leaning Pose",
      description: "Relaxed professional pose while leaning",
      image: PoseLeaning || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Walking Pose": {
      title: "Walking Pose",
      description: "Dynamic professional portrait while walking",
      image: PoseWalking || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Seated Chair": {
      title: "Seated Chair",
      description: "Professional portraits while seated in a chair",
      image: SeatedChair || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Seated Desk": {
      title: "Seated Desk",
      description: "Professional portraits at a desk",
      image: SeatedDesk || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Seated Stool": {
      title: "Seated Stool",
      description: "Professional portraits on a stool",
      image: SeatedStool || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
    "Seated Table": {
      title: "Seated Table",
      description: "Professional portraits at a table",
      image: SeatedTable || PLACEHOLDER_IMAGES.MAN.DEFAULT,
    },
  },
  [GENDER.WOMAN]: {
    "Business Conference": {
      title: "Business Conference",
      description: "Professional portraits in conference settings",
      image: BusinessConference || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Business Meeting": {
      title: "Business Meeting",
      description: "Dynamic portraits in meeting environments",
      image: BusinessMeeting || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Business Tablet": {
      title: "Business Tablet",
      description: "Modern professional portraits with technology",
      image: BusinessTablet || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Casual Blazer": {
      title: "Casual Blazer",
      description: "Professional yet relaxed look with a modern blazer",
      image: CasualBlazerWoman || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Casual Modern": {
      title: "Casual Modern",
      description: "Contemporary casual professional style",
      image: CasualModernWoman || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Autumn Environment": {
      title: "Autumn Environment",
      description: "Professional portraits in autumn settings",
      image: EnvironmentAutumn || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Garden Environment": {
      title: "Garden Environment",
      description: "Professional portraits in garden settings",
      image: EnvironmentGarden || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Park Environment": {
      title: "Park Environment",
      description: "Professional portraits in park settings",
      image: EnvironmentPark || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Rain Environment": {
      title: "Rain Environment",
      description: "Unique professional portraits in rain settings",
      image: EnvironmentRain || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Urban Environment": {
      title: "Urban Environment",
      description: "Professional portraits in urban settings",
      image: EnvironmentUrban || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Creative Environmental": {
      title: "Creative Environmental",
      description: "Creative professional portraits in natural settings",
      image: EnvironmentalCreative || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Office Environmental": {
      title: "Office Environmental",
      description: "Professional portraits in office environments",
      image: EnvironmentalOffice || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Desk Full Body": {
      title: "Desk Full Body",
      description: "Full body professional portraits at desk",
      image: FullbodyDesk || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Modern Full Body": {
      title: "Modern Full Body",
      description: "Contemporary full body professional portraits",
      image: FullbodyModern || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Office Full Body": {
      title: "Office Full Body",
      description: "Full body professional portraits in office setting",
      image: FullbodyOfficeWoman || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Professional Full Body": {
      title: "Professional Full Body",
      description: "Classic full body professional portraits",
      image: FullbodyProfessional || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "White Background Full Body": {
      title: "White Background Full Body",
      description: "Clean full body portraits with white background",
      image: FullbodyWhiteBackground || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Window Full Body": {
      title: "Window Full Body",
      description: "Full body portraits with natural window lighting",
      image: FullbodyWindow || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Autumn Headshot": {
      title: "Autumn Headshot",
      description: "Professional headshots in autumn settings",
      image: HeadshotAutumn || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Blue Gradient Headshot": {
      title: "Blue Gradient Headshot",
      description: "Professional headshots with blue gradient background",
      image: HeadshotBlueGradientWoman || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Garden Headshot": {
      title: "Garden Headshot",
      description: "Professional headshots in garden settings",
      image: HeadshotGarden || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Golden Headshot": {
      title: "Golden Headshot",
      description: "Professional headshots with golden lighting",
      image: HeadshotGolden || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Gradient Headshot": {
      title: "Gradient Headshot",
      description: "Professional headshots with gradient background",
      image: HeadshotGradient || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Gray Gradient Headshot": {
      title: "Gray Gradient Headshot",
      description: "Professional headshots with gray gradient background",
      image: HeadshotGrayGradientWoman || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Minimalist Headshot": {
      title: "Minimalist Headshot",
      description: "Clean, minimalist professional headshots",
      image: HeadshotMinimalistWoman || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Outdoor Headshot": {
      title: "Outdoor Headshot",
      description: "Professional headshots in outdoor settings",
      image: HeadshotOutdoor || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Urban Headshot": {
      title: "Urban Headshot",
      description: "Professional headshots in urban settings",
      image: HeadshotUrban || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "White Headshot": {
      title: "White Headshot",
      description: "Clean professional headshots with white background",
      image: HeadshotWhite || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Natural Window Lighting": {
      title: "Natural Window Lighting",
      description: "Portraits with natural window lighting",
      image: LightingNaturalWindowWoman || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Dramatic Studio Lighting": {
      title: "Dramatic Studio Lighting",
      description: "Portraits with dramatic studio lighting",
      image: LightingStudioDramaticWoman || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Soft Studio Lighting": {
      title: "Soft Studio Lighting",
      description: "Portraits with soft studio lighting",
      image: LightingStudioSoftWoman || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Railing Pose": {
      title: "Railing Pose",
      description: "Professional portraits posed by railing",
      image: PoseRailing || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Seated Pose": {
      title: "Seated Pose",
      description: "Professional portraits in seated pose",
      image: PoseSeated || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Stairs Pose": {
      title: "Stairs Pose",
      description: "Professional portraits posed on stairs",
      image: PoseStairs || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
    "Standing Pose": {
      title: "Standing Pose",
      description: "Professional portraits in standing pose",
      image: PoseStanding || PLACEHOLDER_IMAGES.WOMAN.DEFAULT,
    },
  },
};
