import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedModel: "",
  modals: {
    pricing: false,
    gift: false,
  },
};

const micsSlice = createSlice({
  name: "mics",
  initialState,
  reducers: {
    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload;
    },
    setModal: (state, action) => {
      state.modals[action.payload.name] = action.payload.value;
    },
    setResetModal: (state, action) => {
      return initialState;
    },
  },
});

export const miscActions = micsSlice.actions;

export const modelsSelector = (state) => state.misc;

export const modalsSelector = (state) => state.misc.modals;

export default micsSlice.reducer;
