import { Link } from "react-router-dom";
import prices from "../../data/pricing";

const FormatFeature = ({ text }) => {
  if (text === "Money-back Guarantee") {
    return (
      <div className="flex items-center justify-center gap-2 mb-3">
        <span className="text-orange-500 text-lg">•</span>
        <span className="text-sm font-semibold text-orange-500">{text}</span>
      </div>
    );
  }

  const [title, value] = text.split(":");
  if (!value) return text;

  return (
    <div className="flex items-center w-full gap-y-3 px-4">
      <div className="text-orange-500 text-lg mr-1">•</div>
      <div className="text-sm text-left">
        <span className="text-gray-900 font-medium">{title}</span>
        <span className="text-gray-600 ml-1">:{value}</span>
      </div>
    </div>
  );
};

const metrics = [
  {
    value: "30min",
    label: "Delivery Time",
  },
  {
    value: "98%",
    label: "Satisfaction Rate",
  },
  {
    value: "100+",
    label: "Variations",
  },
];

const isCouponApplicable = false;

export default function Pricing({ pricingRef }) {
  return (
    <div ref={pricingRef} className="max-w-screen-xl mx-auto p-4 mt-16">
      <div className="w-full flex justify-center items-center flex-col mb-12">
        <div className="w-full max-w-xl text-center mb-3 px-4">
          <h1 className="font-semibold text-3xl md:text-5xl">
            Professional <span className="text-orange-500">Headshots</span> at a{" "}
            <span className="text-amber-400">Fraction</span> of Studio Costs
          </h1>
        </div>
        <div className="w-full max-w-6xl text-center">
          <p className="font-normal text-base mb-8">
            Transform your regular photos with AI-powered studio-quality
            professional headshots
          </p>

          {/* Metrics */}
          <div className="flex justify-center gap-12 mt-10 text-xs text-gray-600">
            {metrics.map((metric, index) => (
              <div key={index} className="flex flex-col items-center gap-1">
                <span className="font-semibold text-orange-500 text-lg">
                  {metric.value}
                </span>
                <span>{metric.label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Pricing Cards */}
      <div className="flex flex-col lg:flex-row gap-6 w-full">
        {prices.map((item) => (
          <div key={item.key} className="flex-1">
            <div
              className={`border rounded-3xl p-6 lg:p-8 hover:shadow-lg transition-all duration-300 
              ${
                item.key === "standard"
                  ? "border-orange-600 shadow-md"
                  : "border-gray-200"
              }`}
            >
              <div className="text-center">
                <div className="font-semibold text-xl md:text-2xl mb-2">
                  {item.name}
                </div>
                <div className="flex flex-col items-center">
                  {isCouponApplicable && (
                    <div className="font-bold text-3xl md:text-4xl text-gray-400 line-through mb-1">
                      ${parseInt(item.price)}.00
                    </div>
                  )}
                  <div className="font-bold text-3xl md:text-4xl text-orange-500 mb-1">
                    ${parseInt(item.price * 1)}.00
                    <span className="text-base font-normal text-gray-600 ml-1">
                      one-time
                    </span>
                  </div>
                  {isCouponApplicable && (
                    <div className="bg-green-100 text-green-700 text-sm px-3 py-1 rounded-full font-medium">
                      Save 50% with code: CYBERMONDAY
                    </div>
                  )}
                </div>

                <div className="h-px bg-gray-200 my-6" />

                <div className="space-y-1">
                  {item.features.map((feature) => (
                    <FormatFeature key={feature.key} text={feature.content} />
                  ))}
                </div>

                <div className="mt-8">
                  <Link to="/auth">
                    <button
                      className={`w-full py-3 px-6 rounded-xl font-medium transition-colors duration-300
                      ${
                        item.key === "standard"
                          ? "bg-orange-500 text-white hover:bg-orange-600"
                          : "bg-gray-100 text-gray-900 hover:bg-gray-200"
                      }`}
                    >
                      Get Professional Photos
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
