import { Button } from "@mui/material";
import React, { useState } from "react";

const ReferralCPButton = ({ referralUrl, loading }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    if (loading) return;
    try {
      await navigator.clipboard.writeText(referralUrl);
      setCopied(true);

      setTimeout(() => setCopied(false), 1000);
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  return (
    <>
      <Button
        disabled={loading}
        onClick={handleCopy}
        variant="contained"
        color="primary"
        style={{ color: "white" }}
      >
        {copied ? "Copied!" : "Click to Copy"}
      </Button>
    </>
  );
};

const Referral = ({ referralUrl = "" }) => {
  if (!referralUrl.length) return null;
  return (
    <div className="flex flex-col gap-6 w-full">
      <div className="text-gray-700">
        <h3 className="text-2xl font-semibold mb-3">
          Share HeadShotly with Friends!
        </h3>
        <p className="mb-4">
          Invite your friends to join HeadShotly and earn amazing rewards:
        </p>
        <ul className="list-disc list-inside mb-6 space-y-2">
          <li>
            <span className="font-medium">20 AI Generated Images</span> - For
            you and your friend
          </li>
        </ul>
        <p className="text-sm text-gray-600 mb-6">
          *Both you and your referred friend will receive 20 AI Generated Images
          credits when they successfully sign up and join HeadShotly
        </p>
      </div>

      <div className="flex flex-col gap-y-2">
        <label className="font-medium text-gray-700">
          Your Unique Referral Link:
        </label>
        <div className="flex flex-col lg:flex-row gap-6 w-full">
          <div className="flex flex-col gap-y-2 bg-gray-100 py-2 px-4 rounded-lg text-gray-600 select-all">
            {`${referralUrl}`}
          </div>
          <ReferralCPButton referralUrl={referralUrl} loading={false} />
        </div>
      </div>
    </div>
  );
};

export default Referral;
