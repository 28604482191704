import { useDispatch, useSelector } from "react-redux";
import { miscActions, modalsSelector } from "../../app/slices/mics";
import PricingModal from "./PricingModal";
import GiftModal from "./GiftModal";

export default function Modal() {
  const dispatch = useDispatch();
  const { pricing, gift } = useSelector(modalsSelector);

  const handlePricingModal = (bool = true) => {
    dispatch(miscActions.setModal({ name: "pricing", value: bool }));
  };

  const handleGiftModal = (bool = true) => {
    dispatch(miscActions.setModal({ name: "gift", value: bool }));
  };

  return (
    <>
      <PricingModal
        isOpen={pricing}
        handleClose={() => handlePricingModal(false)}
        showCoupon={false}
      />
      <GiftModal isOpen={gift} handleClose={() => handleGiftModal(false)} />
    </>
  );
}
