import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Pagination,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTour } from "@reactour/tour";
import { modelSelectors } from "../app/slices/model";
import { miscActions } from "../app/slices/mics";
import { asyncUpdateUser, userSelector } from "../app/slices/user";
import idealPhoto1 from "../assets/ideal/high-definition.jpg";
import idealPhoto2 from "../assets/ideal/expressions.jpg";
import idealPhoto3 from "../assets/ideal/diverse-locations.jpg";
import idealPhoto4 from "../assets/ideal/angles.jpg";

import avoidPhoto1 from "../assets/avoid/covered-face.jpg";
import avoidPhoto2 from "../assets/avoid/blurry.jpg";
import avoidPhoto3 from "../assets/avoid/poorly-cropped.jpg";
import avoidPhoto4 from "../assets/avoid/sunglasses.jpg";
import heic2any from "heic2any";
import imageCompression from "browser-image-compression";

const MIN_UPLOAD_LIMIT = 10;
const MAX_UPLOAD_LIMIT = 50;

const IdealPhotoExamples = [
  {
    key: "0",
    img: idealPhoto1,
    heading: "High Quality Photos:",
    content:
      "Upload clear, well-lit photos (minimum 1024x1024 pixels). Professional photos or high-quality selfies work great.",
  },
  {
    key: "1",
    img: idealPhoto2,
    heading: "Natural Expressions:",
    content:
      "Include a mix of professional and natural expressions to showcase your authentic self.",
  },
  {
    key: "2",
    img: idealPhoto3,
    heading: "Professional Settings:",
    content:
      "Include photos in business or professional settings with appropriate attire. Ensure you're at a good distance from the camera.",
  },
  {
    key: "3",
    img: idealPhoto4,
    heading: "Variety of Angles:",
    content:
      "Mix different angles and backgrounds for a diverse professional portfolio.",
  },
];

const PhotostoAvoid = [
  {
    key: "0",
    img: avoidPhoto1,
    heading: "Incomplete Face Photos:",
    content:
      "Ensure your full face is clearly visible without any cropping or obstruction.",
  },
  {
    key: "1",
    img: avoidPhoto2,
    heading: "Group Photos or Blur:",
    content:
      "Upload solo photos only - no group shots. Images must be sharp and clear.",
  },
  {
    key: "2",
    img: avoidPhoto3,
    heading: "Poor Framing:",
    content: "Avoid photos with awkward cropping or hidden facial features.",
  },
  {
    key: "3",
    img: avoidPhoto4,
    heading: "Accessories Blocking Face:",
    content:
      "Skip photos with sunglasses, hats, or anything that covers your facial features.",
  },
];

const UploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed rgb(249 115 22)`,
  borderRadius: "16px",
  padding: theme.spacing(3),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(6),
  },
  textAlign: "center",
  cursor: "pointer",
  marginBottom: theme.spacing(4),
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: "rgb(255 247 237)",
  },
}));

const PreviewContainer = styled(Box)({
  position: "relative",
  width: "100%",
  paddingTop: "100%",
  marginBottom: "16px",
  border: "2px solid #e0e0e0",
  borderRadius: "10px",
  overflow: "hidden",
});

const PreviewImage = styled("img")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "8px",
});

const DeleteButton = styled(IconButton)({
  position: "absolute",
  top: 5,
  right: 5,
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
  },
});

const ModelTraining = () => {
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [status, setStatus] = useState(null);
  const [progress, setProgress] = useState(0);
  const [modelName, setModelName] = useState("");
  const [gender, setGender] = useState("");
  const _modelSelectors = useSelector(modelSelectors);
  const availableModels = _modelSelectors.data.models;
  const { data } = useSelector(userSelector);
  const { setIsOpen, setSteps, isOpen: isTourOpen } = useTour();
  const [isTourOpenOnce, setIsTourOpenOnce] = useState(false);
  const navigate = useNavigate();

  const [converting, setConverting] = useState(false);
  const fileInputRef = useRef(null);
  const [page, setPage] = useState(1);
  const modelsPerPage = 5;

  useEffect(() => {
    if (data?.tourCompleted === false) {
      setSteps(steps);
      setIsOpen(true);
      setIsTourOpenOnce(true);
    }
  }, [setIsOpen, setSteps, data?.tourCompleted]);

  useEffect(() => {
    if (isTourOpenOnce && !isTourOpen) {
      dispatch(asyncUpdateUser({ body: { tourCompleted: true } }));
    }
  }, [isTourOpen, isTourOpenOnce, dispatch]);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files) {
      handleFiles(e.dataTransfer.files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback((e) => {
    if (e.target.files) {
      handleFiles(e.target.files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 3, // Only compress if file is over 3MB
      maxWidthOrHeight: 4096, // Keep very high resolution
      useWebWorker: true, // Better performance
      preserveExif: true, // Keep image metadata
      quality: 0.95, // Very high quality (95%)
    };

    try {
      // Skip compression for small files (under 3MB)
      if (file.size <= 3 * 1024 * 1024) {
        return file;
      }

      const compressedFile = await imageCompression(file, options);
      return new File([compressedFile], file.name, { type: file.type });
    } catch (error) {
      console.error("Compression error:", error);
      return file; // Return original file if compression fails
    }
  };

  const handleFiles = async (files) => {
    setConverting(true);
    try {
      const processedFiles = [];
      for (const file of files) {
        // First handle HEIC if needed
        const isHeic =
          file.type === "image/heic" ||
          file.type === "image/heif" ||
          file.name.toLowerCase().endsWith(".heic") ||
          file.name.toLowerCase().endsWith(".heif");

        let processedFile = file;
        if (isHeic) {
          // Convert HEIC to JPEG first
          const convertedBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 1,
            multiple: false,
          });
          processedFile = new File(
            [convertedBlob],
            file.name.replace(/\.(heic|HEIF)$/i, ".jpg"),
            { type: "image/jpeg" }
          );
        }

        // Then compress if needed
        const compressedFile = await compressImage(processedFile);
        processedFiles.push(compressedFile);
      }

      setSelectedFiles((prevFiles) => {
        const updatedFiles = [...prevFiles, ...processedFiles];
        if (updatedFiles.length > MAX_UPLOAD_LIMIT) {
          setError(`Maximum ${MAX_UPLOAD_LIMIT} images allowed`);
          return prevFiles;
        }
        return updatedFiles;
      });
    } catch (err) {
      setError("Failed to process some images. Please try again.");
    } finally {
      setConverting(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleDelete = useCallback((index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  }, []);

  const MAX_RETRIES = 3;
  const RETRY_DELAY = 2000; // 2 seconds

  const uploadAndProcess = async (retryCount = 0) => {
    if (
      data?.subscriptionStatus !== "active" ||
      data?.credits?.modelTraining <= 0
    ) {
      dispatch(miscActions.setModal({ name: "pricing", value: true }));
      return;
    }
    if (!hasEnoughCredits()) {
      setError(
        "You do not have enough credits to train a model. Please purchase more credits or upgrade your subscription."
      );
      return;
    }

    if (selectedFiles.length < MIN_UPLOAD_LIMIT) {
      setError(`Please upload at least ${MIN_UPLOAD_LIMIT} images.`);
      return;
    }
    if (selectedFiles.length > MAX_UPLOAD_LIMIT) {
      setError(`Maximum ${MAX_UPLOAD_LIMIT} images allowed.`);
      return;
    }
    setProcessing(true);
    setError("");
    setStatus("Uploading and processing...");

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });
    formData.append("modelName", modelName);
    formData.append("gender", gender);

    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/engine/upload-and-process`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
            setStatus(`Uploading: ${percentCompleted}%`);
          },
        }
      );

      setStatus(
        "Your AI photographer is now learning your style! We'll notify you when your professional photos are ready"
      );
      // setModelTrainingCredits((prevCredits) => prevCredits - 1);

      // Start polling for updates
      // pollTrainingStatus(response.data.requestId);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage = error.response.data.message;
        setError(errorMessage);
        setStatus("");
        setProcessing(false);
      } else if (
        error.response &&
        error.response.data.message === "Insufficient model training credits"
      ) {
        setError(
          "You do not have enough credits to train a model. Please purchase more credits or upgrade your subscription."
        );
        setStatus("");
        setProcessing(false);
      } else if (error.response?.status === 413) {
        setError(
          "The files are too large. Please try again with smaller images."
        );
        setStatus("");
        setProcessing(false);
      } else if (retryCount < MAX_RETRIES) {
        setStatus(
          `An error occurred. Retrying... (Attempt ${
            retryCount + 1
          }/${MAX_RETRIES})`
        );
        setTimeout(() => uploadAndProcess(retryCount + 1), RETRY_DELAY);
      } else {
        setError(
          error.response?.data?.message ||
            "An error occurred during model training. Please try again later."
        );
        setStatus("");
        setProcessing(false);
      }
    }
  };

  const renderImagePreviews = () => (
    <Grid container spacing={2}>
      {selectedFiles.map((file, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <PreviewContainer>
            <PreviewImage
              src={URL.createObjectURL(file)}
              alt={`Preview ${index + 1}`}
            />
            <DeleteButton onClick={() => handleDelete(index)}>
              <DeleteIcon />
            </DeleteButton>
          </PreviewContainer>
        </Grid>
      ))}
    </Grid>
  );

  const renderGenderSelection = () => (
    <Box sx={{ mb: 4 }} className="first-step">
      <Typography variant="h6" gutterBottom>
        I am a:
      </Typography>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <Button
            fullWidth
            variant={gender === "man" ? "contained" : "outlined"}
            onClick={() => setGender("man")}
            sx={{ height: "48px" }}
          >
            Man
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            fullWidth
            variant={gender === "woman" ? "contained" : "outlined"}
            onClick={() => setGender("woman")}
            sx={{ height: "48px" }}
          >
            Woman
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  const renderPhotoExamples = () => (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        Photo Guidelines:
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
        Upload {MIN_UPLOAD_LIMIT}-{MAX_UPLOAD_LIMIT} photos that follow these
        guidelines.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" gutterBottom>
            ✅Ideal Photo Examples:
          </Typography>
          <div className="flex flex-col gap-y-3">
            {IdealPhotoExamples.map((item) => (
              <div
                className="flex flex-col sm:flex-row gap-4 w-full"
                key={item.key}
              >
                <div className="flex items-center gap-x-2 w-full">
                  <div className="w-24 min-w-[96px]">
                    <img
                      className="rounded-md object-cover w-full h-24"
                      style={{
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      }}
                      src={item.img}
                      alt=""
                    />
                  </div>
                  <div className="flex-1">
                    <strong className="text-base block mb-1">
                      {item.heading}
                    </strong>
                    <span className="text-sm">{item.content}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" gutterBottom>
            🚫Photos to Avoid:
          </Typography>
          <div className="flex flex-col gap-y-3">
            {PhotostoAvoid.map((item) => (
              <div
                className="flex flex-col sm:flex-row gap-4 w-full"
                key={item.key}
              >
                <div className="flex items-center gap-x-2 w-full">
                  <div className="w-24 min-w-[96px]">
                    <img
                      className="rounded-md object-cover w-full h-24"
                      style={{
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      }}
                      src={item.img}
                      alt=""
                    />
                  </div>
                  <div className="flex-1">
                    <strong className="text-base block mb-1">
                      {item.heading}
                    </strong>
                    <span className="text-sm">{item.content}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    </Box>
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const renderAvailableModels = () => {
    const startIndex = (page - 1) * modelsPerPage;
    const endIndex = startIndex + modelsPerPage;
    const paginatedModels = availableModels.slice(startIndex, endIndex);
    const totalPages = Math.ceil(availableModels.length / modelsPerPage);

    return (
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Your Available Trained AI Models Based on Your Uploaded Photos:
        </Typography>
        <TableContainer
          component={Paper}
          sx={{
            overflowX: "auto",
            "& .MuiTable-root": {
              minWidth: { xs: 650, sm: 750 },
            },
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ color: "rgb(249 115 22)", fontWeight: "bold" }}
                >
                  Model Name
                </TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedModels.map((model, index) => (
                <TableRow key={index}>
                  <TableCell>{model.name}</TableCell>
                  <TableCell>
                    {new Date(model.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{model.status}</TableCell>
                  <TableCell>
                    <Button
                      style={{ color: "white" }}
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleGeneratePictures(model.name, model.requestId)
                      }
                      disabled={model.status.toLowerCase() !== "completed"}
                    >
                      Explore Your AI Photos
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {totalPages > 1 && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "rgb(249 115 22)",
                },
                "& .Mui-selected": {
                  backgroundColor: "rgb(249 115 22) !important",
                  color: "white !important",
                },
              }}
            />
          </Box>
        )}
      </Box>
    );
  };

  const handleGeneratePictures = (modelName, requestId) => {
    navigate("/image-generation", {
      state: { selectedModel: modelName, requestId: requestId },
    });
  };

  const hasEnoughCredits = () => {
    return data?.credits?.modelTraining > 0;
  };

  const hasModelError = useMemo(() => {
    if (Array.isArray(availableModels)) {
      const existModel = availableModels.find(
        (model) => model?.name === modelName
      );
      return !!existModel;
    } else {
      return false;
    }
  }, [availableModels, modelName]);

  return (
    <Container maxWidth="lg">
      <Box
        sx={{ mt: { xs: 2, md: 4 }, mb: { xs: 2, md: 4 }, textAlign: "center" }}
      >
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontWeight: "bold",
            color: "primary.main",
            fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
          }}
        >
          Create Your Professional AI Headshots
        </Typography>
      </Box>

      <Paper
        elevation={3}
        sx={{
          p: { xs: 2, md: 4 },
          mb: { xs: 2, md: 4 },
          backgroundColor: "#FFF7ED",
          border: "2px solid rgb(249 115 22)",
          borderRadius: "12px",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "rgb(249 115 22)", fontWeight: "bold" }}
        >
          Please read this carefully before uploading any pictures:
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Honestly, our AI photographer is very powerful and advanced, but very
          dumb. However as a human, you are smarter. Our AI photographer doesn't
          know you and has never met you, so how can you teach it about
          yourself? By providing high-quality, very recent pictures that clearly
          represent you. These should include:
        </Typography>
        <Box
          component="ul"
          sx={{
            mb: 2,
            pl: 4,
            listStyleType: "disc",
            "& li": {
              display: "list-item",
              mb: 0.5,
            },
          }}
        >
          <li>
            A clear face with different facial expressions from various angles
          </li>
          <li>A clear hairstyle</li>
          <li>Full-body shots</li>
        </Box>
        <Typography variant="body1" sx={{ fontWeight: "500" }}>
          You need to ensure that the pictures you upload allow AI to understand
          you well enough to create an accurate clone of yourself. If you don't
          pay attention to these details, we cannot guarantee the best results.
        </Typography>
      </Paper>

      {!hasEnoughCredits() && data.subscriptionStatus === "active" && (
        <Paper
          elevation={3}
          sx={{
            p: 4,
            mb: 4,
            backgroundColor: "rgb(255 247 237)", // orange-50
            border: "1px solid rgb(255 237 213)", // orange-100
          }}
        >
          <div className="flex flex-col md:flex-row items-center gap-6">
            <div className="flex-grow">
              <Typography
                variant="h5"
                gutterBottom
                sx={{ color: "rgb(249 115 22)" }}
              >
                No Available Model Training Credits
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                To create your personalized AI model, you'll need model training
                credits. These are included in our regular subscription plans.
              </Typography>
              <div className="flex gap-4">
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate("/account", { state: { defaultTab: 1 } })
                  }
                  sx={{
                    bgcolor: "rgb(249 115 22)",
                    "&:hover": {
                      bgcolor: "rgb(234 88 12)",
                    },
                    borderRadius: "24px",
                    py: 1.5,
                    px: 4,
                  }}
                >
                  View Subscription Plans
                </Button>
              </div>
            </div>
            <div className="text-center px-6 py-4 bg-orange-100 rounded-2xl">
              <Typography
                variant="h3"
                sx={{ color: "rgb(249 115 22)", fontWeight: "bold" }}
              >
                {data?.credits?.modelTraining || 0}
              </Typography>
              <Typography variant="body2" sx={{ color: "rgb(194 65 12)" }}>
                Available Training Credits
              </Typography>
            </div>
          </div>
        </Paper>
      )}

      {availableModels.length > 0 && renderAvailableModels()}

      <Paper elevation={3} sx={{ p: 4, mt: 4, opacity: 1 }}>
        <Typography variant="h4" gutterBottom sx={{ color: "rgb(249 115 22)" }}>
          Train Your Custom AI Model
        </Typography>
        <Divider sx={{ mb: 4 }} />

        {renderGenderSelection()}
        {renderPhotoExamples()}

        <UploadBox
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          className="second-step"
        >
          <input
            ref={fileInputRef}
            accept="image/jpeg,image/jpg,image/png,image/webp,image/heic,image/heif"
            type="file"
            multiple
            onChange={handleChange}
            style={{ display: "none" }}
            id="file-upload"
          />
          <label htmlFor="file-upload">
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon />}
              size="large"
              sx={{
                color: "white",
                bgcolor: "rgb(249 115 22)",
                "&:hover": {
                  bgcolor: "rgb(234 88 12)",
                },
              }}
            >
              Upload Your Photos
            </Button>
          </label>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Upload {MIN_UPLOAD_LIMIT}-{MAX_UPLOAD_LIMIT} high-quality photos to
            train your AI
          </Typography>
          {converting && (
            <Typography variant="body2" color="warning.main" sx={{ mt: 1 }}>
              Analyzing images, please wait...
            </Typography>
          )}
        </UploadBox>

        {renderImagePreviews()}

        <Box sx={{ mt: 4 }}>
          <TextField
            className="third-step"
            fullWidth
            label="Name your AI Model"
            placeholder="e.g., Professional Headshots 2024"
            variant="outlined"
            value={modelName}
            helperText={hasModelError ? "This model name already exists" : ""}
            error={hasModelError}
            onChange={(e) => setModelName(e.target.value)}
            sx={{ mb: 2 }}
          />
        </Box>

        <Tooltip
          title={
            !hasEnoughCredits() && data.subscriptionStatus === "active"
              ? "You need more credits to create an AI model"
              : ""
          }
        >
          <span>
            <Button
              className="fourth-step"
              variant="contained"
              onClick={uploadAndProcess}
              style={{ color: "white" }}
              disabled={
                processing ||
                selectedFiles.length === 0 ||
                !modelName ||
                hasModelError ||
                !gender
              }
              sx={{
                mt: 4,
                py: 2,
                fontSize: "1.2rem",
                bgcolor: "rgb(249 115 22)",
                "&:hover": {
                  bgcolor: "rgb(234 88 12)",
                },
                "&.Mui-disabled": {
                  bgcolor: "rgb(254 215 170)",
                },
              }}
              fullWidth
            >
              {processing
                ? "Creating Your Professional Headshots..."
                : "Generate My Professional Headshots"}
            </Button>
          </span>
        </Tooltip>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        {processing && (
          <Box sx={{ width: "100%", mt: 2 }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                "& .MuiLinearProgress-bar": {
                  bgcolor: "rgb(249 115 22)",
                },
              }}
            />
            <Typography variant="body2" sx={{ mt: 1, textAlign: "center" }}>
              {status}
            </Typography>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

const steps = [
  {
    selector: ".first-step",
    content: "Select your gender to personalize the AI model training process.",
  },
  {
    selector: ".second-step",
    content: "Upload your photos here. Drag and drop or click to select files.",
  },
  {
    selector: ".third-step",
    content: "Name your AI model. This helps you identify it later.",
  },
  {
    selector: ".fourth-step",
    content: "Click here to start generating your professional headshots.",
  },
];

export default ModelTraining;
