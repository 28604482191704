import StarImg from "../../assets/landing/icons/star.png";
import CrossImg from "../../assets/landing/icons/cross.png";
import VSImg from "../../assets/landing/versus.png";

const HeadShotlyAI = [
  {
    key: "1",
    content: "Upload any photo - takes just 5 minutes",
  },
  {
    key: "2",
    content: "AI trained on 100,000+ professional studio headshots",
  },
  {
    key: "3",
    content: "Get 100+ professional variations in 30 minutes",
  },
  {
    key: "4",
    content: "Each photo optimized for LinkedIn and business use",
  },
  {
    key: "5",
    content: "Multiple professional backgrounds and lighting styles",
  },
  {
    key: "6",
    content: "Start using your professional headshots today",
  },
];

const TraditionalStudio = [
  {
    key: "1",
    content: "Search and book a professional photographer",
  },
  {
    key: "2",
    content: "Wait weeks for an available appointment",
  },
  {
    key: "3",
    content: "Spend $200+ on professional attire",
  },
  {
    key: "4",
    content: "Travel to studio location during business hours",
  },
  {
    key: "5",
    content: "Limited poses and background options",
  },
  {
    key: "6",
    content: "Wait days or weeks for edited photos",
  },
];

export default function Compare() {
  return (
    <div className="max-w-[900px] mx-auto mt-16">
      <div className="w-full flex justify-center items-center flex-col mb-8">
        <div className="w-full max-w-xl text-center mb-3 px-4">
          <h1 className="font-semibold text-3xl sm:text-4xl md:text-5xl lg:text-6xl">
            Why <span className="text-orange-500">HeadShotlyAI</span> is the{" "}
            <span className="text-amber-400">Smarter Choice</span> for
            Professional Headshots
          </h1>
        </div>
        <div className="w-full max-w-sm text-center px-4">
          <p className="font-normal text-sm sm:text-base md:text-lg text-gray-600 mt-4">
            Studio-quality professional headshots without the studio hassle
          </p>
        </div>
      </div>

      <div className="flex justify-between items-stretch max-[450px]:flex-col max-[450px]:gap-y-5">
        <div className="border-gray-300 border rounded-3xl flex flex-col relative">
          <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
            <span className="bg-green-100 text-green-800 text-sm font-semibold px-4 py-2 rounded-full whitespace-nowrap shadow-md">
              Starting at Only
              <span className="text-2xl ml-1 font-bold">$30</span>
            </span>
          </div>
          <div className="py-10 px-6 max-w-[440px] flex-grow mt-6">
            <div className="w-10 h-10 rounded-full bg-blue-700 flex items-center justify-center">
              <img src={StarImg} alt="" />
            </div>
            <div className="font-semibold text-2xl my-3">
              HeadShotlyAI Professional (30-Minute Delivery)
            </div>
            <div className="font-normal text-base">
              <div className="">
                {HeadShotlyAI.map((item) => (
                  <div key={item.key} className="flex mb-2">
                    <div className="mr-1">•</div>
                    {item.content}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="mx-2 w-10 self-center max-[450px]:hidden">
          <img src={VSImg} alt="" />
        </div>
        <div className="border-gray-300 border rounded-3xl flex flex-col relative">
          <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
            <span className="bg-red-100 text-red-800 text-sm font-semibold px-4 py-2 rounded-full whitespace-nowrap shadow-md">
              Starting at
              <span className="text-2xl ml-1 font-bold">$250</span>
            </span>
          </div>
          <div className="py-10 px-6 max-w-[440px] flex-grow mt-6">
            <div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center">
              <img src={CrossImg} alt="" />
            </div>
            <div className="font-semibold text-2xl my-3">
              Traditional Studio (Days of Waiting)
            </div>
            <div className="font-normal text-base">
              <div className="">
                {TraditionalStudio.map((item) => (
                  <div key={item.key} className="flex mb-2">
                    <div className="mr-1">•</div>
                    {item.content}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
