import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { vouchersSelector } from "../app/slices/vouchers";

export default function VoucherTable() {
  const { data: vouchersData, loading: loadingVouchers } =
    useSelector(vouchersSelector);

  return (
    <div className="border-gray-300 rounded-xl overflow-hidden bg-white">
      <div className="p-1 sm:p-6">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-semibold text-gray-800">
            My Gift Vouchers
          </h3>
          <div className="text-sm text-gray-500">
            Total Vouchers: {vouchersData.length}
          </div>
        </div>

        <div style={{ width: "100%" }}>
          <DataGrid
            rowSelection={false}
            rows={vouchersData.map((voucher) => ({
              id: voucher._id,
              code: voucher.code,
              status: voucher.isClaimed ? "Claimed" : "Available",
              plan: voucher.subscriptionPlan,
              imageCredits: voucher.credits.imageGeneration,
              trainingCredits: voucher.credits.modelTraining,
              claimedBy: voucher.claimedBy?.email || "-",
            }))}
            columns={[
              {
                field: "code",
                headerName: "Voucher Code",
                flex: 1,
                renderCell: (params) => (
                  <div className="font-mono px-3 py-1 select-all">
                    {params.value}
                  </div>
                ),
              },
              {
                field: "status",
                headerName: "Status",
                width: 130,
                renderCell: (params) => (
                  <div className={`px-3 py-1`}>{params.value}</div>
                ),
              },
              { field: "plan", headerName: "Plan", width: 130 },
              {
                field: "imageCredits",
                headerName: "Image Credits",
                width: 130,
              },
              {
                field: "trainingCredits",
                headerName: "Training Credits",
                width: 150,
              },
              { field: "claimedBy", headerName: "Claimed By", flex: 1 },
            ]}
            loading={loadingVouchers}
            pagination
          />
        </div>
      </div>
    </div>
  );
}
