import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import { setModelsOnly } from "./model";

const initialState = {
  isFetched: false,
  isAuthenticated: false,
  isLoading: false,
  isOnboarded: false,
  data: {},
  error: null,
};

const localToken = localStorage?.getItem("token");

export const asyncGetUser = createAsyncThunk(
  "user/getUser",
  async ({ userToken }, { rejectWithValue, dispatch }) => {
    try {
      const token = localToken || userToken;
      if (!token) {
        window.location.href = "/auth";
        return initialState;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/data`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const models = response.data?.models ?? [];
      dispatch(setModelsOnly(models));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncUpdateUser = createAsyncThunk(
  "user/updateUser",
  async ({ userToken, body }, { rejectWithValue }) => {
    try {
      const token = localToken || userToken;
      if (!token) {
        window.location.href = "/auth";
        return initialState;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/update-profile`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        return response.data.user;
      }
      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncGetReferral = createAsyncThunk(
  "user/getReferral",
  async ({ userToken }, { rejectWithValue }) => {
    try {
      const token = localToken || userToken;

      if (!token) {
        window.location.href = "/auth";
        return initialState;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/referral/get-code`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        return `${window.location.origin}?hsref=${response.data.code}`;
      }
      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.data = { ...state.data, ...action.payload?.data };
      state.isLoading = false;
      state.error = null;
    },
    setRemainingToken: (state, action) => {
      const _num = action.payload?.numberOfToken;
      const numberOfToken = typeof _num === "number" ? _num : 0;

      switch (action.payload?.creaditKey) {
        case "credits.modelTraining":
          state.data.credits.modelTraining -= numberOfToken;
          break;
        case "credits.imageGeneration":
          state.data.credits.imageGeneration -= numberOfToken;
          break;
        default:
          return state;
      }
    },

    logOut: (state, action) => {
      state = initialState;
      localStorage?.removeItem("token");
      window.location.href = "/auth";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetUser.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(asyncGetUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isAuthenticated = true;
      state.isOnboarded = true;
      state.error = null;
      state.isFetched = true;
    });
    builder.addCase(asyncGetUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isFetched = true;
    });
    builder.addCase(asyncUpdateUser.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(asyncGetReferral.fulfilled, (state, action) => {
      state.data.referral = action.payload;
    });
  },
});

export const userSelector = (state) => state.user;

export const getIsOnboarded = createSelector(
  [(state) => state.user?.data],
  (data) => {
    if (!isEmpty(data)) {
      const onboarding = data?.onboarding || {};
      const isComplate = Object.entries(onboarding).every(
        // always ignore pricing
        ([key, value]) => value?.completed === true || key === "pricing"
      );
      return { onboarded: Boolean(isComplate), step: 1 };
    }
    return { onboarded: false };
  }
);

export const { setUser, setRemainingToken, logOut } = userSlice.actions;

export default userSlice.reducer;
